import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {ObjectID} from "../../interfaces";
import {environment} from "../../../environments/environment";
import {APIService} from "../../services/api.service";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {BlobDataCompanyImage, BlobDataProjectImage, DataBlob, NewImageData} from "../../interfaces/datablob";
import {isNgDiff} from "../../utils/angular";

@Component({
  selector: 'pfm-imageblob-display',
  templateUrl: './imageblob-display.component.html',
  styleUrls: ['./imageblob-display.component.scss']
})
export class ImageblobDisplayComponent implements OnChanges {

  @Output() imageClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() abortUpload: EventEmitter<void> = new EventEmitter<void>();
  @Output() deleteImage: EventEmitter<void> = new EventEmitter<void>();
  @Output() uploadStarted: EventEmitter<File[]> = new EventEmitter<File[]>();
  @Output() saveImage: EventEmitter<void> = new EventEmitter<void>();

  @Input() clickable: boolean = false;
  @Input() mode: 'edit' | 'uploading' | 'readonly' | 'upload' = 'edit';

  @Input() actions: ('delete' | 'abort' | 'download' | 'save')[] = [];

  @Input() sourceMode: 'full' | '2048' | '1024' | '512' | '256' | '128' | '64' | '32' = '512';

  @Input() isSaving: boolean = false;

  @Input() image: DataBlob<BlobDataProjectImage | BlobDataCompanyImage> | NewImageData | null = null;
  @Output() imageChange = new EventEmitter<DataBlob<BlobDataProjectImage | BlobDataCompanyImage> | NewImageData | null>();

  changed: boolean = false;

  constructor(private api: APIService,
              private modal: ModalService,
              private auth: AuthenticationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'image')) {
      this.changed = false;
    }
  }

  thumbSource(blobid: ObjectID) {
    if (this.sourceMode === 'full') {
      return `${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID()}/images/${blobid}?xx-bearer-token=@${this.auth.getToken()}`;
    } else {
      return `${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID()}/images/${blobid}/thumb/${this.sourceMode}?xx-bearer-token=@${this.auth.getToken()}`;
    }
  }

  imageSource(blobid: ObjectID) {
    return `${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID()}/images/${blobid}?xx-bearer-token=@${this.auth.getToken()}`;
  }

  onClicked() {
    if (this.clickable) this.imageClicked.emit();
  }

  onUploadStarted(evt: Event) {
    const files = (evt.target as any).files as File[];
    this.uploadStarted.emit(files);
  }
}
