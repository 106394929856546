import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'pfm-menubar-button',
  template: '<ng-template #contentTemplate><ng-content></ng-content></ng-template>',
  styles: []
})
export class MenubarButtonComponent {

  @Input() icon: IconProp|null = null;
  @Input() disabled: boolean = false;
  @Input() type: 'default'|'primary'|'secondary' = 'default';
  @Input() routerLink: any[] | string | null | undefined = undefined;

  @Output() btnClick = new EventEmitter<MouseEvent>();

  @ViewChild('contentTemplate', { static: true }) contentTemplate!: TemplateRef<any>;
}
