import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'pfm-suggestion-input',
  templateUrl: './suggestion-input.component.html',
  styleUrls: ['./suggestion-input.component.scss']
})
export class SuggestionInputComponent {

  @Input() preventPlaceholderSelect: boolean = true;
  @Input() placeholder: string|null = null;
  @Input() values: string[] = [];


  _innerValue: string = '';
  get innerValue(): string { return this._innerValue; }
  set innerValue(newInnerValue: string) {
    this._innerValue = newInnerValue;
    if (this.autoTrim) {
      if (newInnerValue.trim() !== this.value) { this.valueChange.emit(this.value = newInnerValue.trim()); }
    } else {
      if (newInnerValue !== this.value) { this.valueChange.emit(this.value = newInnerValue); }
    }
  }

  _value: string = '';
  get value(): string { return this._value; }
  @Input() set value(newValue: string) { this._value = newValue; this._innerValue = newValue; }
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean|undefined = undefined;
  @Input() autoTrim: boolean = true;

  @ViewChild('comp') component!: ElementRef<HTMLInputElement>;

  focus() {
    this.component.nativeElement.focus();
  }

  suggest(v: string|null|undefined) {
    if (v === null) return this.values;
    if (v === undefined) return this.values;
    if (v === '') return this.values;

    if (this.values.includes(v)) return [];

    return this.values.filter(p => {
      return (p.toLowerCase().includes(v.toLowerCase()) || p.toLowerCase().includes(v.toLowerCase()));
    });
  }

  select(v: string) {
    console.log('sugg');
    this.innerValue = v;
  }
}
