export interface PendingChangesGuarded {
  hasNoPendingChanges: () => boolean;
}

export const pendingChangesGuard = (component: PendingChangesGuarded) => {

  console.log('GUARD TRIGGERED');

  if (component.hasNoPendingChanges()) return true;

  return confirm("Achtung\nEinige Daten wurden noch nicht gespeichert, möchtest du die Seite trotzdem verlassen?");
};
