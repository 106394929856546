
<pfm-download-expose-modal [(expose)]="downloadExpose"></pfm-download-expose-modal>

<frame-default [status]="status" errorMessage="Beim Laden der Exposès ist ein Fehler aufgetreten">
  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;"
                     [crumbs]="[{text:'Seiten'}, {text:'Exposès', link: '/exposes/list', active: true},]" ></pfm-breadcrumbs>


    <div class="statistics">
      <pfm-statistics-text icon="file-pen" title="Exposès insgesamt" [loading]="exposeTotalCount === null" [value]="exposeTotalCount ?? '...'"></pfm-statistics-text>
      <div class="spacer"></div>
      <pfm-button routerLink="/exposes/create" icon="plus">Exposè anlegen</pfm-button>
    </div>

    <pfm-input class="searchfield"
               [preIcon]="['fas', 'magnifying-glass']"
               placeholder="Tabelle durchsuchen"
               [value]="(filters['search'].active ?? '') | caststring"
               [autoTrim]="false"
               (valueChange)="filters['search'].active = $event; searchObservable?.next($event)"></pfm-input>

    <pfm-card header="Exposès">

      <pfm-table [datasource]="exposes" [hoverHighlight]="true">

        <pfm-table-col header="Projektnummer">
          <ng-template [ctxType]="{item:exposes[0]!}" let-item="item">
            <pfm-async-display intermediateValue="..." [data]="item.projectID" [query]="resolveProject" transform="projectNumber" ></pfm-async-display>
          </ng-template>
        </pfm-table-col>

        <pfm-table-col header="Projektname">
          <ng-template [ctxType]="{item:exposes[0]!}" let-item="item">
            <pfm-async-display intermediateValue="..." [data]="item.projectID" [query]="resolveProject" transform="projectName" ></pfm-async-display>
          </ng-template>
        </pfm-table-col>

        <pfm-table-col header="Name">
          <ng-template [ctxType]="{item:exposes[0]!}" let-item="item">{{item.name}}</ng-template>
        </pfm-table-col>

        <pfm-table-col header="Template">
          <ng-template [ctxType]="{item:exposes[0]!}" let-item="item">{{translateTemplate(item.template)}}</ng-template>
        </pfm-table-col>

        <pfm-table-col header="">
          <ng-template [ctxType]="{item:exposes[0]!}" let-item="item">
            <pfm-context-menu>
              <pfm-context-menu-item [icon]="['fat', 'pen']"                  (onClick)="editExpose(item)"     >Bearbeiten</pfm-context-menu-item>
              <pfm-context-menu-item [icon]="['fat', 'arrow-down-to-square']" (onClick)="downloadExpose=item"  >Download</pfm-context-menu-item>
              <pfm-context-menu-item [icon]="['fat', 'trash-can']"            (onClick)="deleteExpose(item)"   >Löschen</pfm-context-menu-item>
            </pfm-context-menu>
          </ng-template>
        </pfm-table-col>

      </pfm-table>
    </pfm-card>
  </main>
</frame-default>
