
<pfm-download-projectlist-modal [(projectlist)]="downloadProjectlist"></pfm-download-projectlist-modal>

<frame-default [status]="status" errorMessage="Beim Laden der Projektlisten ist ein Fehler aufgetreten">
  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;"
                     [crumbs]="[{text:'Seiten'}, {text:'Projektlisten', link: '/projectlists/list', active: true},]" ></pfm-breadcrumbs>


    <div class="statistics">
      <pfm-statistics-text icon="file-pen" title="Projektlisten insgesamt" [loading]="projectlistTotalCount === null" [value]="projectlistTotalCount ?? '...'"></pfm-statistics-text>
      <div class="spacer"></div>
      <pfm-button routerLink="/projectlists/create" icon="plus">Projektliste anlegen</pfm-button>
    </div>

    <pfm-input class="searchfield"
               [preIcon]="['fas', 'magnifying-glass']"
               placeholder="Tabelle durchsuchen"
               [value]="(filters['search'].active ?? '') | caststring"
               (valueChange)="filters['search'].active = $event; searchObservable?.next($event)"></pfm-input>

    <pfm-card header="Projektlisten">

      <pfm-table [datasource]="projectlists" [hoverHighlight]="true">

        <pfm-table-col header="Name">
          <ng-template [ctxType]="{item:projectlists[0]!}" let-item="item">{{item.name}}</ng-template>
        </pfm-table-col>

        <pfm-table-col header="Anzahl Projekte">
          <ng-template [ctxType]="{item:projectlists[0]!}" let-item="item">{{item.projectIDs.length}}</ng-template>
        </pfm-table-col>

        <pfm-table-col header="Template">
          <ng-template [ctxType]="{item:projectlists[0]!}" let-item="item">{{translateTemplate(item.template)}}</ng-template>
        </pfm-table-col>

        <pfm-table-col header="">
          <ng-template [ctxType]="{item:projectlists[0]!}" let-item="item">
            <pfm-context-menu>
              <pfm-context-menu-item [icon]="['fat', 'pen']"                  (onClick)="editProjectlist(item)"     >Bearbeiten</pfm-context-menu-item>
              <pfm-context-menu-item [icon]="['fat', 'arrow-down-to-square']" (onClick)="downloadProjectlist=item"  >Download</pfm-context-menu-item>
              <pfm-context-menu-item [icon]="['fat', 'trash-can']"            (onClick)="deleteProjectlist(item)"   >Löschen</pfm-context-menu-item>
            </pfm-context-menu>
          </ng-template>
        </pfm-table-col>

      </pfm-table>
    </pfm-card>
  </main>
</frame-default>
