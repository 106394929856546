import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {InputComponent} from "../input/input.component";
import {sleep} from "../../utils/angular";

@Component({
  selector: 'pfm-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent<T> implements OnInit {

  @Input() placeholder: string|null = null;

  _values: {key: T, value: string}[] = [];
  get values(): {key: T, value: string}[] { return this._values; }
  @Input() set values(newValue: {key: T, value: string}[]) { this._values = newValue; this.refreshFilteredValues(); }

  @Input() canClear: boolean = false;

  @Input() value: T|null = null; // .key of selected value
  @Output() valueChange: EventEmitter<T|null> = new EventEmitter<T|null>();

  @Input() disabled: boolean = false;
  @Input() invalid: boolean|undefined = undefined;

  @ViewChild('searchBox') searchBox!: InputComponent;

  opened: boolean = false;

  _searchFilter: string = '';
  get searchFilter(): string { return this._searchFilter; }
  @Input() set searchFilter(newValue: string) { this._searchFilter = newValue; this.refreshFilteredValues(); }

  filteredValues: {key: T, value: string}[] = [];

  constructor() {}

  ngOnInit() {
    this.refreshFilteredValues();
  }

  onClicked() {
    console.log('onClicked');
    if (this.disabled) return;

    if (this.opened) this.close(); else this.open();
  }

  open() {
    console.log('open');
    if (this.disabled) return;

    if (!this.opened) {
      this.opened = true;
      sleep(100).then(() => this.searchBox.focus());
    }
  }

  close() {
    console.log('close');
    if (this.disabled) return;

    this.opened = false;
  }

  select(key: T) {
    console.log('select', key);
    this.opened = false;
    this.value = key;
    this.valueChange.emit(key);
  }

  clearSelection() {
    console.log('clearSelection');
    this.opened = false;
    this.value = null;
    this.valueChange.emit(null);
  }

  keyToText(k: T) {
    return this.values.find(p => p.key === k)?.value ?? `${k}`;
  }

  refreshFilteredValues() {
    let nv = (this.searchFilter === '') ? this.values : this.values.filter(p => this.value === p.key || p.value.toLowerCase().includes(this.searchFilter.toLowerCase()))

    if (JSON.stringify(nv) !== JSON.stringify(this.filteredValues)) {
      this.filteredValues = nv;
    }

  }
}
