import {Component, OnInit} from '@angular/core';
import {
  Contact,
  CreateContact,
  isValidationError,
  ObjectID
} from "../../interfaces";
import {ValidationRef} from "../../interfaces/validation";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'pfm-contact-editcreate',
  templateUrl: './contact-editcreate.component.html',
  styleUrls: ['./contact-editcreate.component.scss']
})

export class ContactEditcreateComponent implements OnInit{

  status: 'loading'|'error'|'content' = 'content';
  mode: 'CREATE'|'EDIT' = 'CREATE';

  editContactID: ObjectID|null = null;

  id:                   string = '';
  name:                 string = '';
  companyName:          string = '';
  mail:                 string = '';
  companyMail:          string = '';
  phone:                string = '';
  companyPhone:         string = '';
  address_street:       string = '';
  address_streetNumber: string = '';
  address_zip:          string = '';
  address_city:         string = '';
  address_country:      string = '';

  validationErrors: Set<ValidationRef> = new Set<ValidationRef>();
  check: boolean|undefined= undefined;

  constructor(private api:APIService, private router:Router, private notification:NotificationService, private activatedRoute:ActivatedRoute) {
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data['mode'] === 'CREATE') {
      this.editContactID = null;
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchData(this.editContactID).then(() => {});
    } else if (this.activatedRoute.snapshot.data['mode'] === 'EDIT') {
      this.editContactID = this.activatedRoute.snapshot.params['contactid'];
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchData(this.editContactID).then(() => {});
    } else {
      this.status = 'error';
      this.notification.error('Fehler', 'Komponente ist in einem invaliden Zustand');
    }
  }

  async fetchData(contactid: ObjectID|null) {
    try {
      this.status = 'loading';

      if (contactid !== null) {
        const contact = await this.api.getContact(contactid);
        await this.setAllValues(contact);
      }

      this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      this.status = 'error';
    }
  }

  setAllValues(contact: Contact){
    this.name                 = contact.name;
    this.companyName          = contact.companyName;
    this.phone                = contact.phone;
    this.companyPhone         = contact.companyPhone;
    this.mail                 = contact.mail;
    this.companyMail          = contact.companyMail;
    this.address_country      = contact.address.country;
    this.address_street       = contact.address.street;
    this.address_zip          = contact.address.zip;
    this.address_city         = contact.address.city;
    this.address_streetNumber = contact.address.streetNumber;
  }

  async createOrUpdateContact(){
    if(this.mode === 'CREATE') await this.createContact();
    if(this.mode === 'EDIT')   await this.updateContact();

  }

  async createContact(){
    let cnc: CreateContact = {
      address_city:         this.address_city,
      address_country:      this.address_country,
      address_street:       this.address_street,
      address_streetNumber: this.address_streetNumber,
      address_zip:          this.address_zip,

      companyMail:  this.companyMail,
      companyName:  this.companyName,
      companyPhone: this.companyPhone,
      mail:         this.mail,
      name:         this.name,
      phone:        this.phone
    }

    try {
      await this.api.createContact(cnc);

      await this.router.navigate(['/contacts/list']);

    } catch (err) {
      if (isValidationError(err, 'CONTACT_VALIDATION_FAILED')) {
        this.notification.error('Kontakt konnten nicht erstellt werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference as ValidationRef);
      } else {
        showAPIError(this.notification, 'Kontakt konnten nicht erstellt werden', err);
        this.check=true;
      }
    }
  }

  async updateContact() {
    this.validationErrors.clear();

    let cnc: CreateContact = {
      address_city:         this.address_city,
      address_country:      this.address_country,
      address_street:       this.address_street,
      address_streetNumber: this.address_streetNumber,
      address_zip:          this.address_zip,

      companyMail:  this.companyMail,
      companyName:  this.companyName,
      companyPhone: this.companyPhone,
      mail:         this.mail,
      name:         this.name,
      phone:        this.phone
    }

    try {
      await this.api.updateContact(this.editContactID!, cnc);

      await this.router.navigate(['/contacts/list']);

    } catch (err) {
      if (isValidationError(err, 'CONTACT_VALIDATION_FAILED')) {
        this.notification.error('Kontakt konnten nicht erstellt werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference as ValidationRef);
      } else {
        showAPIError(this.notification, 'Kontakt konnten nicht bearbeitet werden', err);
        this.check=true;
      }
    }
  }
}
