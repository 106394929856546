

<div class="menubar">

  <ng-container *ngFor="let btn of buttons; index as idx">

    <ng-container *ngIf="idx > 0"><div class="menuseperator"></div></ng-container>

    <a *ngIf="btn.routerLink !== undefined" class="menubutton type_{{btn.type}}" [class.disabled]="btn.disabled" [routerLink]="btn.routerLink" (click)="btn.btnClick.emit($event)">
      <div *ngIf="btn.icon !== null" class="icon"><fa-icon [icon]="btn.icon"></fa-icon></div>
      <span class="text"><ng-container *ngTemplateOutlet="(btn.contentTemplate)"></ng-container></span>
    </a>

    <div *ngIf="btn.routerLink === undefined" class="menubutton type_{{btn.type}}" [class.disabled]="btn.disabled" (click)="btn.btnClick.emit($event)">
      <div *ngIf="btn.icon !== null" class="icon"><fa-icon [icon]="btn.icon"></fa-icon></div>
      <span class="text"><ng-container *ngTemplateOutlet="(btn.contentTemplate)"></ng-container></span>
    </div>

  </ng-container>

  <ng-content></ng-content>
</div>
