import {Component, ContentChild, EventEmitter, Input, Output, TemplateRef} from '@angular/core';

@Component({
  selector: 'pfm-table-col',
  template: '',
  styles: []
})
export class TableColComponent {

  @Input() header: string = '';
  @Input() widthmode: 'default'|'fit' = 'default';

  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();

  @ContentChild(TemplateRef) contentTemplate!: TemplateRef<any>;
}
