
<label for="rbtn-{{uniq}}" [class.disabled]="disabled">
  <input type="radio"
         id="rbtn-{{uniq}}"
         [name]="name"
         [ngModel]="value"
         (ngModelChange)="value = $event; valueChange.emit($event)"
         [disabled]="disabled"
         [value]="radiovalue"
         [attr.aria-invalid]="invalid">
  <span>{{text}}</span>
</label>
