
<div id="primary">

  <div id="left">
    <ng-content></ng-content>
  </div>

  <div id="right">

    <div class="quote">
      <h1>“Mit Hilfe von Isiproject haben wir es geschafft unsere Projekte optimal digital zu verwalten und mit geringstem Aufwand Portfolios zu erstellen."</h1>
      <div class="source">
        <img class="avatar" ngSrc="assets/testimonial_avatar.svg" height="66" width="66" alt="avatar"/>
        <div class="author">
          <span class="name">Julia Rehfuss</span>
          <span class="company">Isenmann Ingenieure GmbH</span>
        </div>
      </div>
    </div>

    <div class="application-image">
      <img ngSrc="assets/login_application_preview.svg" height="709" width="815" alt="preview"/>
    </div>

  </div>

</div>


