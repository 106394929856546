import {Component, OnInit} from '@angular/core';
import {ObjectID, User} from "../../interfaces";
import {AuthenticationService} from "../../services/authentication.service";
import {APIService} from "../../services/api.service";
import {showAPIError} from "../../utils/api";
import {NotificationService} from "../../services/notification.service";
import {validateEmailRegex} from "../../utils/email";

@Component({
  selector: 'pfm-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  status: 'loading'|'error'|'content' = 'loading';

  saveLoading1: boolean = false;
  saveLoading2: boolean = false;

  errorTexts: string[] = [];

  userid: ObjectID|null = null;

  username = '';
  email: string = '';

  currentPassword: string = '';
  newPassword1: string = '';
  newPassword2: string = '';

  invalid = new Set<string>();

  constructor(private auth: AuthenticationService,
              private api: APIService,
              private notification: NotificationService) {

  }

  ngOnInit() {
    this.fetchUser().then(() => {});
  }

  async fetchUser() {
    try {
      const id = this.auth.getSelfID();
      if (id === null) {
        this.notification.error('Fehler', 'Informationen konnten nicht geladen weden');
        this.status = 'error';
        return;
      }

      const user = await this.api.getUser(id);

      this.setData(user);

    } catch (err) {
      showAPIError(this.notification, 'Informationen konnten nicht geladen weden', err);
    }
  }

  setData(user: User) {
    this.userid          = user.id;
    this.username        = user.username;
    this.email           = user.email;
    this.currentPassword = '';
    this.newPassword1    = '';
    this.newPassword2    = '';
  }

  async changeData() {
    if (this.userid === null) return;

    if (!this.validateUpdateData()) return

    try {
      this.saveLoading1 = true;

      const user = await this.api.updateUser(this.userid, this.username, this.email, null, null);

      this.setData(user);

      this.notification.success('Daten aktualisiert', 'Der Benutzer wurde gespeichert');

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht aktualisiert werden', err);
    } finally {
      this.saveLoading1 = false;
    }
  }

  async changePassword() {
    if (this.userid === null) return;

    if (!this.validateUpdatePassword()) return

    try {
      this.saveLoading2 = true;

      const user = await this.api.updateUser(this.userid, null, null, this.currentPassword, this.newPassword1);

      this.setData(user);

      this.notification.success('Passwort aktualisiert', 'Das Passwort wurde erfolgreich geändert');

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht aktualisiert werden', err);
    } finally {
      this.saveLoading2 = false;
    }
  }

  isInvalid(key: string) {
    return this.invalid.has(key) ? true : undefined;
  }

  validateUpdateData(): boolean {
    this.errorTexts = [];
    this.invalid.clear();

    let anyerr = false;

    if (this.username.length < 4)          { this.errorTexts.push('Der Benutzername muss mindestens 4 Zeichen lang sein'); this.invalid.add('username');  anyerr = true; }
    if (!validateEmailRegex(this.email))   { this.errorTexts.push('Bitte eine gültige Email eingeben');                    this.invalid.add('email');     anyerr = true; }

    return !anyerr;
  }

  validateUpdatePassword(): boolean {
    this.errorTexts = [];
    this.invalid.clear();

    let anyerr = false;

    if (this.currentPassword === '')             { this.errorTexts.push('Das aktuelle Passwort muss eingegeben werden');          this.invalid.add('currentPassword'); anyerr = true; }
    if (this.newPassword1.length < 8)            { this.errorTexts.push('Das neue Passwort muss mindestens 8 Zeichen lang sein'); this.invalid.add('newPassword1');    anyerr = true; }
    if (this.newPassword1 !== this.newPassword2) { this.errorTexts.push('Beide Passwörter müssen übereinstimmen');                this.invalid.add('newPassword2');    anyerr = true; }

    return !anyerr;
  }
}
