
<ng-template #t0>
  <div class="c1 icn">
    <fa-icon *ngIf="loading"                   icon="loader" animation="spin"></fa-icon>
    <fa-icon *ngIf="icon !== null && !loading" [icon]="icon"></fa-icon>
  </div>
  <div class="c2 txt"><ng-content></ng-content></div>
</ng-template>

<a *ngIf="anchorLink !== undefined && routerLink === undefined"
   [class.btndisabled]="disabled"
   class="button type-{{type}} hasicon-{{icon !== null || loading}} stretch-{{stretch}}"
   (click)="click($event)"
   [href]="anchorLink"
   [target]="anchorTarget">

  <ng-container *ngTemplateOutlet="t0"></ng-container>

</a>

<a *ngIf="anchorLink === undefined && routerLink !== undefined"
   [class.btndisabled]="disabled"
   class="button type-{{type}} hasicon-{{icon !== null || loading}} stretch-{{stretch}}"
   (click)="click($event)"
   [routerLink]="routerLink">

  <ng-container *ngTemplateOutlet="t0"></ng-container>

</a>

<a *ngIf="anchorLink === undefined && routerLink === undefined"
   [class.btndisabled]="disabled"
   class="button type-{{type}} hasicon-{{icon !== null || loading}} stretch-{{stretch}}"
   (click)="click($event)">

  <ng-container *ngTemplateOutlet="t0"></ng-container>

</a>

<a *ngIf="anchorLink !== undefined && routerLink !== undefined" class="button">
  ERROR ERROR ERROR ERROR ERROR ERROR ERROR ERROR
</a>
