import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'pfm-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() header: string|null = null;
  @Input() footer: string|null = null;

  @Input() theme: 'card'|'component' = 'card';

  @Input() headerExtra: TemplateRef<any>|null = null

}
