<frame-default [status]="status" errorMessage="Beim Laden der Kontakte ist ein Fehler aufgetreten">
  <main>

    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;"
                     [crumbs]="
                     [
                      {text:'Seiten'},
                      {text:'Firma', link: '/company/settings'},
                      {text:'Kontakte', link:'/contacts/list', active: true},
                     ]"></pfm-breadcrumbs>


    <div class="statistics">
      <pfm-statistics-text title="Kontakte insgesamt" [loading]="contactTotalCount === null" [value]="contactTotalCount ?? '...'"></pfm-statistics-text>
      <div class="spacer"></div>
      <pfm-button routerLink="/contacts/create" icon="plus">Kontakt anlegen</pfm-button>
    </div>

    <pfm-card header="Kontakte">
      <pfm-table [datasource]="contacts" [hoverHighlight]="true" [rowClickable]="true" (rowClicked)="rowClicked($event)">

        <pfm-table-col header="Name">
          <ng-template [ctxType]="{item:contacts[0]!}" let-item="item">{{item.name}}</ng-template>
        </pfm-table-col>
        <pfm-table-col header="Firma">
          <ng-template [ctxType]="{item:contacts[0]!}" let-item="item">{{item.companyName}}</ng-template>
        </pfm-table-col>
        <pfm-table-col header="Telefon">
          <ng-template [ctxType]="{item:contacts[0]!}" let-item="item">{{formatTel(item)}}</ng-template>
        </pfm-table-col>
        <pfm-table-col header="Mail">
          <ng-template [ctxType]="{item:contacts[0]!}" let-item="item">{{formatMail(item)}}</ng-template>
        </pfm-table-col>


        <pfm-table-col>
          <ng-template [ctxType]="{item:contacts[0]!}" let-item="item">
            <pfm-context-menu [stopClickPropagation]="true">
              <pfm-context-menu-item [icon]="['fat' ,'pen']" (onClick)="editContact(item);">Bearbeiten
              </pfm-context-menu-item>
              <pfm-context-menu-item [icon]="['fat', 'trash-can']" (onClick)="deleteContact(item)">Löschen
              </pfm-context-menu-item>
            </pfm-context-menu>
          </ng-template>
        </pfm-table-col>
      </pfm-table>
    </pfm-card>


  </main>
</frame-default>
