
<input #comp
       [valueAsDate]="this.inValue"
       (change)="this.inValue = eventToDate($event); inValueChange.emit(this.inValue)"
       [disabled]="disabled"
       [readonly]="readonly"
       type="date"
       [placeholder]="placeholder"
       [attr.aria-invalid]="invalid"
       [class.novalue]="this.inValue === null"
       (keyup.enter)="onEnterUp.emit($event)">
