import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BlobDataCompanyImage, BlobDataProjectImage, DataBlob} from "../../interfaces/datablob";
import {isNgDiff} from "../../utils/angular";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {environment} from "../../../environments/environment";
import {ObjectID, TemplateIdent} from "../../interfaces";

@Component({
  selector: 'pfm-layout-image-chooser',
  templateUrl: './layout-image-chooser.component.html',
  styleUrls: ['./layout-image-chooser.component.scss']
})
export class LayoutImageChooserComponent implements OnInit, OnChanges {

  @Input() projectid: ObjectID|null = null;
  @Input() projectids: ObjectID[] = [];
  @Input() reference: TemplateIdent|null = null;

  @Input()  show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output() success: EventEmitter<{ident:TemplateIdent, blobid:ObjectID}> = new EventEmitter<{ident:TemplateIdent, blobid:ObjectID}>();

  imagesLoadedProjIDStr: string = '';
  loading: boolean = false;

  images: DataBlob<BlobDataProjectImage|BlobDataCompanyImage>[] = [];

  constructor(private api: APIService,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    const projIDKey = `${this.projectid ?? 'NULL'};${this.projectids.join(':')}`;

    if (isNgDiff(changes, 'show') && this.show && this.imagesLoadedProjIDStr!==projIDKey) {
      this.loadImages(projIDKey).then(() => {});
    }
  }

  async loadImages(pidkey: string) {
    try {
      this.loading = true;

      const rall = await Promise.all([
        this.api.listAllCompanyImages(),
          ...[...new Set([this.projectid, ...this.projectids].filter(p => p !== null))].map(pid => this.api.listProjectImages(pid!))
      ]);

      this.images = rall.flatMap(p => p.images);
      this.imagesLoadedProjIDStr = pidkey;

    } catch (err) {
      showAPIError(this.notification, 'Bilder konnten nicht geladen werden', err);
    } finally {
      this.loading = false;
    }
  }

  imageSource(blobid: ObjectID) {
    return `${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID()}/images/${blobid}?xx-bearer-token=@${this.auth.getToken()}`;
  }

  onImageClicked(imgblob: DataBlob<BlobDataProjectImage | BlobDataCompanyImage>) {
    this.show = false;
    this.showChange.emit(false);

    if (this.reference === null) return;

    this.success.emit({"ident": this.reference, "blobid": imgblob.id})
  }
}
