import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'strToNFloat'
})
export class StrToNFloatPipe implements PipeTransform {

  transform(value: string): number|null {
    if (value === '') return null;
    if (isNaN(value as any as number)) return null; // https://stackoverflow.com/questions/62568464
    return parseFloat(value);
  }

}
