
<div class="switch-button" [ngStyle]="{ '--sel-index': selectedIndex, '--value-count': values.length }">

  <ng-container *ngFor="let val of values; index as idx">
    <div class="value-body" [class.selected]="idx === selectedIndex" (click)="selectedIndexChange.emit(selectedIndex = idx)">
      <ng-container *ngTemplateOutlet="(val.contentTemplate)"></ng-container>
    </div>
  </ng-container>

  <div class="switch-background"></div>

</div>

