import {Component, Input} from '@angular/core';

@Component({
  selector: 'pfm-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent {

  @Input() type: 'default'|'success'|'info'|'warning'|'error' = 'default';

}
