import {Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {TabComponent} from "./tab.component";

@Component({
  selector: 'pfm-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent {

  @ContentChildren(TabComponent) tabs!: QueryList<TabComponent>;

  @Input() selectedIndex: number = 0;
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();

  selectTab(tab: TabComponent, idx: number){
    this.selectedIndex = idx;
    this.selectedIndexChange.emit(idx);
  }

}
