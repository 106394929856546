<frame-default [status]="status" errorMessage="Beim Laden des Benutzers ist ein Fehler aufgetreten">
  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;"
                     back="/users/list"
                     [crumbs]="[{text:'Seiten'}, {text:'Nutzerverwaltung', link: '/users/list'}, {text: 'Nutzer anlegen', link: '/users/create', active: true}]" ></pfm-breadcrumbs>

      <div class="form-content">

        <div *ngIf="errorTexts.length > 0" class="alert-box">
          <pfm-alert *ngFor="let v of errorTexts">{{v}}</pfm-alert>
        </div>

        <label>Username<span class="required">*</span></label>
        <pfm-input #usernameInput
                   [(value)]="username"
                   [autoTrim]="true"
                   [preventAutofill]="true"
                   placeholder="Username"
                   [invalid]="isInvalid('username')"
                   (keyup.enter)="emailInput.focus()">
        </pfm-input>

        <label>E-Mail<span class="required">*</span></label>
        <pfm-input #emailInput
                   [(value)]="email"
                   [autoTrim]="true"
                   [preventAutofill]="true"
                   [invalid]="isInvalid('email')"
                   placeholder="E-Mail"
                   (keyup.enter)="inputPassword1.focus()"
        ></pfm-input>

        <label>Passwort<span class="required">*</span></label>
        <pfm-input #inputPassword1
                   [(value)]="password1"
                   [autoTrim]="false"
                   [preventAutofill]="true"
                   [invalid]="isInvalid('password1')"
                   type="password"
                   [showPasswordEye]="true"
                   [placeholder]="(mode === 'EDIT') ? 'Neues Passwort eingeben' : 'Passwort eingeben'"
        ></pfm-input>

        <ng-container *ngIf="mode === 'CREATE'">
          <label>Passwort wiederholen<span class="required">*</span></label>
          <pfm-input #inputPassword2
                     [(value)]="password2"
                     [autoTrim]="false"
                     [preventAutofill]="true"
                     [invalid]="isInvalid('password2')"
                     type="password"
                     [showPasswordEye]="true"
                     placeholder="Passwort wiederholen"
                     (keyup.enter)="createUser()"
          ></pfm-input>
        </ng-container>

        <pfm-button *ngIf="mode === 'CREATE'"
                    [stretch]="true"
                    [loading]="saveLoading"
                    type="primary"
                    (btnClick)="createUser()"
        >Registrieren</pfm-button>

        <pfm-button *ngIf="mode === 'EDIT'"
                    [stretch]="true"
                    [loading]="saveLoading"
                    type="primary"
                    (btnClick)="updateUser()"
        >Aktualisieren</pfm-button>
      </div>

  </main>
</frame-default>
