import {SerializedParamCollection, SerializedParamUtil} from './serializedParameter';

export function manipulateURLComponents(inurl: string, comps: (string|null)[], forceSuffix: string|SerializedParamCollection|null = null): string  {
  let suffix = "";
  let url = inurl;
  if (inurl.includes("?")) {
    url    = inurl.substring(0, inurl.lastIndexOf("?"));
    suffix = inurl.substring(inurl.lastIndexOf("?"));
  }

  const slashstart = url.startsWith('/');
  if (slashstart) url = url.substring(1);

  const slashend   = url.endsWith('/');
  if (slashend) url = url.substring(0, url.length-1);

  let rawsplit = url.split('/');

  let split = [];

  for (let i = 0; i < comps.length; i++) {
    if (comps[i] == null && i < rawsplit.length) split.push(rawsplit[i]);
    else split.push(comps[i]);
  }

  let comb = split.join('/');
  if (slashstart) comb = '/'+comb;
  if (slashend) comb = comb+'/';

  if (forceSuffix !== null)
  {
    if (typeof forceSuffix === 'string')
      suffix = forceSuffix;
    else
      suffix = SerializedParamUtil.ParameterToPath('', forceSuffix);
  }

  return comb + suffix;
}

export function buildURL(url: string, comps: (string|null)[], params: {key:string, value: string}[] = []): string  {

  if (url.includes("?")) url = url.substring(0, url.lastIndexOf("?"));

  const slashstart = url.startsWith('/');
  if (slashstart) url = url.substring(1);

  const slashend   = url.endsWith('/');
  if (slashend) url = url.substring(0, url.length-1);

  let rawsplit = url.split('/');

  let split = [];

  for (let i = 0; i < comps.length; i++) {
    if (comps[i] == null && i < rawsplit.length) split.push(rawsplit[i]);
    else split.push(comps[i]);
  }

  let comb = split.join('/');
  if (slashstart) comb = '/'+comb;
  if (slashend) comb = comb+'/';

  let suffix = '';
  if (params.length > 0) {
    suffix = "?" + params.map(p => `${p.key}=${encodeURIComponent(p.value)}`).join('&')
  }

  return comb + suffix;
}
