import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import * as uuid from "uuid";

@Component({
  selector: 'pfm-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent {

  _innerValue: string = '';
  get innerValue(): string { return this._innerValue; }
  set innerValue(newInnerValue: string) {
    this._innerValue = newInnerValue;
    if (this.autoTrim) {
      if (newInnerValue.trim() !== this.value) { this.valueChange.emit(this.value = newInnerValue.trim()); }
    } else {
      if (newInnerValue !== this.value) { this.valueChange.emit(this.value = newInnerValue); }
    }
  }

  _value: string = '';
  get value(): string { return this._value; }
  @Input() set value(newValue: string) { this._value = newValue; this._innerValue = newValue; }
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Input() values: {key: string, value: string}[] = [];

  @Output() onEnterUp: EventEmitter<any> = new EventEmitter<any>();

  @Input() type: 'text'|'password'|'number'|'tel'|'url'| 'email' = 'text';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() invalid: boolean|undefined = undefined;
  @Input() placeholder: string = '';
  @Input() preIcon: IconProp | null = null;
  @Input() autoTrim: boolean = true;
  @Input() showPasswordEye: boolean = false;

  @Input() preventAutofill: boolean = false;

  @ViewChild('comp') component!: ElementRef<HTMLInputElement>;

  realPasswordHidden = true;

  uuidName: string = uuid.v4();
  uuidAutocomplete: string = `chrome-disable-${uuid.v4()}`;
  autoFillDisabledOverride: boolean = false;

  ngOnInit(): void {
    if (this.preventAutofill) {
      this.startAutofillPrevention();
    }
  }

  focus() {
    this.component.nativeElement.focus();
  }

  startAutofillPrevention() {
    this.autoFillDisabledOverride = true;
    setTimeout(() => { this.autoFillDisabledOverride = false; }, 100)
  }

  cancelAutofillPrevention() {
    this.autoFillDisabledOverride = false;
  }
}
