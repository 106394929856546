
<pfm-modal [show]="expose !== null" (showChange)="onModalShowChanged($event)">

  <div class="content">

    <h3 *ngIf="state === 'LOADING'"  >Exposè wird geladen</h3>
    <h3 *ngIf="state === 'CREATING'" >Exposè wird erstellt</h3>
    <h3 *ngIf="state === 'DOWNLOAD'" >Exposè downloaden</h3>
    <h3 *ngIf="state === 'ERROR'"    >Fehler</h3>

    <fa-icon *ngIf="state === 'LOADING'"
             class="icon load-icon"
             [icon]="['fas', 'loader']" animation="spin"></fa-icon>

    <fa-icon *ngIf="state === 'CREATING'"
             class="icon build-icon"
             [icon]="['fas', 'loader']" animation="spin"></fa-icon>

    <a class="pdf-anch" [href]="downloadURL()">
      <fa-icon *ngIf="state === 'DOWNLOAD'"
               class="icon pdf-icon"
               [icon]="['fas', 'file-pdf']"></fa-icon>
    </a>

    <fa-icon *ngIf="state === 'ERROR'"
             class="icon err-icon"
             [icon]="['fas', 'user-robot-xmarks']"></fa-icon>

  </div>

</pfm-modal>
