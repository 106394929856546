import {Component, OnInit} from '@angular/core';
import {APIService} from "../../services/api.service";
import {APIError, CursorToken, User} from "../../interfaces";
import {ModalService} from "../../services/modal.service";
import {NotificationService} from "../../services/notification.service";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {showAPIError} from "../../utils/api";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {manipulateURLComponents} from "../../utils/url";
import {Location} from "@angular/common";

@Component({
  selector: 'pfm-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements  OnInit {

  status: 'loading'|'error'|'content' = 'loading';
  loading: boolean = true;

  users: User[] = [];
  totalUserCount: number|null = null;

  self: User|null = null;

  nextToken: CursorToken = "@start";

  filters: SerializedParamCollection = {
    search: { active: null, default: null, type: 'string' },
  };

  constructor(private api: APIService,
              private modal: ModalService,
              private activatedRoute: ActivatedRoute,
              private notification: NotificationService,
              private router: Router,
              private location: Location,
              private auth: AuthenticationService) {
  }

  async ngOnInit() {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });

    try {
      this.self = await this.auth.getSelf();
    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err);
      this.status = 'error';
    }
  }

  async initializeFromQueryParams(params: any) {
    try {
      this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
      await Promise.all([
        this.fetchData(true),
        this.fetchCount(),
      ])
    } catch (err) {
      showAPIError(this.notification, 'Daten aus URL konnten nicht geladen werden', err);
    }
  }

  async fetchData(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;
      if (reset) this.status = 'loading';

      const data = await this.api.listUsers(this.nextToken, 48, this.filters);

      if (reset) {
        this.users = data.users;
      } else {
        this.users = [...this.users, ...data.users];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

      if (reset) this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      if (reset) this.status = 'error';
    } finally {
      this.loading = false;
    }
  }

  async fetchCount() {
    try {

      this.totalUserCount = null;
      const data = await this.api.getUserCount(this.filters);
      this.totalUserCount = data.count;

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['users', 'list'], this.filters));
  }

  async deleteUser(user: User) {
     this.modal.openDialog({
        title: 'User löschen',
        message: 'Das löschen kann nicht Rückgängig gemacht werden.',
        type: 'danger',
        onClose: () => null,
        onSubmit: () => {
          this.api.deleteUser(user.id).then(() => {
            this.users = this.users.filter(p => p.id != user.id);
            this.notification.success('User gelöscht', user.username);
          }).catch((err: APIError) => {
            this.notification.error('Fehler beim Löschen', err.message)
          })
        }
      })
  }

  async editUser(item: User) {
    await this.router.navigate(['/', 'users', item.id, 'edit']);
  }
}
