import {Component, Input} from '@angular/core';

@Component({
  selector: 'pfm-border',
  templateUrl: './border.component.html',
  styleUrls: ['./border.component.scss']
})
export class BorderComponent {
  @Input() clickable: boolean = false;
}
