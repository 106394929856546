import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'pfm-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent {

  @Input() icon: IconProp|null = null;
  @Input() loading: boolean = false;
  @Input() routerLink: any[] | string | null | undefined = undefined;
  @Input() anchorLink: string | undefined = undefined;
  @Input() anchorTarget: '_self'|'_blank'|'_parent'|'_top' = '_self';

  @Input() stretch: boolean = false;
  @Input() type: 'default'|'sub'|'primary'|'sub-trans' = 'default';

  @Input() disabled: boolean = false;

  @Output() btnClick = new EventEmitter<MouseEvent>();

  click($event: any) {
    if (this.disabled || this.loading) {
      return;
    }
    this.btnClick.emit($event)
  }
}
