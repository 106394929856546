<div *ngIf="type === 'text-insert' && ident !== null"
     class="block text-insert"
     [style.left]="pix_x(position[0])"
     [style.top]="pix_y(position[1])"
     [style.width]="pix_x(position[2])"
     [style.height]="pix_y(position[3])"
     [class.text-set]="isTextSet(ident)"
     (click)="textBlockClick(ident)">
  {{txttxt(ident)}}
</div>

<div *ngIf="type === 'image-insert' && ident !== null"
     class="block image-insert"
     [style.left]="pix_x(position[0])"
     [style.top]="pix_y(position[1])"
     [style.width]="pix_x(position[2])"
     [style.height]="pix_y(position[3])"
     [class.image-set]="isImageSet(ident)"
     >

  <img   *ngIf="isImageSet(ident)" [src]="bgimg(ident, true, true)" alt="image">

  <div *ngIf="!isImageSet(ident)" class="imgtext" (click)="imageBlockClick(ident)">
    <label>{{imgtxt(ident)}}</label>
  </div>

  <div class="actioncontainer">
    <div class="actionlist">
      <div class="action edit"   (click)="editImageClick(ident)" ><fa-icon [icon]="['fas', 'pen'  ]"></fa-icon></div>
      <div class="action crop"   (click)="cropImageClick(ident)" ><fa-icon [icon]="['fas', 'crop' ]"></fa-icon></div>
      <div class="action delete" (click)="deleteImageClick(ident)" ><fa-icon [icon]="['fas', 'trash']"></fa-icon></div>
    </div>
  </div>

</div>

<div *ngIf="type === 'generic-text'"
     class="block generic-text"
     [style.left]="pix_x(position[0])"
     [style.top]="pix_y(position[1])"
     [style.width]="pix_x(position[2])"
     [style.height]="pix_y(position[3])">{{gentexttxt()}}</div>
