<frame-default>

  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;" [crumbs]="[{text:'Seiten'}, {text:'Dashboard', link: '/', active: true},]" ></pfm-breadcrumbs>

    <span class="title" *ngIf="user === null">Willkommen zurück!</span>
    <span class="title" *ngIf="user !== null">Willkommen zurück, {{user.username}}!</span>
    <span class="subtitle">{{company?.name ?? '...'}}</span>

    <div class="statistics">
      <pfm-statistics-text [icon]="['fas', 'folder']" title="Projekte insgesamt" [value]="projectTotalCount"></pfm-statistics-text>

      <div class="spacer"></div>
      <pfm-button routerLink="/projects/create" icon="plus">Projekt anlegen</pfm-button>
    </div>

    <div style="display: grid; grid-template-columns: 1fr 1fr; grid-template-rows: 1fr 1fr; grid-column-gap: 1rem; grid-row-gap: 1rem">

      <pfm-info-card
        text="Unsere Projekte-Seite bietet einen Überblick über alle Ihre
              laufenden Bauprojekte. Hier finden Sie eine Liste mit allen
              wichtigen Details wie Status, Fortschritt und Fälligkeiten."
        title="Projekte"
        [icon]="['fat', 'files']"
        buttonIcon="plus"
        routerLink="/projects/list"
        buttonLabel="Projekt anlegen"
        buttonRouterLink="/projects/create"
      ></pfm-info-card>

      <pfm-info-card text="Hier können Sie eine Übersicht aller erstellten Exposès anzeigen lassen.
                           Behalten Sie den Überblick über anstehende Aufgaben, Kosten und Zeitpläne."
                     [icon]="['fat', 'table-layout']"
                     title="Exposès"
                     buttonIcon="plus"
                     routerLink="/exposes/list"
                     buttonLabel="Exposè anlegen"
                     buttonRouterLink="/exposes/create"
      ></pfm-info-card>

      <pfm-info-card text="Hier können Sie eine Übersicht aller erstellten Portfolios anzeigen lassen.
                           Ein Portfolio fässt Informationen und Bilder von mehren Projekten zusammen."
                     [icon]="['fat', 'briefcase']"
                     title="Portfolios"
                     buttonIcon="plus"
                     routerLink="/portfolios/list"
                     buttonLabel="Portfolio anlegen"
                     buttonRouterLink="/portfolios/create"
      ></pfm-info-card>

      <pfm-info-card text="Hier können Sie eine Übersicht Ihrer erstellten Projektlisten anzeigen lassen.
                           Eine Projektliste fässt Informationen zu vielen Projekten tabellarisch zusammen.
                           Behalten Sie den Überblick über anstehende Aufgaben, Kosten und Zeitpläne."
                     [icon]="['fat', 'clipboard-list']"
                     title="Projektlisten"
                     buttonIcon="plus"
                     routerLink="/projectlists/list"
                     buttonLabel="Projektliste anlegen"
                     buttonRouterLink="/projectlists/create"
      ></pfm-info-card>

    </div>
  </main>
</frame-default>
