<div id="primary">

  <nav>
    <div class="title"><img class="logo" ngSrc="assets/logo.svg" height="52" width="52" alt="logo">Portfoliomanager
    </div>

    <span class="spacer"></span>

    <a class="nav-btn" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
      <fa-icon [icon]="['fas', 'grid-2']"></fa-icon>
      <span>Dashboard</span>
    </a>

    <a class="nav-btn" routerLink="/projects/list" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'files']"></fa-icon>
      <span>Projekte</span>
    </a>

    <a class="nav-btn" routerLink="/exposes/list" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'table-layout']"></fa-icon>
      <span>Exposès</span>
    </a>

    <a class="nav-btn" routerLink="/portfolios/list" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'briefcase']"></fa-icon>
      <span>Portfolios</span>
    </a>

    <a class="nav-btn" routerLink="/projectlists/list" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'clipboard-list']"></fa-icon>
      <span>Projektlisten</span>
    </a>

    <span class="spacer"></span>

    <a class="nav-btn" routerLink="/company/settings" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'building']"></fa-icon>
      <span>Firma</span>
    </a>

    <a class="nav-btn" routerLink="/users/list" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'users']"></fa-icon>
      <span>Nutzerverwaltung</span>
    </a>

    <a class="nav-btn" routerLink="/images/" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'images']"></fa-icon>
      <span>Bilder</span>
    </a>

    <a class="nav-btn" routerLink="/contacts/list" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'address-book']"></fa-icon>
      <span>Kontakte</span>
    </a>

    <span class="spacer"></span>

    <a class="nav-btn" routerLink="/profile/" routerLinkActive="active">
      <fa-icon [icon]="['fat', 'user-circle']"></fa-icon>
      <span>Account</span>
    </a>

    <a class="nav-btn" (click)="logout()">
      <fa-icon [icon]="['fat', 'right-from-bracket']"></fa-icon>
      <span>Abmelden</span>
    </a>

  </nav>

  <div id="content">

    <ng-container *ngIf="status === 'loading'">
      <div class="loading-div">
        <fa-icon icon="loader" animation="spin"></fa-icon>
      </div>
    </ng-container>

    <ng-container *ngIf="status === 'error'">
      <div class="error-div">
        <fa-icon icon="user-robot-xmarks"></fa-icon>
        <span class="errtext">{{errorMessage}}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="status === 'content'">
      <ng-content></ng-content>
      <div class="bottom-scroll-fix"></div>
    </ng-container>

  </div>
</div>


