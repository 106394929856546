<div class="modal-container" *ngIf="(dialogModals.length + genericModals.length) !== 0">

  <div class="modal-background" [class.blur]="needsBlur(dialogModals, genericModals)" #modalBackground (click)="onBackgroundClick($event, modalBackground)"></div>

  <ng-container *ngFor="let modal of genericModals">

    <div class="modal-box" #modalBox (click)="onBackgroundClick($event, modalBox)">
      <div class="modal generic" #genericModalContainer
           [class.withBackground]="modal.modal.withBackground"
           [style.width]="modal.modal.modalWidth"
           [style.height]="modal.modal.modalHeight"
           [style.position]="(modal.modal.position === undefined) ? 'default' : 'absolute'"
           [style.left.px]="position_left(modal.modal, genericModalContainer, modalBackground)"
           [style.top.px]="position_top(modal.modal, genericModalContainer, modalBackground)"
           [style.right.px]="position_right(modal.modal, genericModalContainer, modalBackground)"
           [style.bottom.px]="position_bottom(modal.modal, genericModalContainer, modalBackground)">
        <ng-container *ngTemplateOutlet="modal.modal.content"></ng-container>
      </div>
    </div>

  </ng-container>

  <ng-container  *ngFor="let modal of dialogModals">

    <div class="modal-box" #modalBox (click)="onBackgroundClick($event, modalBox)">
      <div class="modal dialog withBackground">
        <div class="body">
          <h2>{{modal.modal.title}}</h2>
          <p class="message">{{modal.modal.message}}</p>
        </div>
        <div class="footer">
          <pfm-button class="button" type="default" (btnClick)="dialogAbortClicked(modal)"   >Abbrechen</pfm-button>
          <pfm-button class="button" type="default" (btnClick)="dialogConfirmClicked(modal)" >Bestätigen</pfm-button>
        </div>
      </div>
    </div>

  </ng-container>

</div>
