import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {isNgDiff} from "../../utils/angular";
import {ModalService} from "../../services/modal.service";

@Component({
  selector: 'pfm-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnChanges {

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() modalWidth: string|undefined = undefined;
  @Input() modalHeight: string|undefined = undefined;

  @ViewChild('template', { static: true }) templRef!: TemplateRef<any>;

  modalid: string|null = null;

  constructor(private modal: ModalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show) {
        this.modalid = this.modal.openGeneric({
          content:this.templRef,
          onClose: () => { this.modalid = null; this.show = false; this.showChange.emit(false); },
          modalWidth: this.modalWidth,
          modalHeight: this.modalHeight,
          withBackground: true,
        })
      } else {
        if (this.modalid !== null) this.modal.close(this.modalid);
        this.modalid = null;
      }
    }
  }

}
