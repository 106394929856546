import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {APIService} from "../../services/api.service";
import {isErrorCode, showAPIError} from "../../utils/api";
import {validateEmailRegex} from "../../utils/email";
import {NotificationService} from "../../services/notification.service";
import {ObjectID} from "../../interfaces";

@Component({
  selector: 'pfm-user-editcreate',
  templateUrl: './user-editcreate.component.html',
  styleUrls: ['./user-editcreate.component.scss']
})
export class UserEditCreateComponent implements OnInit {

  mode: 'CREATE'|'EDIT' = 'CREATE';
  status: 'loading'|'error'|'content' = 'loading';

  saveLoading: boolean = false;
  errorTexts: string[] = [];

  editUserID: ObjectID|null = null;

  username: string = '';
  email: string = '';
  password1: string = '';
  password2: string = '';

  invalid = new Set<string>();

  constructor(private router: Router,
              private api: APIService,
              private activatedRoute: ActivatedRoute,
              private notification: NotificationService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.data['mode'] === 'CREATE') {
      this.editUserID = null;
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.status = 'content';
    } else if (this.activatedRoute.snapshot.data['mode'] === 'EDIT') {
      this.editUserID = this.activatedRoute.snapshot.params['usrid'];
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchExistingData(this.editUserID!).then(() => {});
    } else {
      this.status = 'error';
      this.notification.error('Fehler', 'Komponente ist in einem invaliden Zustand');
    }
  }

  async fetchExistingData(userid: ObjectID) {
    try {
      const data = await this.api.getUser(userid);

      this.username  = data.username;
      this.email     = data.email;
      this.password1 = '';
      this.password2 = '';

      this.status = 'content';

    } catch (err) {
      this.status = 'error';
      showAPIError(this.notification, 'Benutzer konnten nicht geladen werden', err);
    } finally {
      this.saveLoading = false;
    }
  }

  async createUser() {

    if (!this.validate()) return

    try {
      this.saveLoading = true;

      await this.api.createUser(this.email, this.password1, this.username);

      await this.router.navigate(['/users/list'], {queryParams: {}});

    } catch (e: any) {
      if (isErrorCode(e, 'DATABASE_ERROR')) {
        this.errorTexts.push('Leider ist beim Erstellen des Accounts etwas schiefgelaufen, versuchen Sie es nochmal.');
      } else if (isErrorCode(e, 'USERNAME_COLLISION')) {
        this.errorTexts.push('Leider ist der Username bereits vergeben, ändern Sie ihn und versuchen sie es erneut.');
      } else if(isErrorCode(e, 'EMAIL_COLLISION')) {
        this.errorTexts.push('Leider ist die E-Mail bereits vergeben, ändern Sie sie und versuchen sie es erneut.');
      } else if (isErrorCode(e, 'INTERNAL_ERROR')) {
        this.errorTexts.push('Leider ist beim Erstellen des Accounts etwas schiefgelaufen, versuchen Sie es nochmal.');
      } else if (isErrorCode(e, 'UNAUTHORIZED')) {
        this.errorTexts.push('Sie sind nicht berechtigt diese Aktion auszuführen.');
      } else {
        this.errorTexts.push('Leider ist beim Erstellen des Accounts etwas schiefgelaufen, versuchen Sie es nochmal.');
      }
    } finally {
      this.saveLoading = false;
    }
  }

  async updateUser() {

    if (!this.validate()) return

    try {
      this.saveLoading = true;

      await this.api.updateCompanyUser(this.editUserID!, this.username, this.email, this.password1 === '' ? null : this.password1);

      await this.router.navigate(['/users/list'], {queryParams: {}});

    } catch (e: any) {
      if (isErrorCode(e, 'DATABASE_ERROR')) {
        this.errorTexts.push('Leider ist beim Aktualisieren des Accounts etwas schiefgelaufen, versuchen Sie es nochmal.');
      } else if (isErrorCode(e, 'USERNAME_COLLISION')) {
        this.errorTexts.push('Leider ist der Username bereits vergeben, ändern Sie ihn und versuchen sie es erneut.');
      } else if(isErrorCode(e, 'EMAIL_COLLISION')) {
        this.errorTexts.push('Leider ist die E-Mail bereits vergeben, ändern Sie sie und versuchen sie es erneut.');
      } else if (isErrorCode(e, 'INTERNAL_ERROR')) {
        this.errorTexts.push('Leider ist beim aktualisieren des Accounts etwas schiefgelaufen, versuchen Sie es nochmal.');
      } else if (isErrorCode(e, 'UNAUTHORIZED')) {
        this.errorTexts.push('Sie sind nicht berechtigt diese Aktion auszuführen.');
      } else {
        this.errorTexts.push('Leider ist beim Bearbeiten des Accounts etwas schiefgelaufen.');
      }
    } finally {
      this.saveLoading = false;
    }
  }

  isInvalid(key: string) {
    return this.invalid.has(key) ? true : undefined;
  }

  validate(): boolean {
    this.errorTexts = [];
    this.invalid.clear();

    let anyerr = false;

    if (this.mode === 'CREATE') {

      if (this.username.length < 4)          { this.errorTexts.push('Der Benutzername muss mindestens 4 Zeichen lang sein'); this.invalid.add('username');  anyerr = true; }
      if (this.password1.length < 8)         { this.errorTexts.push('Das Passwort muss mindestens 8 Zeichen lang sein');     this.invalid.add('password1'); anyerr = true; }
      if (!validateEmailRegex(this.email))   { this.errorTexts.push('Bitte eine gültige Email eingeben');                    this.invalid.add('email');     anyerr = true; }
      if (this.password1 !== this.password2) { this.errorTexts.push('Beide Passwörter müssen übereinstimmen');               this.invalid.add('password2'); anyerr = true; }

    } else if (this.mode === 'EDIT') {

      if (this.username.length < 4)                          { this.errorTexts.push('Der Benutzername muss mindestens 4 Zeichen lang sein'); this.invalid.add('username');  anyerr = true; }
      if (!validateEmailRegex(this.email))                   { this.errorTexts.push('Bitte eine gültige Email eingeben');                    this.invalid.add('email');     anyerr = true; }
      if (this.password1 != '' && this.password1.length < 8) { this.errorTexts.push('Das Passwort muss mindestens 8 Zeichen lang sein');     this.invalid.add('password1'); anyerr = true; }

    }

    return !anyerr;
  }
}
