import {Component, OnDestroy, OnInit} from '@angular/core';
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {Expose} from "../../interfaces/expose";
import {debounce, interval, Subject, Subscription} from "rxjs";
import {CursorToken, ObjectID, ProjectEnums, TemplateKey} from "../../interfaces";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'pfm-expose-list',
  templateUrl: './expose-list.component.html',
  styleUrls: ['./expose-list.component.scss']
})
export class ExposeListComponent implements OnInit, OnDestroy {

  status: 'loading'|'error'|'content' = 'loading';
  loading: boolean = true;
  exposes: Expose[] = [];
  exposeTotalCount: number|null = null;
  nextToken: CursorToken = "@start";
  filters: SerializedParamCollection = {
    search: { active: null, default: null, type: 'string' },
  };

  downloadExpose: Expose|null = null;

  searchObservable:   Subject<string>|null = null;
  searchSubscription: Subscription|null = null;

  metavalues: ProjectEnums|null = null;

  constructor(private api: APIService,
              private meta: MetaService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
    this.searchObservable   = new Subject<string>();
    this.searchSubscription = this.searchObservable.pipe(debounce(() => interval(500))).subscribe(() => {
      Promise.all([
        this.fetchData(false, true),
        this.fetchCount(),
      ]).then(() => {});
    });

    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe()
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await Promise.all([
      this.fetchData(true, true),
      this.fetchCount(),
    ]);
  }

  async fetchData(initial: boolean, reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;
      if (initial) this.status = 'loading';

      const data = await this.api.listExposes(this.nextToken, 48, this.filters);

      if (initial) this.metavalues = await this.meta.getProjectEnums();

      if (reset) {
        this.exposes = data.exposes;
      } else {
        this.exposes = [...this.exposes, ...data.exposes];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

      if (initial) this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      if (initial) this.status = 'error';
    } finally {
      this.loading = false;
    }
  }

  async fetchCount() {
    try {

      this.exposeTotalCount = null;
      const data = await this.api.countExposes({});
      this.exposeTotalCount = data.count;

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  resolveProject = async (projectid: ObjectID) => { return await this.api.getProject(projectid); }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['exposes', 'list'], this.filters));
  }

  async editExpose(item: Expose) {
    await this.router.navigate(['/', 'exposes', item.id, 'edit']);
  }

  deleteExpose(item: Expose) {
    this.modal.openDialog({
      title: 'Exposè löschen',
      message: 'Das Löschen kann nicht rückgängig gemacht werden.',
      type: 'danger',
      onSubmit: async () => {
        try {
          await this.api.deleteExpose(item.id);
          this.exposes = this.exposes.filter(c => c.id != item.id);
          this.notification.success('Exposè gelöscht', item.name);
        } catch (err) {
          showAPIError(this.notification, 'Fehler beim Löschenn', err);
        }
    },
    });
  }

  translateTemplate(template: TemplateKey): string {
    if (this.metavalues === null) return template;
    for (const v of this.metavalues.exposeTemplate) {
      if (v.value === template) return v.description;
    }
    return template;
  }
}
