import {Component, HostListener} from '@angular/core';
import {
  EnumMetaValue,
  isValidationError,
  ObjectID,
  Portfolio,
  Project,
  ProjectEnums,
  TemplateIdent
} from "../../interfaces";
import {ValidationRef} from "../../interfaces/validation";
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {showAPIError} from "../../utils/api";
import {deepclone} from "../../utils/angular";
import {environment} from "../../../environments/environment";
import {Projectlist} from "../../interfaces/projectlist";
import {TemplateImageConfig} from "../../interfaces/templateConfig";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'pfm-projectlist-editcreate',
  templateUrl: './projectlist-editcreate.component.html',
  styleUrls: ['./projectlist-editcreate.component.scss']
})
export class ProjectlistEditCreateComponent {

  mode: 'CREATE' | 'EDIT' = 'CREATE';
  editProjectlistID: ObjectID|null = null;
  serverData: Projectlist|null = null;

  selectedTab = 0;

  status: 'loading' | 'error' | 'content' = 'loading';

  metavalues: ProjectEnums|null = null;
  allprojects: Project[]|null = null;

  projectChooserValue: ObjectID|null = null;

  selectedTemplate: TemplateIdent|null = null;
  selectedProjects: ObjectID[] = [];

  name: string = '';

  imagesConfig: { [ _p: string ]: TemplateImageConfig | null } = {};
  textsConfig:  { [ _p: string ]: string              | null } = {};

  _serverData: any|null = null;

  validationErrors: Set<ValidationRef> = new Set<ValidationRef>();

  downloadProjectlist: Projectlist|null = null;

  constructor(private api: APIService,
              private meta: MetaService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data['mode'] === 'CREATE') {
      this.editProjectlistID = null;
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchData(this.editProjectlistID).then(() => {});
    } else if (this.activatedRoute.snapshot.data['mode'] === 'EDIT') {
      this.editProjectlistID = this.activatedRoute.snapshot.params['plid'];
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchData(this.editProjectlistID).then(() => {});
    } else {
      this.status = 'error';
      this.notification.error('Fehler', 'Komponente ist in einem invaliden Zustand');
    }
  }

  @HostListener('window:beforeunload')
  hasNoPendingChanges = (): boolean => {
    return JSON.stringify(this.getProjectlistData()) === JSON.stringify(this._serverData);
  }

  async fetchData(projectlistid: ObjectID|null) {
    try {
      this.status = 'loading';

      this.metavalues = await this.meta.getProjectEnums();
      this.allprojects = (await this.api.listProjects("@start", null)).projects.filter(p => p.classification === null || p.classification === "INTERN" || p.classification === 'EXTERN');

      const qpTemplate = this.activatedRoute.snapshot.queryParams['template'];
      if (qpTemplate !== undefined) {
        if (this.metavalues.projectlistTemplate.find(p => p.value === qpTemplate) != undefined) {
          this.selectedTemplate = qpTemplate;
        }
      }

      if (projectlistid !== null) {
        const projectlist = await this.api.getProjectlist(projectlistid);
        await this.setAllValues(projectlist);
      }

      this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      this.status = 'error';
    }
  }

  async setAllValues(projectlist: Projectlist) {

    this.serverData = deepclone(projectlist);

    this.selectedTemplate = projectlist.template;
    this.selectedProjects = projectlist.projectIDs;
    this.name             = projectlist.name;
    this.imagesConfig     = projectlist.templateConfig.mainImages;
    this.textsConfig      = projectlist.templateConfig.mainTexts;

    this._serverData = this.getProjectlistData();
  }

  async createOrUpdateProjectlist(navigate = false) {
    if (this.mode === 'CREATE') await this.createProjectlist(navigate);
    if (this.mode === 'EDIT')   await this.updateProjectlist(navigate);
  }

  async createProjectlist(navigate: boolean) {
    this.validationErrors.clear();

    if (this.name             === '')         { this.validationErrors.add('PROJECTLIST_NAME');  }
    if (this.selectedProjects === null)       { this.validationErrors.add('PROJECT_ID');   }
    if (this.selectedProjects.length === 0)   { this.validationErrors.add('PROJECT_ID');   }
    if (this.selectedTemplate === null)       { this.validationErrors.add('TEMPLATE_KEY'); }

    if (this.validationErrors.size > 0) return;

    try {
      let data = await this.api.createProjectlist(this.name, this.selectedProjects, this.selectedTemplate!, {mainImages: this.imagesConfig, mainTexts: this.textsConfig});

      if (navigate) {
        await this.router.navigate(['/projectlists/list']);
      } else {
        await this.setAllValues(data);
        this._serverData = this.getProjectlistData();
        this.notification.success("Projektliste gespeichert!",'')
      }

      await this.router.navigate(['/projectlists/list']);

    } catch (err) {
      if (isValidationError(err, 'PROJECTLIST_VALIDATION_FAILED')) {
        this.notification.error('Projektliste konnten nicht erstellt werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference as ValidationRef);
      } else {
        showAPIError(this.notification, 'Projektliste konnten nicht erstellt werden', err);
      }
    }
  }

  async updateProjectlist(navigate: boolean) {
    if (this.editProjectlistID === null) return;

    this.validationErrors.clear();

    if (this.name             === '')         { this.validationErrors.add('PROJECTLIST_NAME');  }
    if (this.selectedProjects === null)       { this.validationErrors.add('PROJECT_ID');   }
    if (this.selectedProjects.length === 0)   { this.validationErrors.add('PROJECT_ID');   }
    if (this.selectedTemplate === null)       { this.validationErrors.add('TEMPLATE_KEY'); }

    if (this.validationErrors.size > 0) return;

    try {
      let data = await this.api.updateProjectlist(this.editProjectlistID, this.name, this.selectedProjects, this.selectedTemplate!, {mainImages:this.imagesConfig, mainTexts:this.textsConfig});

      if (navigate) {
        await this.router.navigate(['/projectlists/list']);
      } else {
        await this.setAllValues(data);
        this._serverData = this.getProjectlistData();
        this.notification.success("Projektliste gespeichert!",'')
      }

    } catch (err) {
      if (isValidationError(err, 'PROJECTLIST_VALIDATION_FAILED')) {
        this.notification.error('Projektliste konnten nicht bearbeitet werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference);
      } else {
        showAPIError(this.notification, 'Projektliste konnten nicht bearbeitet werden', err);
      }
    }
  }

  getProjectlistData() {
    return {
      'id':       this.editProjectlistID,
      'name':     this.name,
      'projects': this.selectedProjects,
      'template': this.selectedTemplate,
      'config':    {mainImages: this.imagesConfig, mainTexts: this.textsConfig},
    }
  }

  projectsToKV(v: Project[]) {
    return v.map(p => ({key: p.id, value: p.projectName}));
  }

  metaToKeyValue(v: EnumMetaValue<string, string>[]) {
    return v.map(p => ({key: p.value, value: p.description}));
  }

  metaToKeyValueExt(vSelected: string|null, vAll: EnumMetaValue<string, string>[], vLim: EnumMetaValue<string, string>[]) {
    if (vSelected !== null && !vLim.find(p => p.value === vSelected) && vAll.find(p => p.value === vSelected)) {
      // a enumValue is selected that only exists in [vAll], add it to the select values for now
      return vLim.map(p => ({key: p.value, value: p.description})).concat(({key: vSelected, value: vAll.find(p => p.value === vSelected)!.description}));
    } else {
      return vLim.map(p => ({key: p.value, value: p.description}));
    }
  }

  validate(...args: ValidationRef[]): boolean|undefined {
    return args.find(p => this.validationErrors.has(p))  ? true : undefined;
  }

  resolveProject(pid: ObjectID): Project|null {
    return this.allprojects?.find(p => p.id === pid) ?? null;
  }

  removeProject(pid: ObjectID) {
    this.selectedProjects = this.selectedProjects.filter(p => p != pid);
  }

  addProject(pid: ObjectID|null) {
    if (pid === null) return;

    if (!this.selectedProjects.includes(pid)) this.selectedProjects = [...this.selectedProjects, pid];

    setTimeout(() => {this.projectChooserValue = null;}, 0); // weird hack???
  }

  castObjectID(v: string|null) {
    return v as (ObjectID|null);
  }
}
