import {Component, EventEmitter, Input, Output} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {ContextMenuComponent} from "./context-menu.component";

@Component({
  selector: 'pfm-context-menu-item',
  templateUrl: './context-menu-item.component.html',
  styleUrls: ['./context-menu-item.component.scss']
})
export class ContextMenuItemComponent {

  @Input()  icon: IconProp|null = null;

  @Output() onClick = new EventEmitter<MouseEvent>();

  constructor(public parent: ContextMenuComponent) { }
  onAction(event: MouseEvent){

    this.onClick.emit(event);
    this.parent.close();

    if (this.parent.stopClickPropagation) {
      event.stopPropagation();
    }
  }
}

