import {Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {TableColComponent} from "./table-col.component";

@Component({
  selector: 'pfm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent {

  @Input() datasource: any[] = [];
  @Input() type: 'default'|'bordered' = 'default';
  @Input() invalid: boolean|undefined = undefined; //TODO
  @Input() hoverHighlight: boolean = false;
  @Input() loading: boolean = false;
  @Input() rowClickable: boolean = false;

  @Output() rowClicked: EventEmitter<any> = new EventEmitter<any>();

  @ContentChildren(TableColComponent) coldefs!: QueryList<TableColComponent>;

  onClick(data: any) {
    if (this.rowClickable) this.rowClicked.emit(data)
  }
}
