import { Pipe, PipeTransform } from '@angular/core';
import {DateUtils} from "../../../utils/date";

@Pipe({ name: 'strToNDate' })
export class StrToNDatePipePipe implements PipeTransform {

  transform(value: string): Date|null {
    if (value === '') return null;
    if (!DateUtils.isRFC3339(value)) return null;
    return DateUtils.parseRFC3339(value);
  }

}
