import { Component } from '@angular/core';

@Component({
  selector: 'empty-frame',
  templateUrl: './empty-frame.component.html',
  styleUrls: ['./empty-frame.component.scss']
})
export class EmptyFrameComponent {

}
