import {Component, HostListener, OnInit} from '@angular/core';
import {
  Address,
  CreateNewProject, CreateNewProjectFunctionContact, CreateNewProjectRootContact,
  HOAIServicePhases, isValidationError, LinkProjectImage,
  MoneyCent,
  NewAddress,
  NewHOAIServicePhases, ObjectID, Project, ProjectFunctionContact, ProjectRootContact, rfc3339ToNullDate, User
} from "../../interfaces";
import {RFC3339} from "../../interfaces";
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {showAPIError} from "../../utils/api";
import {EnumMetaValue, ProjectEnums} from "../../interfaces";
import {Contact} from "../../interfaces";
import {displayContact} from "../../utils/businesslogic";
import * as uuid from "uuid";
import {environment} from "../../../environments/environment";
import {blobToBase64} from "../../utils/blob";
import {DateUtils} from "../../utils/date";
import {ValidationRef} from "../../interfaces/validation";
import {preloadImage} from "../../utils/image";
import {deepclone} from "../../utils/angular";
import {BlobDataCompanyImage, BlobDataProjectImage, DataBlob, NewImageData} from "../../interfaces/datablob";
import {PendingChangesGuarded} from "../../guards/pending-changes.guard";
import {MetaService} from "../../services/meta.service";

interface CreateProjectFunctionContact {
  uuid: string;

  service: string;

  id: 'NEW'|ObjectID|null;

  companyID: ObjectID|'';
  name: string;
  companyName: string;
  mail: string;
  companyMail: string;
  address: Address;
  phone: string;
  companyPhone: string;
  companyProjects: string;
  companyTask: string;
  creationTime: RFC3339;
  lastUpdated: RFC3339;
}

interface CreateProjectRootContact {
  privacy: string|null;

  id: 'NEW'|ObjectID|null;

  companyID: ObjectID|'';
  name: string;
  companyName: string;
  address: Address;
  phone: string,
  creationTime: RFC3339;
  lastUpdated: RFC3339;
}

function NewCreateProjectRootContact(): CreateProjectRootContact {
  return {
    privacy: null,
    id: 'NEW',
    companyID: '',
    name: '',
    companyName: '',
    address: NewAddress(),
    phone: '',
    creationTime: '',
    lastUpdated: '',
  }
}

function NewCreateProjectFunctionContact(): CreateProjectFunctionContact {
  return {
    uuid: uuid.v4(),
    service: '',
    id: 'NEW',
    companyID: '',
    name: '',
    companyName: '',
    address: NewAddress(),
    phone: '',
    companyMail: '',
    companyPhone: '',
    companyProjects: '',
    companyTask: '',
    mail: '',
    creationTime: '',
    lastUpdated: ''
  }
}

@Component({
  selector: 'pfm-project-editcreate',
  templateUrl: './project-editcreate.component.html',
  styleUrls: ['./project-editcreate.component.scss']
})
export class ProjectEditCreateComponent implements OnInit, PendingChangesGuarded {

  mode: 'CREATE'|'EDIT' = 'CREATE';
  editProjectID: ObjectID|null = null;

  status: 'loading'|'error'|'content' = 'loading';

  metavalues: ProjectEnums|null = null;

  projectName: string = '';
  projectNumber: string = '';
  projectStatus: string = '';
  projectKind: string[] = [];
  confidentialAgreement: boolean = false;
  classification: string|null = null;
  projectAddress: Address = NewAddress();
  procedure: string|null = null;
  categories: string[] = [];
  teamMembers: CreateProjectFunctionContact[] = [];
  awardingBody: CreateProjectRootContact = NewCreateProjectRootContact();
  builder: CreateProjectRootContact = NewCreateProjectRootContact();
  client: CreateProjectRootContact = NewCreateProjectRootContact();
  clientisbuilder: boolean = false;
  serviceProvider: CreateProjectFunctionContact[] = [];
  servicePhases: HOAIServicePhases = NewHOAIServicePhases();
  workScopes: string[] = [];
  utilisation: string[] = [];
  constructionTypes: string[] = [];
  sustainabilityAspects: string[] = [];
  milestones_projectStart: Date|null = null;
  milestones_endLPH3: Date|null = null;
  milestones_buildingStart: Date|null = null;
  milestones_projectEnd: Date|null = null;
  specialFeatures: string = '';
  windLoadZone: string|null = null;
  snowLoad: string|null = null;
  earthquakeZone: string|null = null;
  payload: string|null = null;
  buildingSpan: number|null = null;
  buildVolume: number|null = null;
  floorSpace: number|null = null;
  buildingHeight: number|null = null;
  masses: number|null = null;
  buildingClass: string|null = null;
  BGF: number|null = null;
  NUF: number|null = null;
  BRI: number|null = null;
  NRF: number|null = null;
  NRI: number|null = null;
  BF: number|null = null;
  rulebook: string|null = null;
  feeBand: string|null = null;
  feeLevel: string|null = null;
  feeHOAI: MoneyCent|null = null;
  costType: string|null = null;
  KG100: MoneyCent|null = null;
  KG300: MoneyCent|null = null;
  KG300400: MoneyCent|null = null;
  totalCost: MoneyCent|null = null;
  descriptionShort: string = '';
  description: string = '';
  images: NewImageData[] = [];
  completionDate: RFC3339|null = null;
  completedBy: User|null = null;

  showNewTeamMemberModal: boolean = false;
  showNewServiceProviderModal: boolean = false;
  newContact: CreateProjectFunctionContact = NewCreateProjectFunctionContact();

  showEditTeamMemberModal: boolean = false;
  showEditServiceProviderModal: boolean = false;
  editContact: CreateProjectFunctionContact = NewCreateProjectFunctionContact();

  _serverData: CreateNewProject|null = null;

  validationErrors: Set<ValidationRef> = new Set<ValidationRef>();

  selectedTab: number = 0;

  constructor(private api: APIService,
              private meta: MetaService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private auth: AuthenticationService,
              private notification: NotificationService) {
    //...
  }

  @HostListener('window:beforeunload')
  hasNoPendingChanges = (): boolean => {
    return JSON.stringify(this.getProjectData()) === JSON.stringify(this._serverData);
  }

  async ngOnInit() {
    try {
      if (this.activatedRoute.snapshot.data['mode'] === 'CREATE') {
        this.editProjectID = null;
        this.mode = this.activatedRoute.snapshot.data['mode'];
        await this.fetchData(this.editProjectID);
      } else if (this.activatedRoute.snapshot.data['mode'] === 'EDIT') {
        this.editProjectID = this.activatedRoute.snapshot.params['projid'];
        this.mode = this.activatedRoute.snapshot.data['mode'];
        await this.fetchData(this.editProjectID);
      } else {
        this.status = 'error';
        this.notification.error('Fehler', 'Komponente ist in einem invaliden Zustand');
      }
    } catch (err) {
      showAPIError(this.notification, 'Daten konnte nicht geladen werden', err);
      this.status = 'error';
    }
  }

  async fetchData(projid: ObjectID|null) {
    try {
      this.status = 'loading';

      this.metavalues = await this.meta.getProjectEnums();

      if (projid !== null) {
        const project = await this.api.getProject(projid);
        await this.setAllValues(project);
      } else {
        this._serverData = this.getProjectData();
      }

      this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      this.status = 'error';
    }
  }

  async setAllValues(project: Project) {
    this.classification           = project.classification;
    this.projectName              = project.projectName;
    this.projectNumber            = project.projectNumber;
    this.projectStatus            = project.projectStatus;
    this.projectKind              = project.projectKind;
    this.confidentialAgreement    = project.confidentialAgreement;
    this.projectAddress           = project.projectAddress;
    this.procedure                = project.procedure;
    this.categories               = project.categories;
    this.teamMembers              = await Promise.all(project.teamMembers.map(p => this.apiContactToFunc(p)));
    this.awardingBody             = await this.apiContactToRoot(project.awardingBody);
    this.builder                  = await this.apiContactToRoot(project.builder);
    this.client                   = await this.apiContactToRoot(project.client);
    this.clientisbuilder          = project.client !== null && project.builder?.contactID === project.client?.contactID;
    this.serviceProvider          = await Promise.all(project.serviceProvider.map(p => this.apiContactToFunc(p)));
    this.servicePhases            = project.servicePhases;
    this.workScopes               = project.workScopes;
    this.utilisation              = project.utilisation;
    this.constructionTypes        = project.constructionTypes;
    this.sustainabilityAspects    = project.sustainabilityAspects;
    this.milestones_projectStart  = rfc3339ToNullDate(project.milestones.projectStart);
    this.milestones_endLPH3       = rfc3339ToNullDate(project.milestones.endLPH3);
    this.milestones_buildingStart = rfc3339ToNullDate(project.milestones.buildingStart);
    this.milestones_projectEnd    = rfc3339ToNullDate(project.milestones.projectEnd);
    this.specialFeatures          = project.specialFeatures;
    this.windLoadZone             = project.windLoadZone;
    this.snowLoad                 = project.snowLoad;
    this.earthquakeZone           = project.earthquakeZone;
    this.payload                  = project.payload;
    this.buildingSpan             = project.buildingSpan;
    this.buildVolume              = project.buildVolume;
    this.floorSpace               = project.floorSpace;
    this.buildingHeight           = project.buildingHeight;
    this.masses                   = project.masses;
    this.buildingClass            = project.buildingClass;
    this.BGF                      = project.BGF;
    this.NUF                      = project.NUF;
    this.BRI                      = project.BRI;
    this.NRF                      = project.NRF;
    this.NRI                      = project.NRI;
    this.BF                       = project.BF;
    this.rulebook                 = project.rulebook;
    this.feeBand                  = project.feeBand;
    this.feeLevel                 = project.feeLevel;
    this.feeHOAI                  = project.feeHOAI;
    this.costType                 = project.costType;
    this.KG100                    = project.KG100;
    this.KG300                    = project.KG300;
    this.KG300400                 = project.KG300400;
    this.totalCost                = project.totalCost;
    this.descriptionShort         = project.descriptionShort;
    this.description              = project.description;
    this.images                   = await Promise.all(project.images.map(p => this.apiImageToImageData(p)));

    this.completionDate           = project.completion
    this.completedBy              = await this.apiCompletedByToUser(project.completedBy)

    this._serverData = this.getProjectData();
  }

  stringToKeyValue(v: string[]) {
    return v.map(p => ({key: p, value: p}));
  }

  metaToKeyValue(v: EnumMetaValue<string, string>[]) {
    return v.map(p => ({key: p.value, value: p.description}));
  }

  contactsToKeyValue(v: Contact[]): {key: 'NEW'|ObjectID|null, value: string}[] {
    return [{key:'NEW', value:'Neuen Kontakt erstellen'}, ...v.map(p => ({key: p.id, value: displayContact(p) ?? '???'}))];
  }

  resolveFunctionContact(v: CreateProjectFunctionContact): Contact {
    if (v.id === 'NEW') return { ...v, companyID: (v.companyID as ObjectID), id:('NEW' as ObjectID), deleted: null };
    return this.getMetaContact(v.id) ?? { ...v, companyID: (v.companyID as ObjectID), id:('NEW' as ObjectID), deleted: null };
  }

  getMetaContact(id: ObjectID|'NEW'|null): Contact|null {
    if (id === 'NEW') return null;
    return this.metavalues?.contacts.find(p => p.id === id) ?? null;
  }

  addTeamMember() {
    this.teamMembers = [ ...this.teamMembers, this.newContact ]
  }

  remTeamMember(v: CreateProjectFunctionContact) {
    this.teamMembers = this.teamMembers.filter(p => p.uuid != v.uuid || p.id != v.id);
  }

  addServiceProvider() {
    this.serviceProvider = [ ...this.serviceProvider, this.newContact ]
  }

  remServiceProvider(v: CreateProjectFunctionContact) {
    this.serviceProvider = this.serviceProvider.filter(p => p.uuid != v.uuid || p.id != v.id);
  }

  async onUploadStarted(evt: Event) {

    const files = (evt.target as any).files as File[];

    for (const f of files) {

      const b64 = await blobToBase64(f);

      void (async () => {

        const uniqid = uuid.v4();

        try {
          this.images.push({
            uploaded: false,
            uuid: uniqid,
            id: null,
            progress: 0,
            filesize: f.size,
            data: {
              filename: f.name,
              caption: '',
              copyright: '',
              notes: '',
            }
          })

          const data = await this.api.uploadImage(f.name, b64, f.type, {
            uploadProgress: (loaded: number, total: number | undefined) => {
              const progress = (total===undefined) ? 0 : loaded/total;
              this.images = this.images.map(p => (p.uuid !== uniqid) ? p : {...p, progress: progress });
            }
          });

          await preloadImage(`${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID()}/images/${data.id}/thumb/${512}?xx-bearer-token=@${this.auth.getToken()}`);

          this.images = this.images.map(p => (p.uuid !== uniqid) ? p : {
            ...p,
            progress: null,
            id: data.id,
            uploaded: true,
            filesize: data.filesize,
          });

        } catch (err) {

          this.images = this.images.filter(p => p.uuid !== uniqid);
          showAPIError(this.notification, 'Bild konnten nicht hochgeladen werden', err);

        }
      })();

    }

  }

  deleteImage(uniqid: string) {
    this.images = this.images.filter(p => p.uuid !== uniqid);
  }

  async createOrUpdateProject(navigate = false) {
    if (this.mode === 'CREATE') await this.createProject(navigate);
    if (this.mode === 'EDIT')   await this.updateProject(navigate);
  }

  async createProject(navigate: boolean) {

    this.validationErrors.clear();

    if (this.images.filter(p => !p.uploaded).length > 0) {
      this.validationErrors.add('IMAGES');
      this.notification.error('Fehler',  'Einige Bilder sind noch nicht vollständig hochgeladen');
      return;
    }

    let cnp = this.getProjectData();

    try {
      let data = await this.api.createProject(cnp);

      if (navigate) {
        await this.router.navigate(['/projects/list']);
      } else {
        await this.setAllValues(data);
        this._serverData = this.getProjectData();
        this.notification.success("Projekt gespeichert!",'')
      }

    } catch (err) {
      if (isValidationError(err, 'PROJECT_VALIDATION_FAILED')) {
        this.notification.error('Projekt konnten nicht erstellt werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference as ValidationRef);
      } else {
        showAPIError(this.notification, 'Projekt konnten nicht erstellt werden', err);
      }
    }
  }

  async updateProject(navigate: boolean) {

    this.validationErrors.clear();

    if (this.images.filter(p => !p.uploaded).length > 0) {
      this.validationErrors.add('IMAGES');
      this.notification.error('Fehler',  'Einige Bilder sind noch nicht vollständig hochgeladen');
      return;
    }

    let cnp = this.getProjectData();

    try {
      let data = await this.api.updateProject(this.editProjectID!, cnp);

      if (navigate) {
        await this.router.navigate(['/projects/list']);
      }
      else {
        await this.setAllValues(data);
        this._serverData = this.getProjectData();
        this.notification.success("Projekt gespeichert!",'')
      }

    } catch (err) {
      if (isValidationError(err, 'PROJECT_VALIDATION_FAILED')) {
        this.notification.error('Projekt konnten nicht bearbeitet werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference as ValidationRef);
      } else {
        showAPIError(this.notification, 'Projekt konnten nicht bearbeitet werden', err);
      }
    }
  }

  private getProjectData(): CreateNewProject {
    return {
      projectName           : this.projectName,
      projectNumber         : this.projectNumber,
      projectStatus         : this.projectStatus,
      projectKind           : this.projectKind,
      confidentialAgreement : this.confidentialAgreement,
      classification        : this.classification,
      projectAddress        : deepclone(this.projectAddress),
      procedure             : this.procedure,
      categories            : this.categories,
      teamMembers           : this.teamMembers.map(p => this.fcontactToAPI(p)).filter(p => p !== null).map(p => p as CreateNewProjectFunctionContact),
      awardingBody          : this.rcontactToAPI(this.awardingBody),
      builder               : this.rcontactToAPI(this.builder),
      clientIsBuilder       : this.clientisbuilder,
      client                : this.clientisbuilder ? null : this.rcontactToAPI(this.client),
      serviceProviders      : this.serviceProvider.map(p => this.fcontactToAPI(p)).filter(p => p !== null).map(p => p as CreateNewProjectFunctionContact),
      servicePhases         : this.servicePhases,
      workScopes            : this.workScopes,
      utilisation           : this.utilisation,
      constructionTypes     : this.constructionTypes,
      sustainabilityAspects : this.sustainabilityAspects,
      milestones            : {
        projectStart:   this.ndateToRFC(this.milestones_projectStart),
        buildingStart:  this.ndateToRFC(this.milestones_buildingStart),
        endLPH3:        this.ndateToRFC(this.milestones_endLPH3),
        projectEnd:     this.ndateToRFC(this.milestones_projectEnd),
      },
      specialFeatures       : this.specialFeatures,
      windLoadZone          : this.windLoadZone,
      snowLoad              : this.snowLoad,
      earthquakeZone        : this.earthquakeZone,
      payload               : this.payload,
      buildingSpan          : this.buildingSpan,
      buildVolume           : this.buildVolume,
      floorSpace            : this.floorSpace,
      buildingHeight        : this.buildingHeight,
      masses                : this.masses,
      buildingClass         : this.buildingClass,
      BGF                   : this.BGF,
      NUF                   : this.NUF,
      BRI                   : this.BRI,
      NRF                   : this.NRF,
      NRI                   : this.NRI,
      BF                    : this.BF,
      rulebook              : this.rulebook,
      feeBand               : this.feeBand,
      feeLevel              : this.feeLevel,
      feeHOAI               : this.feeHOAI,
      costType              : this.costType,
      KG100                 : this.KG100,
      KG300                 : this.KG300,
      KG300400              : this.KG300400,
      totalCost             : this.totalCost,
      descriptionShort      : this.descriptionShort,
      description           : this.description,
      images                : this.images.map(this.imgDataToLinkImage),
    };
  }

  async showNewestExpose() {
    try {
      let data = await this.api.listProjectExposes(this.editProjectID!, '@start', null);
      if (data.exposes.length === 0) {
        await this.router.navigate(['/', 'exposes', 'create'], {queryParams: {'project':this.editProjectID!}});
      } else {
        await this.router.navigate(['/', 'exposes', data.exposes[0].id, 'edit']);
      }
    } catch (err) {
      showAPIError(this.notification, 'Expose konnten nicht geladen werden', err);
    }
  }

  ndateToRFC(v: Date|null): RFC3339|null {
    return (v === null) ? null : DateUtils.formatRFC3339(v);
  }

  fcontactToAPI(v: CreateProjectFunctionContact): CreateNewProjectFunctionContact|null {
    if (v.id === null) return null
    if (v.id === 'NEW') {
      if (v.address.zip === '' && v.address.country === '' && v.address.street === '' && v.address.streetNumber === '' && v.address.city === '' && v.companyName === '' && v.name === '' && v.phone === '') return null
      return {
        address: v.address,
        companyName: v.companyName,
        name: v.name,
        service: v.service,
        phone: v.phone,
        contactID: null,
      };
    } else {
      return {
        address: null,
        companyName: null,
        name: null,
        phone: null,
        service: v.service,
        contactID: v.id,
      };
    }

  }

  rcontactToAPI(v: CreateProjectRootContact): CreateNewProjectRootContact|null {
    if (v.id === null) return null
    if (v.id === 'NEW') {
      if (v.address.zip === '' && v.address.country === '' && v.address.street === '' && v.address.streetNumber === '' && v.address.city === '' && v.companyName === '' && v.name === '' && v.phone === '') return null
      return {
        address: v.address,
        companyName: v.companyName,
        name: v.name,
        privacy: v.privacy ?? '',
        phone: v.phone,
        contactID: null,
      };
    } else {
      return {
        address: null,
        companyName: null,
        name: null,
        privacy: v.privacy ?? '',
        phone: v.phone,
        contactID: v.id,
      };
    }

  }

  async apiContactToRoot(v: ProjectRootContact|null): Promise<CreateProjectRootContact> {
    if (v === null) return NewCreateProjectRootContact();

    const contact = await this.api.getContact(v.contactID);

    return {
      "privacy":      v.privacy,
      "id":           v.contactID,
      "companyID":    contact.companyID,
      "name":         contact.name,
      "companyName":  contact.companyName,
      "address":      contact.address,
      "phone":        contact.phone,
      "creationTime": contact.creationTime,
      "lastUpdated":  contact.lastUpdated,
    }
  }

  async apiContactToFunc(v: ProjectFunctionContact|null): Promise<CreateProjectFunctionContact> {
    if (v === null) return NewCreateProjectFunctionContact();

    const contact = await this.api.getContact(v.contactID);

    return {
      "uuid":         uuid.v4(),
      "service":      v.service,
      "id":           v.contactID,
      "companyID":    contact.companyID,
      "name":         contact.name,
      "companyName":  contact.companyName,
      "address":      contact.address,
      "phone":        contact.phone,
      "companyPhone": contact.companyPhone,
      "creationTime": contact.creationTime,
      "mail": contact.mail,
      "companyMail": contact.companyMail,
      "companyProjects": contact.companyProjects,
      "companyTask": contact.companyTask,
      "lastUpdated":  contact.lastUpdated,

    }
  }

  async apiImageToImageData(v: ObjectID): Promise<NewImageData> {
    const blob = await this.api.getImageMetadata<BlobDataProjectImage>(v);

    return  {
      "uploaded":  true,
      "progress":  null,
      "uuid":      uuid.v4(),
      "id":        blob.id,
      "filesize":  blob.filesize,
      "data": {
        "filename":  blob.data.filename,
        "copyright": blob.data.copyright,
        "notes":     blob.data.notes,
        "caption":   blob.data.caption,
      }
    };
  }

  async apiCompletedByToUser(v: ObjectID|null): Promise<User|null> {
    if (v === null) return null;

    return await this.api.getUser(v);

  }

  validate(s: ValidationRef): boolean|undefined {
    return this.validationErrors.has(s) ? true : undefined;
  }

  showProjectTeamModal() {
    this.newContact = NewCreateProjectFunctionContact();
    this.showNewTeamMemberModal = true;
  }

  showServiceProviderModal() {
    this.newContact = NewCreateProjectFunctionContact();
    this.showNewServiceProviderModal = true;
  }

  editTeamMember(v: CreateProjectFunctionContact) {
    this.editContact = deepclone(v);
    this.showEditTeamMemberModal = true;
  }

  editServiceProvider(v: CreateProjectFunctionContact) {
    this.editContact = deepclone(v);
    this.showEditServiceProviderModal = true;
  }

  updateTeamMember() {
    this.teamMembers = this.teamMembers.map(p => (p.uuid === this.editContact.uuid) ? this.editContact : p);
  }

  updateServiceProvider() {
    this.serviceProvider = this.serviceProvider.map(p => (p.uuid === this.editContact.uuid) ? this.editContact : p);
  }

  imgDataToLinkImage(v: NewImageData): LinkProjectImage {
    return {
      id: v.id,
      caption: v.data.caption,
      copyright: v.data.copyright,
      notes: v.data.notes,
    }
  }

  imageUpdated(value: DataBlob<BlobDataProjectImage | BlobDataCompanyImage> | NewImageData | null, i: number) {
    this.images[i] = <NewImageData>value
  }

  async finishProject() {
    if (!this.editProjectID) return;
    try {
      this.status = 'loading';
      const data = await this.api.finishProject(this.editProjectID)
      await this.setAllValues(data);
      this.status = 'content'
    } catch (e) {
      showAPIError(this.notification, 'Projekt konnte nicht abgeschlossen werden', e);
      this.status = 'error';
    }
  }
}
