import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {showAPIError} from "../../utils/api";
import {CursorToken, Project, ProjectEnums} from "../../interfaces";
import {NotificationService} from "../../services/notification.service";
import {ModalService} from "../../services/modal.service";
import {debounce, interval, Subject, Subscription, switchAll} from "rxjs";
import {ComparisonType, ProjectFilter} from "../../interfaces/projectFilter";
import {displayContact} from "../../utils/businesslogic";
import {MetaService} from "../../services/meta.service";
import {DateUtils} from "../../utils/date";
import {buildURL} from "../../utils/url";
import {ProjectTableComponent} from "../../components/project-table/project-table.component";

@Component({
  selector: 'pfm-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss']
})
export class ProjectListComponent implements OnInit, OnDestroy {

  status: 'loading'|'error'|'content' = 'loading';
  loading: boolean = true;

  projectTotalCount:  number|null = null;
  projectInternCount: number|null = null;
  projectExternCount: number|null = null;

  @ViewChild('table') tableComponent!: ProjectTableComponent;

  constructor(private api: APIService,
              private meta: MetaService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  ngOnDestroy() {}

  async initializeFromQueryParams(params: any) {

    await this.fetchCount();

    let metavalues: ProjectEnums;

    try {
      metavalues = await this.meta.getProjectEnums();
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      this.status = 'error';
    }

    let fitersFromQuery: ProjectFilter[] = [];

    let searchText = '';

    for (const entry of Object.entries<string>(params)) {
      if (entry[0] === 'search') {
        searchText = entry[1];
      } else if (entry[0].startsWith('filter.')) {
        const split = entry[0].split('.');
        if (split.length != 4) continue;
        let nmr = split[1];
        let comp = split[2];
        let field = split[3];

        if (!metavalues!.projectFields.find(p => p.identifier === field)) continue;

        let parsedFilter = this.tableComponent.newEmptyFilter();
        parsedFilter.fieldIdentifier =field;
        parsedFilter.comparisonType  =(comp as ComparisonType);
        parsedFilter.value           =`${entry[1]}`;

        parsedFilter = this.tableComponent.updateFilter(parsedFilter, false);

        if (parsedFilter.fieldIdentifier !== '') fitersFromQuery.push(parsedFilter);
      }
    }

    let filters = [...fitersFromQuery, this.tableComponent.newEmptyFilter()];

    let showFilter = filters.filter(p => p.fieldIdentifier !== '').length > 0;

    await this.tableComponent.initFromQuery(searchText, filters, showFilter);
  }

  async fetchCount(){
    try {

      this.projectTotalCount = null;
      const data1 = await this.api.getProjectsCount();
      this.projectTotalCount = data1.count;

      this.projectInternCount = null;
      const data2 = await this.api.getProjectsCount([{ident: 'CLASSIFICATION', comp: 'EQ', val: 'INTERN'}]);
      this.projectInternCount = data2.count;

      this.projectExternCount = null;
      const data3 = await this.api.getProjectsCount([{ident: 'CLASSIFICATION', comp: 'EQ', val: 'EXTERN'}]);
      this.projectExternCount = data3.count;

      this.status = "content"
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  manipulatePath(searchText: string, filter: ProjectFilter[]) {
    this.location.go(this.generateURLWithParams(searchText, filter));
  }

  generateURLWithParams(searchText: string, realFilters: ProjectFilter[]): string {

    let params: {key:string, value: string}[] = []

    if (searchText != '') params.push({key: 'search', value: searchText});

    for (let i = 0; i < realFilters.length; i++) {
      const f = realFilters[i];
      if (f.fieldIdentifier === '') continue;
      params.push({key: `filter.${i}.${f.comparisonType}.${f.fieldIdentifier}`, value: f.value});
    }

    return buildURL(this.router.url, ['projects', 'list'], params);
  }

}
