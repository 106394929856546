import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {environment} from "../../../environments/environment";
import {APIService} from "../../services/api.service";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {Company, ObjectID, TemplateIdent} from "../../interfaces";
import {Dimensions, ImageCropperComponent} from "ngx-image-cropper";
import {TemplateImageConfig} from "../../interfaces/templateConfig";
import {showAPIError} from "../../utils/api";

@Component({
  selector: 'pfm-template-builder',
  templateUrl: './template-builder.component.html',
  styleUrls: ['./template-builder.component.scss']
})
export class TemplateBuilderComponent implements OnInit {

  @Input()  projectid: ObjectID|null = null;
  @Input()  projectids: ObjectID[] = [];
  @Input()  template!: TemplateIdent;
  @Input()  mainPage: boolean = true;

  @Input()  images: {[_p:TemplateIdent]:TemplateImageConfig|null}|undefined = undefined;
  @Output() imagesChange: EventEmitter<{[_p:TemplateIdent]:TemplateImageConfig|null}> = new EventEmitter<{[_p:TemplateIdent]:TemplateImageConfig|null}>();

  @Input()  texts:  {[_p:TemplateIdent]:string|null}|undefined = undefined;
  @Output() textsChange: EventEmitter<{[_p:TemplateIdent]:string|null}> = new EventEmitter<{[_p:TemplateIdent]:string|null}>();

  @Input()  invalid: boolean|undefined = undefined;

  company: Company | null = null;
  imgChooserShow: boolean = false;
  imgChooserRef: string|null = null;

  txtChooserShow: boolean = false;
  txtChooserRef: string|null = null;
  txtChooserValue: string = '';

  cropperImageDim: {width: number, height: number}|null = null;
  cropFile: { url:string, blobid:ObjectID, ident:TemplateIdent, ratio:number, initial:{x1:number,y1:number,x2:number,y2:number}|null }|null = null;

  constructor(private api: APIService,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  async ngOnInit() {
    try {
      const company = await this.api.getCompany();

      if (this.images && !this.images['logo'] && company.logo !== null) {
        this.images['logo'] = {image:company.logo, cropHeight: null, cropWidth: null, cropX: null, cropY: null};
      }

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  onEditImage(ident: string, startValue: TemplateImageConfig|null) {
    this.imgChooserRef  = ident;
    this.imgChooserShow = true;
  }

  onEditText(ident: string, startValue: string) {
    this.txtChooserRef   = ident;
    this.txtChooserShow  = true;
    this.txtChooserValue = startValue;
  }

  imageChosen(ident:TemplateIdent, blobid:ObjectID) {
    if (this.images === undefined) this.images = {};
    const imgs = {...this.images}
    imgs[ident] = {image:blobid, cropHeight: null, cropWidth: null, cropX: null, cropY: null};
    this.images = imgs;
    this.imagesChange.emit(imgs);
  }

  textChosen(ident:TemplateIdent, value:string) {
    if (this.texts === undefined) this.texts = {};
    this.texts[ident] = value;
    this.textsChange.emit(this.texts);
  }

  hideCropperModal(v: boolean) {
    if (!v) this.cropFile = null;
  }

  loadCropperImageFailed() {
    this.notification.error('Ladefehler', 'Das Bild konnt enicht geladen werden');
  }

  loadCropperReady(ident: TemplateIdent, blobid: ObjectID, initRect: {x1: number;y1: number;x2: number;y2: number}|null, cropper: ImageCropperComponent, imgDim: Dimensions) {
    if (initRect != null) {
      cropper.cropper= {
        x1: initRect.x1 * imgDim.width,
        y1: initRect.y1 * imgDim.height,
        x2: initRect.x2 * imgDim.width,
        y2: initRect.y2 * imgDim.height,
      };
    }

    this.cropperImageDim = imgDim;
  }

  onCropImage(ident: TemplateIdent, currValue: TemplateImageConfig, currURL: string, imgw: number, imgh: number) {

    let initRect = null;
    if (currValue.cropX !== null && currValue.cropY !== null && currValue.cropWidth !== null && currValue.cropHeight !== null) {
      initRect = {
        x1:(currValue.cropX),
        y1:(currValue.cropY),
        x2:(currValue.cropX + currValue.cropWidth),
        y2:(currValue.cropY + currValue.cropHeight),
      };
    }

    this.cropFile = {
      ident: ident,
      blobid: currValue.image,
      url: currURL,
      ratio: imgw/imgh,
      initial: initRect,
    };
  }

  onCrop(ident: TemplateIdent, blobid: ObjectID, cropper: ImageCropperComponent) {
    this.cropFile = null;

    if (this.cropperImageDim === null) { console.error('cropperImageDim === null'); return; }

    if (this.images === undefined) this.images = {};

    this.images[ident] = {
      image:      blobid,
      cropX:      (cropper.cropper.x1)                      / this.cropperImageDim.width,
      cropY:      (cropper.cropper.y1)                      / this.cropperImageDim.height,
      cropWidth:  (cropper.cropper.x2 - cropper.cropper.x1) / this.cropperImageDim.width,
      cropHeight: (cropper.cropper.y2 - cropper.cropper.y1) / this.cropperImageDim.height,
    };
    this.imagesChange.emit(this.images);

    this.cropperImageDim = null;
  }
}
