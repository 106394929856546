import {Injectable} from '@angular/core';
import {JWTToken, User} from '../interfaces';
import {APIEventListener, APIMethod, APIRequestMethod, APIServiceRef} from "../utils/apiMethod";
import {HttpClient} from "@angular/common/http";
import {AuthenticationService} from "./authentication.service";
import {SerializedParamCollection} from "../utils/serializedParameter";
import {CreateNewProject, Project} from "../interfaces";
import {ProjectEnums} from "../interfaces";
import {
  BlobData,
  BlobDataCompanyImage,
  BlobDataExposePDF, BlobDataPortfolioPDF,
  BlobDataProjectImage, BlobDataProjectlistPDF,
  DataBlob
} from "../interfaces/datablob";
import {
  Company,
  Contact,
  CreateContact,
  CursorToken,
  ObjectID,
  Portfolio,
  TemplateIdent
} from "../interfaces";
import {Expose} from "../interfaces/expose";
import {CreateExposeTask, Task, TaskParamData, TaskResultData} from '../interfaces/task';
import {Projectlist} from "../interfaces/projectlist";
import {
  ExposeTemplateConfiguration,
  PortfolioTemplateConfiguration,
  ProjectlistTemplateConfiguration,
} from "../interfaces/templateConfig";

type AnyImageBlob = DataBlob<BlobDataCompanyImage|BlobDataProjectImage>;

type Pagination = {nextPageToken: CursorToken, pageSize: number};

type PortfolioPDF   = DataBlob<BlobDataPortfolioPDF>;
type ProjectlistPDF = DataBlob<BlobDataProjectlistPDF>;
type ExposePDF      = DataBlob<BlobDataExposePDF>;
type CompanyImage   = DataBlob<BlobDataCompanyImage>;


@Injectable({ providedIn: 'root' })
export class APIService {

  private static ENDPOINTS = {

    login:                       new APIMethod<{token: JWTToken, user: User}>                (APIRequestMethod.POST,   'login'                                  ),
    register:                    new APIMethod<{token: JWTToken, user: User}>                (APIRequestMethod.POST,   'signup'                                 ),
    tokenRefresh:                new APIMethod<{token: JWTToken, user: User}>                (APIRequestMethod.POST,   'refresh'                                ),

    updateUser:                  new APIMethod<User>                                         (APIRequestMethod.PATCH,  'users/{uid}'                            ),

    listAllCompanyImages:        new APIMethod<{images: CompanyImage[]}>                     (APIRequestMethod.GET,    'company/{cid}/images'                   ),
    listImages:                  new APIMethod<Pagination & {images: AnyImageBlob[]}>        (APIRequestMethod.GET,    'company/{cid}/images'                   ),
    countImages:                 new APIMethod<{count: number}>                              (APIRequestMethod.GET,    'company/{cid}/images/count'             ),
    getImageMetadata:            new APIMethod<DataBlob<any>>                                (APIRequestMethod.GET,    'company/{cid}/images/{bid}/meta'        ),
    updateImageMetadata:         new APIMethod<DataBlob<any>>                                (APIRequestMethod.PATCH,  'company/{cid}/images/{bid}/meta'        ),
    deleteCompanyImage:          new APIMethod<{}>                                           (APIRequestMethod.DELETE, 'company/{cid}/images/{bid}'             ),

    createProject:               new APIMethod<Project>                                      (APIRequestMethod.POST,   'company/{cid}/projects'                 ),
    listProjects:                new APIMethod<Pagination & {projects: Project[]}>           (APIRequestMethod.GET,    'company/{cid}/projects'                 ),
    getProjectEnums:             new APIMethod<ProjectEnums>                                 (APIRequestMethod.GET,    'company/{cid}/projects/enums'           ),
    countProjects:               new APIMethod<{count: number}>                              (APIRequestMethod.GET,    'company/{cid}/projects/count'           ),
    updateProject:               new APIMethod<Project>                                      (APIRequestMethod.PATCH,  'company/{cid}/projects/{pid}'           ),
    deleteProject:               new APIMethod<{}>                                           (APIRequestMethod.DELETE, 'company/{cid}/projects/{pid}'           ),
    finishProject:               new APIMethod<Project>                                      (APIRequestMethod.POST,   'company/{cid}/projects/{pid}/complete'    ),
    getProject:                  new APIMethod<Project>                                      (APIRequestMethod.GET,    'company/{cid}/projects/{pid}'           ),
    listProjectImages:           new APIMethod<{images: CompanyImage[]}>                     (APIRequestMethod.GET,    'company/{cid}/projects/{pid}/images'    ),
    listProjectExposes:          new APIMethod<Pagination & {exposes: Expose[]}>             (APIRequestMethod.GET,    'company/{cid}/projects/{pid}/exposes'   ),

    createContact:               new APIMethod<CreateContact>                                (APIRequestMethod.POST,   'company/{cid}/contacts'                 ),
    listContacts:                new APIMethod<Pagination & {contacts: Contact[]}>           (APIRequestMethod.GET,    'company/{cid}/contacts'                 ),
    updateContact:               new APIMethod<Contact>                                      (APIRequestMethod.PATCH,  'company/{cid}/contacts/{clid}'          ),
    deleteContact:               new APIMethod<{}>                                           (APIRequestMethod.DELETE, 'company/{cid}/contacts/{clid}'          ),
    countContacts:               new APIMethod<{count: number}>                              (APIRequestMethod.GET,    'company/{cid}/contacts/count'           ),

    uploadProjectImage:          new APIMethod<DataBlob<BlobDataProjectImage>>               (APIRequestMethod.POST,   'company/{cid}/images'                   ),

    listPortfolios:              new APIMethod<Pagination & {portfolios: Portfolio[]}>       (APIRequestMethod.GET,    'company/{cid}/portfolios'               ),
    deletePortfolio:             new APIMethod<void>                                         (APIRequestMethod.DELETE, 'company/{cid}/portfolios/{pfid}'        ),
    countPortfolios:             new APIMethod<{count: number}>                              (APIRequestMethod.GET,    'company/{cid}/portfolios/count'         ),
    createPortfolio:             new APIMethod<Portfolio>                                    (APIRequestMethod.POST,   'company/{cid}/portfolios'               ),
    getPortfolio:                new APIMethod<Portfolio>                                    (APIRequestMethod.GET,    'company/{cid}/portfolios/{pfid}'        ),
    updatePortfolio:             new APIMethod<Portfolio>                                    (APIRequestMethod.PATCH,  'company/{cid}/portfolios/{pfid}'        ),
    getPortfolioPDF:             new APIMethod<{exists: boolean; blob: PortfolioPDF|null}>   (APIRequestMethod.GET,    'company/{cid}/portfolios/{pfid}/pdf'    ),

    listProjectlists:            new APIMethod<Pagination & {projectlists: Projectlist[]}>   (APIRequestMethod.GET,    'company/{cid}/projectlists'             ),
    deleteProjectlist:           new APIMethod<void>                                         (APIRequestMethod.DELETE, 'company/{cid}/projectlists/{plid}'      ),
    countProjectlists:           new APIMethod<{count: number}>                              (APIRequestMethod.GET,    'company/{cid}/projectlists/count'       ),
    createProjectlist:           new APIMethod<Projectlist>                                  (APIRequestMethod.POST,   'company/{cid}/projectlists'             ),
    getProjectlist:              new APIMethod<Projectlist>                                  (APIRequestMethod.GET,    'company/{cid}/projectlists/{plid}'      ),
    updateProjectlist:           new APIMethod<Projectlist>                                  (APIRequestMethod.PATCH,  'company/{cid}/projectlists/{plid}'      ),
    getProjectlistPDF:           new APIMethod<{exists: boolean; blob: ProjectlistPDF|null}> (APIRequestMethod.GET,    'company/{cid}/projectlists/{plid}/pdf'  ),

    createExpose:                new APIMethod<Expose>                                       (APIRequestMethod.POST,   'company/{cid}/exposes'                  ),
    listExposes:                 new APIMethod<Pagination & {exposes: Expose[]}>             (APIRequestMethod.GET,    'company/{cid}/exposes'                  ),
    countExposes:                new APIMethod<{count: number}>                              (APIRequestMethod.GET,    'company/{cid}/exposes/count'            ),
    deleteExpose:                new APIMethod<void>                                         (APIRequestMethod.DELETE, 'company/{cid}/exposes/{exid}'           ),
    updateExpose:                new APIMethod<Expose>                                       (APIRequestMethod.PATCH,  'company/{cid}/exposes/{exid}'           ),
    getExpose:                   new APIMethod<Expose>                                       (APIRequestMethod.GET,    'company/{cid}/exposes/{exid}'           ),
    getExposePDF:                new APIMethod<{exists: boolean; blob: ExposePDF|null}>      (APIRequestMethod.GET,    'company/{cid}/exposes/{exid}/pdf'       ),

    getCompanyUser:              new APIMethod<User>                                         (APIRequestMethod.GET,    'company/{cid}/users/{uid}'              ),
    listCompanyUsers:            new APIMethod<Pagination & {users: User[]}>                 (APIRequestMethod.GET,    'company/{cid}/users'                    ),
    createCompanyUser:           new APIMethod<User>                                         (APIRequestMethod.POST,   'company/{cid}/users'                    ),
    deleteCompanyUser:           new APIMethod<User>                                         (APIRequestMethod.DELETE, 'company/{cid}/users/{uid}'              ),
    updateCompanyUser:           new APIMethod<User>                                         (APIRequestMethod.PATCH,  'company/{cid}/users/{uid}'              ),
    countCompanyUsers:           new APIMethod<{count: number}>                              (APIRequestMethod.GET,    'company/{cid}/users/count'              ),

    getCompany:                  new APIMethod<Company>                                      (APIRequestMethod.GET,    'company/{cid}'                          ),
    updateCompany:               new APIMethod<Company>                                      (APIRequestMethod.PATCH,  'company/{cid}'                          ),

    getContact:                  new APIMethod<Contact>                                      (APIRequestMethod.GET,    'company/{cid}/contacts/{ctcid}'         ),

    startCreateExposeTask:      new APIMethod<CreateExposeTask>                              (APIRequestMethod.POST,   'company/{cid}/tasks/create-expose'      ),
    startCreatePortfolioTask:   new APIMethod<CreateExposeTask>                              (APIRequestMethod.POST,   'company/{cid}/tasks/create-portfolio'   ),
    startCreateProjectlistTask: new APIMethod<CreateExposeTask>                              (APIRequestMethod.POST,   'company/{cid}/tasks/create-projectlist' ),

    getTask:                    new APIMethod<Task<TaskParamData, TaskResultData>>           (APIRequestMethod.GET,    'company/{cid}/tasks/{tid}'              ),

  };

  private readonly ref: APIServiceRef;

  constructor(private http: HttpClient,
              private auth: AuthenticationService)
  {
    this.ref = ({http: http, auth: auth, api: this});
  }

  private compid(): string {
    return this.auth.getSelfCompanyID() ?? 'ERR';
  }

  login(ident: string, password: string) {
    return APIService.ENDPOINTS.login.run(this.ref, {}, {'identifier': ident, 'password': password});
  }

  register(username: string, email: string, password: string, company: string, ) {
    return APIService.ENDPOINTS.register.run(this.ref, {}, {"company":company, "password":password, "email":email, "username":username});
  }

  refreshToken() {
    return APIService.ENDPOINTS.tokenRefresh.run(this.ref, {}, {});
  }

  updateUser(userid: ObjectID, username: string|null, email: string|null, oldPassword: string|null, newPassword: string|null) {
    return APIService.ENDPOINTS.updateUser.run(this.ref, {':uid': userid },
      {
        "username":    username    ?? undefined,
        "email":       email       ?? undefined,
        "oldPassword": oldPassword ?? undefined,
        "password":    newPassword ?? undefined,
      });
  }

  updateCompanyUser(userid: ObjectID, username: string|null, email: string|null, newPassword: string|null) {
    return APIService.ENDPOINTS.updateCompanyUser.run(this.ref, {':cid': this.compid(), ':uid': userid },
      {
        "username":    username    ?? undefined,
        "email":       email       ?? undefined,
        "password":    newPassword ?? undefined,
      });
  }

  listProjects(token: CursorToken, limit: number|null, filter: {ident: string, comp: string, val: string}[]|null = null) {
    return APIService.ENDPOINTS.listProjects.run(this.ref,
    {
      ':cid': this.compid(),
      'nextPageToken': token,
      'pageSize': limit ?? undefined,
      'filter': (filter===null) ? undefined : JSON.stringify(filter.map(p => ({i:p.ident,c:p.comp,v:p.val}))),
    }, {});
  }

  deleteProject(id: ObjectID) {
    return APIService.ENDPOINTS.deleteProject.run(this.ref, {':cid':this.compid(), ':pid': id}, {});
  }

  getProjectEnums() {
    return APIService.ENDPOINTS.getProjectEnums.run(this.ref, {':cid':this.compid()}, {});
  }

  getProjectsCount(filter: {ident: string, comp: string, val: string}[]|null = null) {
    return APIService.ENDPOINTS.countProjects.run(this.ref,
      {
        ':cid':this.compid(),
        'filter': (filter===null) ? undefined : JSON.stringify(filter.map(p => ({i:p.ident,c:p.comp,v:p.val}))),
      }, {});
  }

  uploadImage(filename: string, b64Data: string, mime: string, listener: APIEventListener) {
    return APIService.ENDPOINTS.uploadProjectImage.run(this.ref, {':cid':this.compid()}, {'mime':mime, 'data':b64Data, 'filename':filename, 'type': 'PROJECT_IMAGE'}, undefined, listener);
  }

  uploadCompanyImage(filename: string, b64Data: string, mime: string, listener: APIEventListener) {
    return APIService.ENDPOINTS.uploadProjectImage.run(this.ref, {':cid':this.compid()}, {'mime':mime, 'data':b64Data, 'filename':filename, 'type': 'COMPANY_IMAGE'}, undefined, listener);
  }

  createProject(data: CreateNewProject) {
    return APIService.ENDPOINTS.createProject.run(this.ref, {':cid':this.compid()}, {'project': data});
  }

  updateProject(projectid: ObjectID, data: CreateNewProject) {
    return APIService.ENDPOINTS.updateProject.run(this.ref, {':cid':this.compid(), ':pid': projectid}, {'project': data});
  }

  listPortfolios(token: CursorToken, limit: number, filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.listPortfolios.run(this.ref, {':cid':this.compid(), 'nextPageToken': token, 'pageSize': limit}, {}, filter);
  }

  deletePortfolio(id: ObjectID) {
    return APIService.ENDPOINTS.deletePortfolio.run(this.ref, {':cid':this.compid(), ':pfid': id}, {});
  }

  countPortfolios(filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.countPortfolios.run(this.ref, {':cid':this.compid()}, {}, filter);
  }

  listExposes(token: CursorToken, limit: number, filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.listExposes.run(this.ref, {':cid':this.compid(), 'nextPageToken': token, 'pageSize': limit}, {}, filter);
  }

  countExposes(filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.countExposes.run(this.ref, {':cid':this.compid()}, {}, filter);
  }

  listProjectlists(token: CursorToken, limit: number, filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.listProjectlists.run(this.ref, {':cid':this.compid(), 'nextPageToken': token, 'pageSize': limit}, {}, filter);
  }

  countProjectlists(filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.countProjectlists.run(this.ref, {':cid':this.compid()}, {}, filter);
  }

  deleteProjectlist(id: ObjectID) {
    return APIService.ENDPOINTS.deleteProjectlist.run(this.ref, {':cid':this.compid(), ':plid': id}, {});
  }

  getUser(id: ObjectID) {
    return APIService.ENDPOINTS.getCompanyUser.run(this.ref, {':cid':this.compid(), ':uid': id} , {});
  }

  listUsers(token: CursorToken, limit: number, filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.listCompanyUsers.run(this.ref, {':cid':this.compid(), 'nextPageToken': token, 'pageSize': limit}, {}, filter);
  }

  createUser(email: string, password: string, username: string) {
    return APIService.ENDPOINTS.createCompanyUser.run(this.ref, {':cid': this.compid() }, {'email': email, 'password': password, 'username': username})
  }

  deleteUser(userId: ObjectID) {
    return APIService.ENDPOINTS.deleteCompanyUser.run(this.ref, {':cid': this.compid(), ':uid': userId }, {})
  }

  getUserCount(filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.countCompanyUsers.run(this.ref, {':cid': this.compid() }, {}, filter)
  }

  getCompany() {
    return APIService.ENDPOINTS.getCompany.run(this.ref, {':cid': this.compid() }, {})
  }

  updateCompany(companyName?: string, address_name?: string, address_extra?: string, address_street?: string, address_streetNumber?: string, address_zip?: string, address_city?: string, address_country?: string, phoneNumber?: string, faxNumber?: string, mailAddress?: string, website?: string, ) {
    return APIService.ENDPOINTS.updateCompany.run(this.ref, {':cid': this.compid() }, {
      'companyName': companyName,
      'address_name': address_name,
      'address_extra': address_extra,
      'address_street': address_street,
      'address_streetNumber': address_streetNumber,
      'address_zip': address_zip,
      'address_city': address_city,
      'address_country': address_country,
      'phoneNumber': phoneNumber,
      'faxNumber': faxNumber,
      'mailAddress': mailAddress,
      'website': website,
    })
  }

  updateCompanyLogo(logo: string) {
    return APIService.ENDPOINTS.updateCompany.run(this.ref, {':cid': this.compid() }, {'logo': logo})
  }

  listAllCompanyImages() {
    return APIService.ENDPOINTS.listAllCompanyImages.run(this.ref, {':cid': this.compid(), 'type': 'COMPANY_IMAGE' }, {})
  }

  listImages(token: CursorToken, limit: number|null, filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.listImages.run(this.ref, {':cid':this.compid(), 'nextPageToken': token, 'pageSize': limit??undefined}, {}, filter);
  }

  countImages(filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.countImages.run(this.ref, {':cid':this.compid()}, {}, filter);
  }

  listProjectImages(projectid: ObjectID) {
    return APIService.ENDPOINTS.listProjectImages.run(this.ref, {':cid': this.compid(), ':pid': projectid }, {})
  }

  createExpose(name: string, projectID: ObjectID, templKey: TemplateIdent, cfg: ExposeTemplateConfiguration) {
    return APIService.ENDPOINTS.createExpose.run(this.ref, {':cid': this.compid() }, {
      "name": name,
      "projectID": projectID,
      "template": templKey,
      "images": cfg.images,
      "texts": cfg.texts,
    });
  }

  getProject(projectid: ObjectID) {
    return APIService.ENDPOINTS.getProject.run(this.ref, {':cid': this.compid(), ':pid': projectid }, {})
  }

  getContact(contactID: ObjectID) {
    return APIService.ENDPOINTS.getContact.run(this.ref, {':cid': this.compid(), ':ctcid': contactID }, {})
  }

  getImageMetadata<TData extends BlobData>(blobid: ObjectID) {
    return APIService.ENDPOINTS.getImageMetadata.run(this.ref, {':cid': this.compid(), ':bid': blobid }, {}) as Promise<DataBlob<TData>>
  }

  listProjectExposes(projectid: ObjectID, token: CursorToken, limit: number|null) {
    return APIService.ENDPOINTS.listProjectExposes.run(this.ref, {':cid': this.compid(), ':pid': projectid, 'nextPageToken': token, 'pageSize': limit ?? undefined }, {})
  }

  getExpose(exposeid: ObjectID) {
    return APIService.ENDPOINTS.getExpose.run(this.ref, {':cid': this.compid(), ':exid': exposeid }, {})
  }

  updateExpose(exposeid: ObjectID, name: string|null, projectID: ObjectID|null, templKey: string|null, cfg: ExposeTemplateConfiguration|null) {
    return APIService.ENDPOINTS.updateExpose.run(this.ref, {':cid': this.compid(), ':exid': exposeid }, {
      "name":      name        ?? undefined,
      "projectID": projectID   ?? undefined,
      "template":  templKey    ?? undefined,
      "images":    cfg?.images ?? undefined,
      "texts":     cfg?.texts  ?? undefined,
    })
  }

  getExposePDF(exid: ObjectID) {
    return APIService.ENDPOINTS.getExposePDF.run(this.ref, { ':cid': this.compid(), ":exid": exid }, {})
  }

  startCreateExposeTasks(exid: ObjectID) {
    return APIService.ENDPOINTS.startCreateExposeTask.run(this.ref, { ':cid': this.compid() }, { id: exid })
  }

  getPortfolioPDF(exid: ObjectID) {
    return APIService.ENDPOINTS.getPortfolioPDF.run(this.ref, { ':cid': this.compid(), ":pfid": exid }, {})
  }

  startCreatePortfolioTasks(exid: ObjectID) {
    return APIService.ENDPOINTS.startCreatePortfolioTask.run(this.ref, { ':cid': this.compid() }, { id: exid })
  }

  getProjectlistPDF(exid: ObjectID) {
    return APIService.ENDPOINTS.getProjectlistPDF.run(this.ref, { ':cid': this.compid(), ":plid": exid }, {})
  }

  startCreateProjectlistTasks(exid: ObjectID) {
    return APIService.ENDPOINTS.startCreateProjectlistTask.run(this.ref, { ':cid': this.compid() }, { id: exid })
  }

  getTask(tid: ObjectID) {
    return APIService.ENDPOINTS.getTask.run(this.ref, { ':cid': this.compid(), ":tid": tid }, {})
  }
  createContact(data: CreateContact) {
    return APIService.ENDPOINTS.createContact.run(this.ref, {':cid':this.compid()}, data);
  }

  listContacts(token: CursorToken, limit: number|null, filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.listContacts.run(this.ref, {':cid':this.compid(), 'nextPageToken': token, 'pageSize': limit ?? undefined}, {}, filter);
  }

  updateContact(contactid: ObjectID, data: CreateContact) {
    return APIService.ENDPOINTS.updateContact.run(this.ref, {':cid':this.compid(), ':clid': contactid}, data);
  }

  deleteContact(id: ObjectID) {
    return APIService.ENDPOINTS.deleteContact.run(this.ref, {':cid': this.compid(), ':clid': id}, {});
  }

  getContactsCount(filter: SerializedParamCollection) {
    return APIService.ENDPOINTS.countContacts.run(this.ref, {':cid':this.compid()}, {}, filter);
  }

  deleteExpose(id: ObjectID) {
    return APIService.ENDPOINTS.deleteExpose.run(this.ref, {':cid': this.compid(), ':clid': id}, {});
  }

  getProjectlist(projectlistid: ObjectID) {
    return APIService.ENDPOINTS.getProjectlist.run(this.ref, { ':cid': this.compid(), ":plid": projectlistid }, {})
  }

  createProjectlist(name: string, proj: ObjectID[], templ: TemplateIdent, cfg: ProjectlistTemplateConfiguration) {
    return APIService.ENDPOINTS.createProjectlist.run(this.ref, {':cid': this.compid() }, {
      "name": name,
      "projectIDs": proj,
      "template": templ,
      "mainImages": cfg.mainImages,
      "mainTexts": cfg.mainTexts,
    });
  }

  updateProjectlist(plid: ObjectID, name: string, proj: ObjectID[], templ: TemplateIdent, cfg: ProjectlistTemplateConfiguration) {
    return APIService.ENDPOINTS.updateProjectlist.run(this.ref, {':cid': this.compid(), ':plid': plid, }, {
      "name": name,
      "projectIDs": proj,
      "template": templ,
      "mainImages": cfg.mainImages,
      "mainTexts": cfg.mainTexts,
    });
  }

  getPortfolio(pfid: ObjectID) {
    return APIService.ENDPOINTS.getPortfolio.run(this.ref, { ':cid': this.compid(), ":pfid": pfid }, {})
  }

  createPortfolio(name: string, proj: ObjectID[], templ: TemplateIdent, cfg: PortfolioTemplateConfiguration) {
    return APIService.ENDPOINTS.createPortfolio.run(this.ref, {':cid': this.compid() }, {
      "name": name,
      "projectIDs": proj,
      "template": templ,
      "mainImages": cfg.mainImages,
      "mainTexts": cfg.mainTexts,
      "projectImages": cfg.projectImages,
      "projectTexts": cfg.projectTexts,
    });
  }

  updatePortfolio(pfid: ObjectID, name: string, proj: ObjectID[], templ: TemplateIdent, cfg: PortfolioTemplateConfiguration) {
    return APIService.ENDPOINTS.updatePortfolio.run(this.ref, {':cid': this.compid(), ':pfid': pfid, }, {
      "name": name,
      "projectIDs": proj,
      "template": templ,
      "mainImages": cfg.mainImages,
      "mainTexts": cfg.mainTexts,
      "projectImages": cfg.projectImages,
      "projectTexts": cfg.projectTexts,
    });
  }

  deleteCompanyImage(imgid: ObjectID) {
    return APIService.ENDPOINTS.deleteCompanyImage.run(this.ref, { ':cid': this.compid(), ":bid": imgid }, {});
  }

  updateImageBlobMeta(imgid: ObjectID, copyright: string, notes: string, caption: string) {
    return APIService.ENDPOINTS.updateImageMetadata.run(this.ref, { ':cid': this.compid(), ":bid": imgid }, {'copyright':copyright, 'notes':notes, 'caption':caption});
  }

  finishProject(projectid: ObjectID) {
    return APIService.ENDPOINTS.finishProject.run(this.ref, { ':cid': this.compid(), ":pid": projectid }, {} )
  }
}
