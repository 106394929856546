import { Component } from '@angular/core';

@Component({
  selector: 'pfm-theme-changer-btn',
  templateUrl: './theme-changer-btn.component.html',
  styleUrls: ['./theme-changer-btn.component.scss']
})
export class ThemeChangerBtnComponent {

  changeTheme() {
    let theme = document.body.getAttribute('data-theme');
    if (theme === 'dark') {
      document.body.setAttribute('data-theme', 'light');
    } else {
      document.body.setAttribute('data-theme', 'dark');
    }
  }

}
