import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'pfm-async-display',
  templateUrl: './async-display.component.html',
  styleUrls: ['./async-display.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsyncDisplayComponent<TInData, TQueryData> implements OnChanges {

  @Input() intermediateValue: string = '';
  @Input() data: TInData|null = null;
  @Input() query: ((v: TInData) => Promise<TQueryData>)|((v: TInData) => TQueryData)|null = null;
  @Input() transform: ((v: TQueryData) => string)|'raw'|(keyof TQueryData) = 'raw';

  realValue: string = '';

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.loadData();
  }

  loadData() {

    //TODO this can be improved:
    //   - global cache to prevent same re-queries (clear cache on navigation change?)
    //   - option to have pre-defined queries (resolve-User, resolve-Project, ...)

    this.realValue = this.intermediateValue;
    this.cdr.detectChanges();

    if (this.data === null) return;

    if (this.query === null) {
      this.realValue = `${this.data}`;
      this.cdr.detectChanges();
      return;
    }

    const qdata = this.query(this.data);

    if (qdata instanceof Promise) {
      qdata.then(v => {
        this.realValue = this.doTransform(v as any as TQueryData);
        this.cdr.detectChanges();
      });
    } else {
      this.realValue = this.doTransform(qdata as TQueryData);
      this.cdr.detectChanges();
    }
  }

  doTransform(v: TQueryData): string {
    if (this.transform === 'raw') {
      return `${v}`
    } else if (typeof this.transform === 'string') {
      return `${v[this.transform]}`
    } else if (typeof this.transform === 'function') {
      return this.transform(v);
    } else {
      return 'N/A';
    }
  }


}
