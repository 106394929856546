import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {isNgDiff, sleep} from "../../utils/angular";
import {Expose} from "../../interfaces/expose";
import * as uuid from "uuid";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {NotificationService} from "../../services/notification.service";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "../../services/authentication.service";
import {CreateExposeTask} from "../../interfaces/task";
import {ObjectID} from "../../interfaces";

@Component({
  selector: 'pfm-download-expose-modal',
  templateUrl: './download-expose-modal.component.html',
  styleUrls: ['./download-expose-modal.component.scss']
})
export class DownloadExposeModalComponent implements OnChanges {

  @Input() expose: Expose|null = null;
  @Output() exposeChange: EventEmitter<Expose|null> = new EventEmitter<Expose|null>();

  blobid: ObjectID|null = null;

  state: 'LOADING'|'CREATING'|'ERROR'|'DOWNLOAD' = 'LOADING'

  internalAsyncID: string = '';

  constructor(private api: APIService,
              private notification: NotificationService,
              private auth: AuthenticationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'expose')) {
      if (this.expose !== null) {
        this.loadPDF().then(() => {})
      } else {
        this.clear();
      }
    }
  }

  clear() {
    this.state = 'LOADING';
    this.internalAsyncID = '';
  }

  async loadPDF() {
    if (this.expose === null) return;

    const iaid = uuid.v4();

    try {
      this.state = 'LOADING';
      this.internalAsyncID = iaid;

      const data = (await Promise.all([this.api.getExposePDF(this.expose.id), sleep(850)]))[0];

      if (this.internalAsyncID != iaid) return; // modal closed

      if (data.exists) {
        this.blobid = data.blob!.id;
        this.state = 'DOWNLOAD';
        return;
      }

      const baseTask = await this.api.startCreateExposeTasks(this.expose.id);

      if (this.internalAsyncID != iaid) return; // modal closed

      this.state = 'CREATING';

      while (true) {

        const task = (await this.api.getTask(baseTask.id)) as CreateExposeTask;

        if (this.internalAsyncID != iaid) return; // modal closed

        if (task.status === 'RUNNING') {
          await sleep(100);
          if (this.internalAsyncID != iaid) return; // modal closed
          continue;
        }

        if (task.status === 'FINISHED') {
          this.blobid = task.result!.blobID;
          this.state = 'DOWNLOAD';
          return;
        }

        if (task.status === 'ABORTED') {
          this.state = 'ERROR';
          this.notification.error('Fehler', 'Task wurde abgebrochen');
          return;
        }

        if (task.status === 'FAILED') {
          this.state = 'ERROR';
          this.notification.error('Fehler', task.error);
          return;
        }

        task.status;

        this.state = 'ERROR';
        this.notification.error('Fehler', "Unbekannter API Rückgabewert");
        return;

      }

    } catch (err) {

      this.state = 'ERROR';
      showAPIError(this.notification, 'PDF konnten nicht geladen werden', err);
      return;

    }
  }

  onModalShowChanged(show: boolean) {
    if (!show) {
      this.exposeChange.emit(this.expose = null);
    }
  }

  downloadURL() {
    return `${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID() ?? 'ERR'}/blobs/${this.blobid ?? 'NULL'}/data?xx-bearer-token=@${this.auth.getToken()}`
  }
}
