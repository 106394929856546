import {Component, ContentChildren, QueryList} from '@angular/core';
import {MenubarButtonComponent} from "./menubar-button.component";

@Component({
  selector: 'pfm-menubar',
  templateUrl: './menubar.component.html',
  styleUrls: ['./menubar.component.scss']
})
export class MenubarComponent {

  @ContentChildren(MenubarButtonComponent) buttons!: QueryList<MenubarButtonComponent>;

}
