
<div class="container" [class.anysuggestions]="suggest(innerValue).length > 0" [attr.aria-invalid]="invalid">

  <div class="input-div"
       [class.anysuggestions]="suggest(innerValue).length > 0"
       [class.readonly]="readonly"
       [class.disabled]="readonly"
       [attr.aria-invalid]="invalid">

    <input #comp
           type="text"
           [ngModel]="innerValue"
           (ngModelChange)="innerValue = $event;"
           [disabled]="disabled"
           [readonly]="readonly"
           [placeholder]="placeholder ?? ''"
           [attr.aria-invalid]="invalid">

  </div>


  <div class="option-box">
    <div class="option-list">
      <div class="seperator"></div>
      <div class="option" *ngFor="let suggestion of suggest(innerValue)" (mousedown)="select(suggestion)">{{ suggestion }}</div>
    </div>

</div>

</div>
