import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as uuid from "uuid";

@Component({
  selector: 'pfm-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent {

  uniq = uuid.v4()

  @Input() value?: boolean = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() indeterminate: boolean = false;

  @Input() text: string = '';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() invalid: boolean|undefined = undefined;
}
