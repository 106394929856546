import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'pfm-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent {

  @Input() value: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() onEnterUp: EventEmitter<any> = new EventEmitter<any>();

  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() invalid: boolean|undefined = undefined;
  @Input() placeholder: string = '';
  @Input() rows: number|undefined = undefined;
  @Input() resize: 'both'|'none'|'vertical'|'horizontal' = 'both';
  @Input() fullInnerHeight: boolean = false;

}
