import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {InputAutogrowComponent} from "../input-autogrow/input-autogrow.component";

@Component({
  selector: 'pfm-multiselect',
  templateUrl: './multiselect.component.html',
  styleUrls: ['./multiselect.component.scss']
})
export class MultiselectComponent {

  @Input() preventPlaceholderSelect: boolean = true;
  @Input() placeholder: string|null = null;
  @Input() values: {key: string, value: string}[] = [];
  @Input() autoSubmit: boolean = false;
  @Input() autoTrim: boolean = true;

  @Input() value: string[] = [];
  @Output() valueChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  intermedValue: string = '';

  @Input() readonly: boolean = false;
  @Input() disabled: boolean = false;
  @Input() invalid: boolean|undefined = undefined;

  @ViewChild('comp') component!: InputAutogrowComponent;

  focus() {
    this.component.focus();
  }

  onEnter(evt: Event) {
    this.applyIntermedValue();
  }

  displ(v: string): string {
    for (const kv of this.values) {
      if (kv.key === v) return kv.value;
    }
    return v;
  }

  removeValue(v: string) {
    this.value = this.value.filter(p => p != v);
    this.valueChange.emit(this.value);
  }

  suggest(v: string) {
    if (v === '') return this.values;

    return this.values.filter(p => {
      return (!this.value.includes(p.key)) && (p.value.toLowerCase().includes(v.toLowerCase()) || p.key.toLowerCase().includes(v.toLowerCase()));
    });
  }

  addValue(key: string, value: string ) {
    if (!this.value.includes(key)) this.value = [...this.value, key];
    this.intermedValue = '';
    this.valueChange.emit(this.value);
  }

  onDivClicked(evt: MouseEvent, bg: HTMLDivElement) {

    if (evt.target !== bg) return;

    this.component.focus();
  }

  onFocusLost() {
    if (this.autoSubmit && (this.autoTrim ? this.intermedValue.trim() : this.intermedValue) !== '') this.applyIntermedValue();
  }

  applyIntermedValue() {
    if ((this.autoTrim ? this.intermedValue.trim() : this.intermedValue) !== '') {
      let newval = (this.autoTrim ? this.intermedValue.trim() : this.intermedValue);

      for (const kv of this.values) {
        if (kv.key.toLowerCase()   === newval.toLowerCase()) {newval = kv.key; break;}
        if (kv.value.toLowerCase() === newval.toLowerCase()) {newval = kv.key; break;}
      }

      if (!this.value.includes(newval)) this.value = [...this.value, newval];
      this.intermedValue = '';
      this.valueChange.emit(this.value)
    }
  }
}
