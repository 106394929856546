<frame-default [status]="status" errorMessage="Beim Laden der Projekte ist ein Fehler aufgetreten">
  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;"
                     [crumbs]="
                     [
                      {text:'Seiten'},
                      {text:'Firma', link: '/company/settings'},
                      {text:'Bilder', link: '/images', active: true},
                     ]" ></pfm-breadcrumbs>


    <div class="statistics">
      <pfm-statistics-text icon="file-pen" title="Firmenbilder"  [loading]="imageCompanyCount === null" [value]="imageCompanyCount ?? '...'"></pfm-statistics-text>
      <pfm-statistics-text icon="file-pen" title="Projektbilder" [loading]="imageProjectCount === null" [value]="imageProjectCount ?? '...'" style="margin-left: 2rem"></pfm-statistics-text>
      <div class="spacer"></div>
      <pfm-button icon="plus" *ngIf="selectedTab === 'COMPANY_IMAGES'" (btnClick)="addUploadTile()">Bild hinzufügen</pfm-button>
    </div>

    <pfm-input class="searchfield"
               [preIcon]="['fas', 'magnifying-glass']"
               placeholder="Tabelle durchsuchen"
               [value]="(filters['search'].active ?? '') | caststring"
               [autoTrim]="false"
               (valueChange)="filters['search'].active = $event; searchObservable?.next($event)"></pfm-input>

    <pfm-card [header]="tabToHeader(selectedTab)" [headerExtra]="imagesCardExtra">

      <ng-template #imagesCardExtra>
        <div style="flex-grow: 1; align-self: center; display: flex; justify-content: flex-end">
          <pfm-toggle-button style="align-self: end" [selectedIndex]="tabToIndex(selectedTab)" (selectedIndexChange)="changeTabByIndex($event)">
            <pfm-toggle-value>{{tabToHeader('COMPANY_IMAGES')}}</pfm-toggle-value>
            <pfm-toggle-value>{{tabToHeader('PROJECT_IMAGES')}}</pfm-toggle-value>
          </pfm-toggle-button>
        </div>
      </ng-template>

      <div *ngIf="selectedTab === 'COMPANY_IMAGES'" class="card-content">
        <div *ngIf="!tableLoading" class="image-list">

          <pfm-imageblob-display *ngFor="let upkey of uploadPlaceholder;"
                                 [image]="null"
                                 mode="upload"
                                 [clickable]="false"
                                 [actions]="[]"
                                 (uploadStarted)="startUpload(upkey, $event)" ></pfm-imageblob-display>

          <pfm-imageblob-display *ngFor="let img of uploadingImages;"
                                 [image]="img"
                                 mode="uploading"
                                 [clickable]="false"
                                 [actions]="['abort']"
                                 (abortUpload)="abortUpload(img.uuid)"></pfm-imageblob-display>

          <pfm-imageblob-display *ngFor="let img of imagesCompany"
                                 [image]="img"
                                 mode="edit"
                                 [clickable]="false"
                                 [actions]="['delete', 'download', 'save']"
                                 [isSaving]="imageSaveLoading.has(img.id)"
                                 (saveImage)="saveCompanyImage(img)"
                                 (deleteImage)="deleteCompanyImage(img)"></pfm-imageblob-display>

        </div>

        <div *ngIf="tableLoading" class="loading-div">
          <fa-icon icon="loader" animation="spin"></fa-icon>
        </div>

        <pfm-button *ngIf="!tableLoading && imageCompanyNextToken !== '@end'" (btnClick)="fetchCompanyImagesData(false, false)">Mehr</pfm-button>
      </div>

      <div *ngIf="selectedTab === 'PROJECT_IMAGES'" class="card-content">
        <div *ngIf="!tableLoading" class="image-list">

          <pfm-imageblob-display *ngFor="let img of imagesProject"
                                 [image]="img"
                                 mode="edit"
                                 [clickable]="false"
                                 [actions]="['download', 'save']"
                                 [isSaving]="imageSaveLoading.has(img.id)"
                                 (saveImage)="saveProjectImage(img)"></pfm-imageblob-display>

        </div>

        <div *ngIf="tableLoading" class="loading-div">
          <fa-icon icon="loader" animation="spin"></fa-icon>
        </div>

        <pfm-button *ngIf="!tableLoading && imageProjectNextToken !== '@end'" class="pagination-btn" type="sub" (btnClick)="fetchProjectImagesData(false, false)">Mehr</pfm-button>
      </div>

    </pfm-card>

  </main>
</frame-default>

