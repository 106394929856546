
<div class="container">
  <fa-icon *ngIf="icon" [icon]="icon" style="color: #7D8398" size="lg"></fa-icon>
  <div class="content">
    <span class="title">{{title}}</span>
    <span class="value" [class.loading]="loading">
      <ng-container *ngIf="!loading" >{{value}}</ng-container>
      <fa-icon *ngIf="loading" icon="loader" animation="spin"></fa-icon>
    </span>
  </div>
</div>
