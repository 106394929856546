import {ValidationRef} from "./validation";
import {HttpErrorResponse} from "@angular/common/http";

export interface APIError {
  errorcode: ApiErrorCode;
  message: string;

  __error?: string;
  __trace?: string[];

  extra: any;
}

export interface ValidationError extends APIError {
  errorcode: ApiErrorCode;
  message: string;

  __error?: string;
  __trace?: string[];

  extra: { reference: ValidationRef, message: string };
}

export interface TypedHttpErrorResponse<T> extends HttpErrorResponse {
  error: T;
}

export function isAPIError(v: any): v is TypedHttpErrorResponse<APIError> {
  if (!(v instanceof HttpErrorResponse)) return false;
  return typeof v === 'object' && v.error !== undefined && v.error !== null && v.error.errorcode !== undefined && v.error.message !== undefined;
}

export function isValidationError(v: any, errcode: ApiErrorCode): v is TypedHttpErrorResponse<ValidationError> {
  if (!isAPIError(v)) return false;

  if (v.error.errorcode !== errcode) return false;
  if (v.error.extra?.reference === undefined) return false;
  if (v.error.extra?.message === undefined) return false;

  return true;
}

export type ApiErrorCode =
  'NOT_IMPLEMENTED' | 'INTERNAL_ERROR' |  'PANIC' |  'DATABASE_ERROR' |  'MAILER_ERROR' | 'FILESYSTEM_ERROR' | 'DATA_CONSISTENCY_ERROR' |
  'BINDFAIL_URI' |  "BINDFAIL_QUERY" |  "BINDFAIL_JSON" |  "BINDFAIL_FORMDATA" |
  "INVALID_REQUEST_PARAMETER" |  "SELF_DELETE" |  "INVALID_REF_KEY" |  "ENTITY_NOT_FOUND" |  "INVALID_CURSOR_TOKEN" |
  "USERNAME_COLLISION" |  "EMAIL_COLLISION" |  "MISSING_PERMISSIONS" |
  "WRONG_OLD_PASSWORD" |  "WRONG_SECRET" |
  "MIME_ERROR" |
  "PROJECT_VALIDATION_FAILED" |  "PORTFOLIO_VALIDATION_FAILED" |  "PROJECTLIST_VALIDATION_FAILED" |  "CONTACT_VALIDATION_FAILED" |  "EXPOSE_VALIDATION_FAILED" |
  "CONTACT_IN_USE" |  "PROJECT_IN_USE" | "IMAGE_IN_USE" |
  "UNAUTHORIZED" |  "AUTH_FAILED";
