import {Component, Input} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'pfm-statistics-text',
  templateUrl: 'statistics-text.component.html',
  styleUrls: ['./statistics-text.component.scss']
})
export class StatisticsTextComponent {
  @Input() icon: IconProp | null = null;
  @Input() value: string|number = '';
  @Input() title: string = '';
  @Input() loading: boolean = false;
}
