<empty-frame>

  <main>

    <pfm-breadcrumbs style="align-self: start; margin-bottom: 2rem"
                     back="/"
                     [crumbs]="[
                       {text:'Seiten'},
                       {text:'Root', link:'/'},
                       {text:'Inaktiv', link:'/demo'},
                       {text:'Demo (this)', link:'/demo', active:true},
                     ]"></pfm-breadcrumbs>

    <pfm-tabs [selectedIndex]="tabidx" (selectedIndexChange)="tabidx = $event; setFragment(tabidx)">

      <pfm-tab tabIndex="1" tabTitle="Buttons">
        <div style="display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; grid-column-gap: 1rem">

          <div class="tab-content">

            <pfm-button [loading]="loginLoading"  icon="right-to-bracket" style="align-self: start"></pfm-button>
            <pfm-button [loading]="true" style="align-self: start"></pfm-button>

            <pfm-button [loading]="loginLoading"  icon="right-to-bracket" style="align-self: start">Login</pfm-button>
            <pfm-button [loading]="true" style="align-self: start">Loading</pfm-button>

          </div>

          <div class="tab-content">

            <pfm-button icon="plus"      type="default">type=default</pfm-button>
            <pfm-button                  type="default">type=default</pfm-button>
            <pfm-button [loading]="true" type="default">type=default + Loading</pfm-button>

            <pfm-button icon="plus"      type="sub">type=sub</pfm-button>
            <pfm-button                  type="sub">type=sub</pfm-button>
            <pfm-button [loading]="true" type="sub">type=sub + Loading</pfm-button>

            <pfm-button icon="plus"      type="primary">type=primary</pfm-button>
            <pfm-button                  type="primary">type=primary</pfm-button>
            <pfm-button  [loading]="true" type="primary">type=primary + loading</pfm-button>

            <pfm-button icon="plus"      type="sub-trans">type=sub-trans</pfm-button>
            <pfm-button                   type="sub-trans">type=sub-trans</pfm-button>
            <pfm-button  [loading]="true" type="sub-trans">type=sub-trans + loading</pfm-button>

          </div>

          <div class="tab-content">

            <pfm-button [disabled]="true" icon="plus"      type="default">[disabled] type=default + icon</pfm-button>
            <pfm-button [disabled]="true"                  type="default">[disabled] type=default</pfm-button>
            <pfm-button [disabled]="true" [loading]="true" type="default">[disabled] type=default + Loading</pfm-button>

            <pfm-button [disabled]="true" icon="plus"      type="sub">[disabled] type=sub + icon</pfm-button>
            <pfm-button [disabled]="true"                  type="sub">[disabled] type=sub</pfm-button>
            <pfm-button [disabled]="true" [loading]="true" type="sub">[disabled] type=sub + Loading</pfm-button>

            <pfm-button [disabled]="true" icon="plus"      type="primary">[disabled] type=primary + icon</pfm-button>
            <pfm-button [disabled]="true"                   type="primary">[disabled] type=primary</pfm-button>
            <pfm-button [disabled]="true"  [loading]="true" type="primary">[disabled] type=primary + loading</pfm-button>

            <pfm-button [disabled]="true" icon="plus"      type="sub-trans">[disabled] type=sub-trans + icon</pfm-button>
            <pfm-button [disabled]="true"                   type="sub-trans">[disabled] type=sub-trans</pfm-button>
            <pfm-button [disabled]="true"  [loading]="true" type="sub-trans">[disabled] type=sub-trans + loading</pfm-button>

          </div>

          <div class="tab-content">

            <pfm-toggle-button style="align-self: start">
              <pfm-toggle-value>First</pfm-toggle-value>
              <pfm-toggle-value>Second</pfm-toggle-value>
            </pfm-toggle-button>

            <pfm-toggle-button style="align-self: start">
              <pfm-toggle-value>First</pfm-toggle-value>
              <pfm-toggle-value>Second</pfm-toggle-value>
              <pfm-toggle-value>Thrird</pfm-toggle-value>
            </pfm-toggle-button>

            <pfm-toggle-button>
              <pfm-toggle-value>First</pfm-toggle-value>
              <pfm-toggle-value>Second</pfm-toggle-value>
            </pfm-toggle-button>

            <pfm-toggle-button>
              <pfm-toggle-value>First</pfm-toggle-value>
              <pfm-toggle-value>Second</pfm-toggle-value>
              <pfm-toggle-value>Thrird</pfm-toggle-value>
            </pfm-toggle-button>

          </div>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="2.1" tabTitle="Text-Inputs">
        <div class="tab-content">

          <pfm-input                [(value)]="identifier"                  placeholder="Textfield" (onEnterUp)="this.inputPassword.focus()"></pfm-input>
          <pfm-input #inputPassword [(value)]="password"    type="password" placeholder="Passwort"></pfm-input>

          <pfm-input [invalid]="true"  value="invalid"></pfm-input>
          <pfm-input [invalid]="false" value="valid"></pfm-input>
          <pfm-input [disabled]="true" value="disabled"></pfm-input>
          <pfm-input [readonly]="true" value="readonly"></pfm-input>

          <hr>

          <pfm-numberinput placeholder="Numbers only" unit="m"  ></pfm-numberinput>

          <pfm-numberinput placeholder="" unit="Euro"  ></pfm-numberinput>

          <pfm-numberinput placeholder="2 prec and min" [precision]="2" [min]="0" ></pfm-numberinput>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="2.2" tabTitle="Bool Inputs">
        <div class="tab-content">


          <pfm-checkbox [(value)]="ephemeral1" text="A Checkbox"></pfm-checkbox>

          <pfm-checkbox [indeterminate]="true" text="Indeterm"></pfm-checkbox>

          <pfm-checkbox [(value)]="ephemeral1" text="Same Checkbox"></pfm-checkbox>

          <pfm-checkbox [value]="true" text="Checkbox (true)"></pfm-checkbox>

          <pfm-checkbox [value]="false" text="Checkbox (false)"></pfm-checkbox>

          <pfm-checkbox [value]="false" [disabled]="true" text="Checkbox (false | disabled)"></pfm-checkbox>

          <pfm-checkbox [value]="true" [disabled]="true" text="Checkbox (true | disabled)"></pfm-checkbox>

          <pfm-checkbox [value]="false" [invalid]="true" text="Checkbox (false | invalid)"></pfm-checkbox>

          <pfm-checkbox [value]="true" [invalid]="true" text="Checkbox (true | invalid)"></pfm-checkbox>

          <pfm-checkbox [value]="false" [invalid]="false" text="Checkbox (false | valid)"></pfm-checkbox>

          <pfm-checkbox [value]="true" [invalid]="false" text="Checkbox (true | valid)"></pfm-checkbox>

          <pfm-switch [(value)]="ephemeral2" text="A Switch"></pfm-switch>

          <pfm-switch [(value)]="ephemeral2" text="Same Switch"></pfm-switch>

          <pfm-switch [disabled]="true" text="Disabled"></pfm-switch>

          <pfm-radiobutton radiovalue="rv1" text="Radio 1"></pfm-radiobutton>
          <pfm-radiobutton radiovalue="rv2" text="Radio 2"></pfm-radiobutton>
          <pfm-radiobutton radiovalue="rv3" text="Radio 3"></pfm-radiobutton>
          <pfm-radiobutton radiovalue="rv4" text="Disabled" [disabled]="true"></pfm-radiobutton>
          <pfm-radiobutton radiovalue="rv6" text="Valid" [invalid]="false"></pfm-radiobutton>
          <pfm-radiobutton radiovalue="rv7" text="Invalid" [invalid]="true"></pfm-radiobutton>

          <hr>

          <pfm-radiobutton radiovalue="rv1" name="radiogroup-1" text="Radio 1" [(value)]="radio"></pfm-radiobutton>
          <pfm-radiobutton radiovalue="rv2" name="radiogroup-1" text="Radio 2" [(value)]="radio"></pfm-radiobutton>
          <pfm-radiobutton radiovalue="rv3" name="radiogroup-1" text="Radio 3" [(value)]="radio"></pfm-radiobutton>
          <span (click)="radio = ''">RadioValue: '{{radio}}' (click to clear)</span>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="2.3" tabTitle="Textarea">
        <div class="tab-content">


          <pfm-textarea value="textarea" [rows]="2"></pfm-textarea>

          <pfm-textarea placeholder="(placehodler)" [rows]="2"></pfm-textarea>

          <pfm-textarea placeholder="4 rows" [rows]="4"></pfm-textarea>

          <pfm-textarea placeholder="disabled" [rows]="2" [disabled]="true"></pfm-textarea>

          <pfm-textarea placeholder="readonly" [rows]="2" [readonly]="true"></pfm-textarea>

          <pfm-textarea placeholder="invalid" [rows]="2" [invalid]="true"></pfm-textarea>

          <pfm-textarea placeholder="valid" [rows]="2" [invalid]="false"></pfm-textarea>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="2.4" tabTitle="Selects">
        <div class="tab-content">

          <pfm-select placeholder="pfm-select" [values]="[{key:'1', value:'Value 1'}, {key:'2', value:'Value 2'}, {key:'3', value:'Value 3'}]" [(value)]="selval"></pfm-select>

          <pfm-select placeholder="Choose me" [values]="[{key:'1', value:'Value 1'}, {key:'2', value:'Value 2'}, {key:'3', value:'Value 3'}]" [(value)]="selval"></pfm-select>

          <pfm-select placeholder="Choose me" [values]="[{key:'1', value:'Value 1'}, {key:'2', value:'Value 2'}, {key:'3', value:'Preselected'}]" value="3"></pfm-select>

          <pfm-select [values]="[{key:'1', value:'No placeholder'}, {key:'2', value:'Value 2'}, {key:'3', value:'Value 3'}]"></pfm-select>

          <pfm-select [disabled]="true" [values]="[{key:'1', value:'No placeholder'}, {key:'2', value:'Value 2'}, {key:'3', value:'Preselected'}]"></pfm-select>
          <pfm-select [invalid]="true" [values]="[{key:'1', value:'invalid'}, {key:'2', value:'Value 2'}, {key:'3', value:'Preselected'}]"></pfm-select>
          <pfm-select [invalid]="false" [values]="[{key:'1', value:'valid'}, {key:'2', value:'Value 2'}, {key:'3', value:'Preselected'}]"></pfm-select>

          <pfm-modal [(show)]="showModalSelec1">
            <div style="min-width: 800px; min-height: 300px; display: flex; flex-direction: column; gap: 1rem">

              <pfm-select placeholder="pfm-select" [values]="[{key:'1', value:'Value 1'}, {key:'2', value:'Value 2'}, {key:'3', value:'Value 3'}]" [(value)]="selval"></pfm-select>

              <pfm-select placeholder="Choose me" [values]="[{key:'1', value:'Value 1'}, {key:'2', value:'Value 2'}, {key:'3', value:'Value 3'}]" [(value)]="selval"></pfm-select>

              <pfm-select placeholder="Choose me" [values]="[{key:'1', value:'Value 1'}, {key:'2', value:'Value 2'}, {key:'3', value:'Preselected'}]" value="3"></pfm-select>

              <pfm-select [values]="[{key:'1', value:'No placeholder'}, {key:'2', value:'Value 2'}, {key:'3', value:'Value 3'}]"></pfm-select>

              <pfm-select [disabled]="true" [values]="[{key:'1', value:'No placeholder'}, {key:'2', value:'Value 2'}, {key:'3', value:'Preselected'}]"></pfm-select>
              <pfm-select [invalid]="true" [values]="[{key:'1', value:'invalid'}, {key:'2', value:'Value 2'}, {key:'3', value:'Preselected'}]"></pfm-select>
              <pfm-select [invalid]="false" [values]="[{key:'1', value:'valid'}, {key:'2', value:'Value 2'}, {key:'3', value:'Preselected'}]"></pfm-select>

            </div>
          </pfm-modal>

          <pfm-button type="primary" (btnClick)="showModalSelec1 = true">Select (in modal)</pfm-button>

          <hr>

          <pfm-multiselect class="val" placeholder="Multi Tag Select" [value]="['JASMINE', 'RASPBERRY']" [values]="multiSelectValues"></pfm-multiselect>

          <pfm-multiselect class="val" placeholder="pfm-multiselect" [value]="[]" [values]="multiSelectValues" [invalid]="true"></pfm-multiselect>

          <pfm-multiselect class="val" placeholder="Multi Tag Select" [value]="['ORANGE', 'World']" [values]="multiSelectValues" [invalid]="false"></pfm-multiselect>

          <pfm-multiselect class="val" placeholder="Multi Tag Select" [value]="['JASMINE', 'RASPBERRY']" [values]="multiSelectValues" [disabled]="true"></pfm-multiselect>

          <pfm-multiselect class="val" placeholder="Multi Tag Select" [value]="['JASMINE', 'RASPBERRY']" [values]="multiSelectValues" style="width: 400px"></pfm-multiselect>

          <pfm-multiselect class="val" placeholder="Multi Tag Select" [value]="['Mg', 'He']" [values]="multiSelectValues2" style="width: 400px"></pfm-multiselect>

          <hr>

          <pfm-suggestion-input #si1 [disabled]="true" placeholder="disabled" ></pfm-suggestion-input>

          <pfm-suggestion-input #si2  placeholder="fm-suggestion-input" [values]="['APPLE','BANANA','CHERRY','DATE','ELDERBERRY','FIG','GRAPE','HONEYDEW','IRIS','JASMINE','KIWI','LEMON','MANGO','NECTARINE','ORANGE','PEAR','QUINCE','RASPBERRY','STRAWBERRY','TANGERINE',]" ></pfm-suggestion-input>

          <pfm-suggestion-input #si3  [values]="['APPLE','BANANA','CHERRY','DATE','ELDERBERRY','FIG','GRAPE','HONEYDEW','IRIS','JASMINE','KIWI','LEMON','MANGO','NECTARINE','ORANGE','PEAR','QUINCE','RASPBERRY','STRAWBERRY','TANGERINE',]" ></pfm-suggestion-input>

          <pfm-suggestion-input #si4  [values]="[]" ></pfm-suggestion-input>

          <pfm-modal [(show)]="showModalSugg1">
            <div style="min-width: 800px; min-height: 300px; display: flex; flex-direction: column; gap: 1rem">

              <pfm-suggestion-input #si1 [disabled]="true" placeholder="disabled" ></pfm-suggestion-input>

              <pfm-suggestion-input #si2  placeholder="fm-suggestion-input" [values]="['APPLE','BANANA','CHERRY','DATE','ELDERBERRY','FIG','GRAPE','HONEYDEW','IRIS','JASMINE','KIWI','LEMON','MANGO','NECTARINE','ORANGE','PEAR','QUINCE','RASPBERRY','STRAWBERRY','TANGERINE',]" ></pfm-suggestion-input>

              <pfm-suggestion-input #si3  [values]="['APPLE','BANANA','CHERRY','DATE','ELDERBERRY','FIG','GRAPE','HONEYDEW','IRIS','JASMINE','KIWI','LEMON','MANGO','NECTARINE','ORANGE','PEAR','QUINCE','RASPBERRY','STRAWBERRY','TANGERINE',]" ></pfm-suggestion-input>

              <pfm-suggestion-input #si4  [values]="[]" ></pfm-suggestion-input>

            </div>
          </pfm-modal>

          <pfm-button type="primary" (btnClick)="showModalSugg1 = true">Suggestion-Input (in modal)</pfm-button>

          <label>Selections:</label>
          <label>[1]: {{si1.value}}</label>
          <label>[2]: {{si2.value}}</label>
          <label>[3]: {{si3.value}}</label>
          <label>[4]: {{si4.value}}</label>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="2.5" tabTitle="Date-Inputs">
        <div class="tab-content">

          <pfm-input                [(value)]="identifier"                  placeholder="Textfield" (onEnterUp)="this.inputPassword.focus()"></pfm-input>
          <pfm-input #inputPassword [(value)]="password"    type="password" placeholder="Passwort"></pfm-input>


          <span>Normal</span>  <pfm-dateinput [(value)]="d0"                  ></pfm-dateinput>
          <span>Invalid</span> <pfm-dateinput [invalid]="true"                ></pfm-dateinput>
          <span>Valid</span>   <pfm-dateinput [invalid]="false"               ></pfm-dateinput>
          <span>Disabled</span><pfm-dateinput [disabled]="true"               ></pfm-dateinput>
          <span>Readonly</span><pfm-dateinput [readonly]="true"               ></pfm-dateinput>
          <span>Now</span>     <pfm-dateinput [readonly]="true" [value]="now" ></pfm-dateinput>
          <span>Copy</span>    <pfm-dateinput [(value)]="d0"                  ></pfm-dateinput>

          <hr>

          <pfm-numberinput placeholder="Numbers only" unit="m"  ></pfm-numberinput>

          <pfm-numberinput placeholder="" unit="Euro"  ></pfm-numberinput>

          <pfm-numberinput placeholder="2 prec and min" [precision]="2" [min]="0" ></pfm-numberinput>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="3" tabTitle="Table">
        <div class="tab-content">


          <pfm-card header="Example">
            <pfm-table [datasource]="[{k:1}, {k:2}, {k:3}, {k:4}, {k:5}]">

              <pfm-table-col header="hello">
                <ng-template [ctxType]="{item:{k:1}}" let-item="item">1:{{item.k}}</ng-template>
              </pfm-table-col>

              <pfm-table-col header="world">
                <ng-template [ctxType]="{item:{k:1}}" let-item="item">2:{{item.k}}</ng-template>
              </pfm-table-col>

              <pfm-table-col header="yo">
                <ng-template [ctxType]="{item:{k:1}}" let-item="item">3:{{item.k}}</ng-template>
              </pfm-table-col>

            </pfm-table>
          </pfm-card>

          <pfm-card header="Example (Hover)">
            <pfm-table [hoverHighlight]="true" [datasource]="[{k:1}, {k:2}, {k:3}, {k:4}, {k:5}]">

              <pfm-table-col header="hello">
                <ng-template let-item="item">1:{{item.k}}</ng-template>
              </pfm-table-col>

              <pfm-table-col header="world">
                <ng-template let-item="item">2:{{item.k}}</ng-template>
              </pfm-table-col>

              <pfm-table-col header="yo">
                <ng-template let-item="item">3:{{item.k}}</ng-template>
              </pfm-table-col>

            </pfm-table>
          </pfm-card>

          <pfm-table type="bordered" [datasource]="[{k:1}, {k:2}, {k:3}, {k:4}, {k:5}]">

            <pfm-table-col header="hello">
              <ng-template let-item="item">1:{{item.k}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="world">
              <ng-template let-item="item">2:{{item.k}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="yo">
              <ng-template let-item="item">3:{{item.k}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="hello">
              <ng-template let-item="item">1:{{item.k}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="world">
              <ng-template let-item="item">2:{{item.k}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="yo">
              <ng-template let-item="item">3:{{item.k}}</ng-template>
            </pfm-table-col>

          </pfm-table>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="4" tabTitle="Modals & Notifications">
        <div class="tab-content">

          <div style="display: grid; grid-template-columns: repeat(5, auto); grid-column-gap: 1rem">
            <pfm-button (click)="notify('INFO', 'Title', 'Message', 3000)"    >INFO</pfm-button>
            <pfm-button (click)="notify('SUCCESS', 'Title', 'Message', 3000)" >SUCCESS</pfm-button>
            <pfm-button (click)="notify('WARNING', 'Title', 'Message', 3000)" >WARN</pfm-button>
            <pfm-button (click)="notify('ERROR', 'Title', 'Message', 3000)"   >ERROR</pfm-button>
            <pfm-button (click)="notify('INFO', 'Title', 'Message', null)"    >PERM</pfm-button>
          </div>

          <hr>

          <pfm-button type="primary" (btnClick)="showDialog()">Show Dialog</pfm-button>

          <hr>

          <pfm-modal [(show)]="showModal1">
            <div style="min-width: 800px; min-height: 300px; font-size: 4rem; display: flex; justify-content: center; align-items: center;">
              Hello World
            </div>
          </pfm-modal>

          <pfm-button type="primary" (btnClick)="showModal1 = true">Show Modal</pfm-button>

          <pfm-button type="primary" (btnClick)="showModal1Timed(3000)">Show Modal (3sec)</pfm-button>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="5" tabTitle="Misc">
        <div class="tab-content">

          <pfm-alert type="default" >This is an default</pfm-alert>
          <pfm-alert type="error"   >This is an error</pfm-alert>
          <pfm-alert type="info"    >This is an infp</pfm-alert>
          <pfm-alert type="success" >This is an success</pfm-alert>
          <pfm-alert type="warning" >This is an warni</pfm-alert>

          <pfm-readonly-input value="This input is readonly :("></pfm-readonly-input>

          <pfm-menubar style="align-self: center">
            <pfm-menubar-button type="secondary">Zurück</pfm-menubar-button>
            <pfm-menubar-button [icon]="['fas', 'save']">Option</pfm-menubar-button>
            <pfm-menubar-button [icon]="['fas', 'grid-2']">Secon</pfm-menubar-button>
            <pfm-menubar-button >no icon</pfm-menubar-button>
            <pfm-menubar-button [icon]="['fas', 'briefcase']">Fouth</pfm-menubar-button>
            <pfm-menubar-button routerLink="/" >router.link</pfm-menubar-button>
            <pfm-menubar-button (btnClick)="alert('hello')" >eventhandler</pfm-menubar-button>
            <pfm-menubar-button [icon]="['fas', 'trash']" [disabled]="true">disabled</pfm-menubar-button>
            <pfm-menubar-button type="primary">Weiter</pfm-menubar-button>
          </pfm-menubar>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="6" tabTitle="Pico">
        <div class="tab-content">

          <pfm-button [loading]="loginLoading" icon="right-to-bracket">Login</pfm-button>

          <pfm-button [loading]="true">Loading</pfm-button>

          <hr>

        </div>
      </pfm-tab>

    </pfm-tabs>

  </main>

</empty-frame>
