

<pfm-modal [show]="show"
           (showChange)="show = $event; showChange.emit($event);"
           modalWidth="calc(100vw - 4rem)"
           modalHeight="calc(100vh - 4rem)">

  <div class="modal-content">
    <h1>Bild auswählen</h1>

    <div *ngIf="loading" class="loading-div">
      <fa-icon *ngIf="loading" icon="loader" animation="spin"></fa-icon>
    </div>

    <div *ngIf="!loading" class="image-list">

      <pfm-imageblob-display *ngFor="let imgblob of images"
                             [clickable]="true"
                             [image]="imgblob"
                             mode="readonly"
                             [actions]="[]"
                             (imageClicked)="onImageClicked(imgblob)"></pfm-imageblob-display>

    </div>

  </div>

</pfm-modal>
