<div class="card" [attr.data-theme]="theme">

  <header *ngIf="header != null || headerExtra !== null">
    <span *ngIf="header !== null" class="text">{{header}}</span>
    <ng-container *ngIf="headerExtra !== null"><ng-container  *ngTemplateOutlet="(headerExtra)"></ng-container></ng-container>
  </header>

  <div class="content"><ng-content></ng-content></div>

  <footer *ngIf="footer != null">{{footer}}</footer>

</div>
