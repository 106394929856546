<frame-default [status]="status">
  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 2rem;"
                     [crumbs]="[{text:'Seiten'}, {text:'Firma', active: true}]"></pfm-breadcrumbs>

    <div class="form-content" *ngIf="company != null">


    <label>Firmen Logo</label>
    <pfm-avatar [defaultImage]="imageSource(company.logo)" [(selectedImage)]="newLogo" [round]="false"></pfm-avatar>

    <label>Firmen Name</label>
    <pfm-input [(value)]="companyName" [preventAutofill]="true" placeholder="Name der Firma"></pfm-input>

    <label>Anschrift</label>
    <div style="display: grid; grid-template-columns: 1fr 2fr 1fr; grid-column-gap: 1rem; grid-row-gap: 1rem; margin-bottom: 2rem;">
      <pfm-input style="grid-row:1; grid-column: 1/-1" [(value)]="address_name"         [preventAutofill]="true" placeholder="Name / Firma"></pfm-input>
      <pfm-input style="grid-row:2; grid-column: 1/-1" [(value)]="address_extra"        [preventAutofill]="true" placeholder="Adresszusatz"></pfm-input>
      <pfm-input style="grid-row:3; grid-column: 1/3"  [(value)]="address_street"       [preventAutofill]="true" placeholder="Straße"></pfm-input>
      <pfm-input style="grid-row:3; grid-column: 3"    [(value)]="address_streetNumber" [preventAutofill]="true" placeholder="Hausnummer"></pfm-input>
      <pfm-input style="grid-row:4; grid-column: 1"    [(value)]="address_zip"          [preventAutofill]="true" placeholder="Postleitzahl"></pfm-input>
      <pfm-input style="grid-row:4; grid-column: 2/4"  [(value)]="address_city"         [preventAutofill]="true" placeholder="Ort"></pfm-input>
      <pfm-input style="grid-row:5; grid-column: 1/-1" [(value)]="address_country"      [preventAutofill]="true" placeholder="Land"></pfm-input>
    </div>

    <label>Telefon Nummer</label>
    <pfm-input [(value)]="phoneNumber" [preventAutofill]="true" placeholder="Firmen Telefonnummer"></pfm-input>

    <label>Fax Nummer</label>
    <pfm-input [(value)]="faxNumber" [preventAutofill]="true" placeholder="Firmen Faxnummer"></pfm-input>

    <label>Mail Adresse</label>
    <pfm-input [(value)]="mailAddress" [preventAutofill]="true" placeholder="Mail Adresse der Firma"></pfm-input>

    <label>Webseite</label>
    <pfm-input [(value)]="website" [preventAutofill]="true" placeholder="Webseite der Firma"></pfm-input>




    <pfm-button type="primary" [loading]="saveLoading" (btnClick)="saveCompany()">Speichern</pfm-button>

    </div>
  </main>
</frame-default>
