<pfm-download-portfolio-modal [(portfolio)]="downloadPortfolio"></pfm-download-portfolio-modal>

<frame-default>

  <main *ngIf="allprojects !== null && metavalues !== null">

    <pfm-breadcrumbs style="align-self: start; margin-bottom: 2rem"
                     back="/portfolios/list"
                     [crumbs]="[{text:'Seiten'}, {text:'Portfolios', link:'/portfolios/list'}, {text:'Portfolio anlegen', link:'/portfolio/create', active:true}]">
    </pfm-breadcrumbs>

    <pfm-tabs [(selectedIndex)]="selectedTab">
      <pfm-tab tabIndex="1" tabTitle="Projekt auswählen">
        <div class="main-content">
          <label>Projekt<span class="required">*</span></label>
          <pfm-project-table [multiSelect]="true" [(selectedProjects)]="selectedProjects"></pfm-project-table>
        </div>
      </pfm-tab>
      <pfm-tab tabIndex="2" tabTitle="Layout definieren">
        <div class="main-content">
          <label>Name<span class="required">*</span></label>
          <pfm-input class="val" placeholder="Name" [(value)]="name" [invalid]="validate('PORTFOLIO_NAME')"></pfm-input>

          <label>Template<span class="required">*</span></label>
          <pfm-select class="val" placeholder="Template" [(value)]="selectedTemplate" [values]="metaToKeyValueExt(serverData?.template??null, metavalues.portfolioTemplate, metavalues.portfolioTemplateAvailable)" [invalid]="validate('TEMPLATE_KEY')"></pfm-select>

          <pfm-template-builder *ngIf="selectedTemplate !== null && selectedProjects.length > 0"
                                class="span"
                                [projectids]="[]"
                                [template]="selectedTemplate"
                                [mainPage]="true"
                                [(images)]="config.mainImages"
                                [invalid]="validate('TEMPLATE_CONFIG_IMAGES', 'TEMPLATE_CONFIG_TEXTS')"
                                [(texts)]="config.mainTexts"></pfm-template-builder>

          <ng-container *ngFor="let proj of selectedProjects">

            <pfm-template-builder *ngIf="selectedTemplate !== null && selectedProjects.length > 0"
                                  class="span"
                                  [projectids]="[proj]"
                                  [template]="selectedTemplate"
                                  [mainPage]="false"
                                  [(images)]="config.projectImages[proj]"
                                  [invalid]="validate('TEMPLATE_CONFIG_IMAGES', 'TEMPLATE_CONFIG_TEXTS')"
                                  [(texts)]="config.projectTexts[proj]"></pfm-template-builder>

          </ng-container>
        </div>
      </pfm-tab>
    </pfm-tabs>

    <pfm-menubar style="align-self: center">
      <pfm-menubar-button                           [disabled]="!(selectedTab > 0)"                                                                  type="secondary"        (btnClick)="selectedTab=selectedTab-1"    >Zurück</pfm-menubar-button>
      <pfm-menubar-button                           [disabled]="true"                                       [icon]="['fat', 'trash']"                                                                                  >Löschen</pfm-menubar-button>
      <pfm-menubar-button *ngIf="mode === 'CREATE'" [disabled]="hasNoPendingChanges()"                      [icon]="['fat', 'floppy-disk']"                                  (btnClick)="createPortfolio(false)"       >Erstellen</pfm-menubar-button>
      <pfm-menubar-button *ngIf="mode === 'EDIT'"   [disabled]="hasNoPendingChanges()"                      [icon]="['fat', 'floppy-disk']"                                  (btnClick)="updatePortfolio(false)"       >Speichern</pfm-menubar-button>
      <pfm-menubar-button *ngIf="selectedTab < 1"   [disabled]="hasNoPendingChanges()"                      [icon]="['fat', 'arrow-right']"          type="primary"          (btnClick)="selectedTab=selectedTab+1"    >Weiter</pfm-menubar-button>
      <pfm-menubar-button                           [disabled]="mode == 'CREATE' || !hasNoPendingChanges()" [icon]="['fat', 'arrow-down-to-square']"                         (btnClick)="downloadPortfolio=serverData" >Download</pfm-menubar-button>
      <pfm-menubar-button *ngIf="selectedTab === 1"                                                         [icon]="['fat', 'floppy-disk']"                                  (btnClick)="createOrUpdatePortfolio(true)">Speichern & Beenden</pfm-menubar-button>
    </pfm-menubar>

  </main>

</frame-default>
