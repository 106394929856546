
<pfm-modal [(show)]="showNewTeamMemberModal">
  <div style="display: flex; flex-direction: column; min-width: 30rem" *ngIf="metavalues !== null">

    <h2 style="text-align: center; margin: 1rem">Beteiligter hinzufügen</h2>

    <div style="height: 1rem"></div>

    <pfm-select class=""
                [values]="contactsToKeyValue(metavalues.contacts)"
                [(value)]="newContact.id"></pfm-select>

    <div style="height: 1rem"></div>

    <pfm-suggestion-input class=""
                          placeholder="Funktion"
                          [values]="metavalues.functionContactService"
                          [(value)]="newContact.service"></pfm-suggestion-input>

    <div style="height: 1rem"></div>

    <ng-container *ngIf="newContact.id === 'NEW'">
      <pfm-input placeholder="Name" class="" [(value)]="newContact.name" ></pfm-input>
    </ng-container>
    <ng-container *ngIf="newContact.id !== 'NEW'">
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(newContact.id)?.name ?? ''"></pfm-input>
    </ng-container>

    <div style="height: 1rem"></div>

    <ng-container *ngIf="newContact.id === 'NEW'">
      <pfm-button [icon]="['fas', 'plus']" [disabled]="newContact.name === ''" (btnClick)="showNewTeamMemberModal = false; addTeamMember();">Hinzufügen</pfm-button>
    </ng-container>
    <ng-container *ngIf="newContact.id !== 'NEW'">
      <pfm-button [icon]="['fas', 'plus']" (btnClick)="showNewTeamMemberModal = false; addTeamMember();">Hinzufügen</pfm-button>
    </ng-container>
  </div>
</pfm-modal>

<pfm-modal [(show)]="showEditTeamMemberModal">
  <div style="display: flex; flex-direction: column; min-width: 30rem" *ngIf="metavalues !== null">

    <h2 style="text-align: center; margin: 1rem">Funktion bearbeiten</h2>

    <div style="height: 1rem"></div>

    <pfm-suggestion-input class=""
                          placeholder="Funktion"
                          [values]="metavalues.functionContactService"
                          [(value)]="editContact.service"></pfm-suggestion-input>

    <div style="height: 1rem"></div>

    <ng-container *ngIf="editContact.id === 'NEW'">
      <pfm-input placeholder="Name" class="" [(value)]="editContact.name" ></pfm-input>
    </ng-container>
    <ng-container *ngIf="editContact.id !== 'NEW'">
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(editContact.id)?.name ?? ''"></pfm-input>
    </ng-container>

    <div style="height: 1rem"></div>

    <ng-container *ngIf="editContact.id === 'NEW'">
      <pfm-button [icon]="['fas', 'save']" [disabled]="editContact.name === ''" (btnClick)="showEditTeamMemberModal = false; updateTeamMember();">Speichern</pfm-button>
    </ng-container>
    <ng-container *ngIf="editContact.id !== 'NEW'">
    <pfm-button [icon]="['fas', 'save']" (btnClick)="showEditTeamMemberModal = false; updateTeamMember();">Speichern</pfm-button>
    </ng-container>
  </div>
</pfm-modal>

<pfm-modal [(show)]="showNewServiceProviderModal">
  <div style="display: flex; flex-direction: column; min-width: 30rem" *ngIf="metavalues !== null">

    <h2 style="text-align: center; margin: 1rem">Beteiligter hinzufügen</h2>

    <div style="height: 1rem;"></div>

    <pfm-select class=""
                [values]="contactsToKeyValue(metavalues.contacts)"
                [(value)]="newContact.id"></pfm-select>

    <div style="height: 1rem"></div>

    <pfm-suggestion-input class=""
                          placeholder="Funktion"
                          [values]="metavalues.functionContactService"
                          [(value)]="newContact.service"></pfm-suggestion-input>

    <div style="height: 1rem"></div>

    <ng-container *ngIf="newContact.id === 'NEW'">
      <pfm-input placeholder="Firmenname" class="" [(value)]="newContact.companyName" ></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input placeholder="PLZ"        class="" [(value)]="newContact.address.zip" ></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input placeholder="Stadt"      class="" [(value)]="newContact.address.city" ></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input placeholder="Name"       class="" [(value)]="newContact.name" ></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input placeholder="Telefon"    class="" [(value)]="newContact.phone" ></pfm-input>
    </ng-container>
    <ng-container *ngIf="newContact.id !== 'NEW'">
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(newContact.id)?.companyName   ?? ''"></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(newContact.id)?.address?.zip  ?? ''"></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(newContact.id)?.address?.city ?? ''"></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(newContact.id)?.name          ?? ''"></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(newContact.id)?.phone         ?? ''"></pfm-input>
    </ng-container>

    <div style="height: 1rem"></div>

    <ng-container *ngIf="newContact.id === 'NEW'">
      <pfm-button [icon]="['fas', 'plus']" [disabled]="newContact.companyName === ''" (btnClick)="showNewServiceProviderModal = false; addServiceProvider();">Hinzufügen</pfm-button>
    </ng-container>
    <ng-container *ngIf="newContact.id !== 'NEW'">
      <pfm-button [icon]="['fas', 'plus']" (btnClick)="showNewServiceProviderModal = false; addServiceProvider();">Hinzufügen</pfm-button>
    </ng-container>
  </div>
</pfm-modal>

<pfm-modal [(show)]="showEditServiceProviderModal">
  <div style="display: flex; flex-direction: column; min-width: 30rem" *ngIf="metavalues !== null">

    <h2 style="text-align: center; margin: 1rem">Beteiligter bearbeiten</h2>

    <div style="height: 1rem;"></div>

    <pfm-suggestion-input class=""
                          placeholder="Funktion"
                          [values]="metavalues.functionContactService"
                          [(value)]="editContact.service"></pfm-suggestion-input>

    <div style="height: 1rem"></div>

    <ng-container *ngIf="editContact.id === 'NEW'">
      <pfm-input placeholder="Firmenname" class="" [(value)]="editContact.companyName" ></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input placeholder="PLZ"        class="" [(value)]="editContact.address.zip" ></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input placeholder="Stadt"      class="" [(value)]="editContact.address.city" ></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input placeholder="Name"       class="" [(value)]="editContact.name" ></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input placeholder="Telefon"    class="" [(value)]="editContact.phone" ></pfm-input>
    </ng-container>
    <ng-container *ngIf="editContact.id !== 'NEW'">
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(editContact.id)?.companyName   ?? ''"></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(editContact.id)?.address?.zip  ?? ''"></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(editContact.id)?.address?.city ?? ''"></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(editContact.id)?.name          ?? ''"></pfm-input>
      <div style="height: 0.5rem"></div>
      <pfm-input [disabled]="true" class="" [value]="getMetaContact(editContact.id)?.phone         ?? ''"></pfm-input>
    </ng-container>

    <div style="height: 1rem"></div>

    <ng-container *ngIf="editContact.id === 'NEW'">
      <pfm-button [icon]="['fas', 'save']" [disabled]="editContact.companyName === ''" (btnClick)="showEditServiceProviderModal = false; updateServiceProvider();">Speichern</pfm-button>
    </ng-container>
    <ng-container *ngIf="editContact.id !== 'NEW'">
      <pfm-button [icon]="['fas', 'save']" (btnClick)="showEditServiceProviderModal = false; updateServiceProvider();">Speichern</pfm-button>
    </ng-container>
  </div>
</pfm-modal>

<frame-default [status]="status" errorMessage="Beim Laden der Projektdaten ist ein Fehler aufgetreten">

  <main>

    <pfm-breadcrumbs style="align-self: start; margin-bottom: 2rem"
                     back="/projects/list"
                     [crumbs]="[{text:'Seiten'}, {text:'Projekte', link:'/projects/list'}, {text:'Projekt anlegen', link:'/projects/create', active:true}]"></pfm-breadcrumbs>

    <pfm-tabs [(selectedIndex)]="selectedTab">

      <pfm-tab tabIndex="1" tabTitle="Allgemeine Projektangaben">
        <div *ngIf="metavalues !== null" class="tab-content">

          <div class="row-flex justify-center">
            <ng-container *ngFor="let v of metavalues.classification">
              <pfm-radiobutton class="span" name="classification" [radiovalue]="v.value" [text]="v.description" [(value)]="classification" [invalid]="validate('CLASSIFICATION')"></pfm-radiobutton>
            </ng-container>
            <pfm-button *ngIf="!completionDate" (click)="finishProject()">Projekt abschliessen</pfm-button>
            <span class="key" *ngIf="completionDate">Abgeschlossen von: {{completedBy?.email}} Am: {{completionDate | displaydate }} </span>
          </div>

          <div class="space-2"></div>

          <div class="row-2">
            <span class="key">Projektnummer<span class="required">*</span></span>
            <span class="key">Projektname<span class="required">*</span></span>
            <pfm-input class="val" [(value)]="projectNumber" placeholder="Geben Sie die Projektnummer ein" [invalid]="validate('PROJECT_NUMBER')"></pfm-input>
            <pfm-input class="val" [(value)]="projectName"   placeholder="Geben Sie den Projektname ein"   [invalid]="validate('PROJECT_NAME')"  ></pfm-input>
          </div>

          <div class="separator-dashed"></div>

          <h2>Projektanschrift</h2>

          <div class="row-2">
            <span class="key">Straße</span>
            <span class="key">Hausnummer</span>
            <pfm-input class="val" [(value)]="projectAddress.street"       placeholder="Geben Sie den Straßennamen ein" [invalid]="validate('PROJECT_ADDRESS')"></pfm-input>
            <pfm-input class="val" [(value)]="projectAddress.streetNumber" placeholder="Geben Sie die Hausnummer ein"   [invalid]="validate('PROJECT_ADDRESS')"></pfm-input>
          </div>

          <div class="space-3"></div>

          <div class="row-3">
            <span class="key">Postleitzahl</span>
            <span class="key">Stadt</span>
            <span class="key">Land</span>

            <pfm-input            class="val" [(value)]="projectAddress.zip"          placeholder="Geben Sie die PLZ ein"             [invalid]="validate('PROJECT_ADDRESS')"></pfm-input>
            <pfm-input            class="val" [(value)]="projectAddress.city"         placeholder="Geben Sie den Namen der Stadt ein" [invalid]="validate('PROJECT_ADDRESS')"></pfm-input>
            <pfm-suggestion-input class="val" [(value)]="projectAddress.country"      placeholder="Geben Sie das Land ein"            [invalid]="validate('PROJECT_ADDRESS')" [values]="metavalues.defaultCountries"></pfm-suggestion-input>
          </div>

          <div class="separator-dashed"></div>

          <h2>Vertraulichkeitserklärung</h2>

          <pfm-switch [(value)]="confidentialAgreement" text="Projekt unterliegt einer Vertraulichkeitserklärung"></pfm-switch>

          <div class="separator-dashed"></div>

          <h2>Verfahrensart <fa-icon class="clearicon" [class.nullvalue]="procedure == null" (click)="procedure = null" [icon]="['fas', 'circle-xmark']"></fa-icon></h2>

          <div class="row-flex">
            <ng-container *ngFor="let v of metavalues.procedures">
              <pfm-radiobutton class="span" name="procedure" [radiovalue]="v.value" [text]="v.description" [(value)]="procedure" [invalid]="validate('PROCEDURE')"></pfm-radiobutton>
            </ng-container>
          </div>

          <div class="separator-dashed"></div>

          <h2>Kategorien</h2>

          <div class="row-single">
            <pfm-multiselect class="span-val-2"
                             placeholder="Geben Sie eine Kategorie ein"
                             [(value)]="categories"
                             [values]="stringToKeyValue(metavalues.categories)"
                             [autoSubmit]="true"
                             [invalid]="validate('CATEGORIES')"></pfm-multiselect>
          </div>


        </div>
      </pfm-tab>

      <pfm-tab tabIndex="2" tabTitle="Angaben zu Projektbeteiligten">
        <div *ngIf="metavalues !== null" class="tab-content">

          <h2>Projektteam (intern)</h2>

          <pfm-button type="sub"
                      [icon]="['fat', 'user-plus']"
                      (btnClick)="showProjectTeamModal()"
                      style="align-self: start">Weitere Beteiligte hinzufügen</pfm-button>

          <div class="space-2"></div>

          <pfm-table *ngIf="teamMembers.length > 0" style="width: 100%" type="bordered" [invalid]="validate('TEAM_MEMBER')" [datasource]="teamMembers">

            <pfm-table-col header="Funktion" widthmode="default">
              <ng-template [ctxType]="{item:teamMembers[0]!}" let-item="item">{{item.service}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="Name" widthmode="default">
              <ng-template [ctxType]="{item:teamMembers[0]!}" let-item="item">{{resolveFunctionContact(item).name}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="E-Mail" widthmode="default">
              <ng-template [ctxType]="{item:teamMembers[0]!}" let-item="item">{{resolveFunctionContact(item).mail}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="" widthmode="fit">
              <ng-template [ctxType]="{item:teamMembers[0]!}" let-item="item">
                <div style="display: flex; flex-direction: row; gap: 3rem">
                  <pfm-button [icon]="['fat', 'pen']"   (btnClick)="editTeamMember(item)" type="sub-trans">Bearbeiten</pfm-button>
                  <pfm-button [icon]="['fat', 'trash']" (btnClick)="remTeamMember(item)"  type="sub-trans">Löschen</pfm-button>
                </div>
              </ng-template>
            </pfm-table-col>

          </pfm-table>

          <ng-container *ngIf="procedure === 'COMPETITION_WIN'">
            <div class="separator-dashed"></div>

            <h2>Auslober (bei Wettbewerb)</h2>

            <div class="row-flex">
              <ng-container *ngFor="let v of metavalues.privacy">
                <pfm-radiobutton name="awardingBody_privacy" [radiovalue]="v.value" [text]="v.description" [(value)]="awardingBody.privacy" [invalid]="validate('AWARDING_BODY')"></pfm-radiobutton>
              </ng-container>
            </div>

            <div class="space-2"></div>

            <div class="row-3">
              <span class="key"></span>
              <span class="key">Name</span>
              <span class="key">Firma</span>
              <pfm-select class="val" [values]="contactsToKeyValue(metavalues.contacts)" [(value)]="awardingBody.id" [invalid]="validate('AWARDING_BODY')"></pfm-select>

              <pfm-input  class="val" *ngIf="awardingBody.id === 'NEW'" [(value)]="awardingBody.name"        placeholder="Geben Sie den Name ein"    [invalid]="validate('AWARDING_BODY')"></pfm-input>
              <pfm-input  class="val" *ngIf="awardingBody.id === 'NEW'" [(value)]="awardingBody.companyName" placeholder="Geben Sie die Firma ein"   [invalid]="validate('AWARDING_BODY')"></pfm-input>

              <pfm-input  class="val" *ngIf="awardingBody.id !== 'NEW'" [value]="getMetaContact(awardingBody.id)?.name        ?? ''" [disabled]="true" placeholder="Geben Sie den Name ein" ></pfm-input>
              <pfm-input  class="val" *ngIf="awardingBody.id !== 'NEW'" [value]="getMetaContact(awardingBody.id)?.companyName ?? ''" [disabled]="true" placeholder="Geben Sie die Firma ein"></pfm-input>

            </div>

            <div class="space-3"></div>

            <div class="row-2">
              <span class="key">Straße</span>
              <span class="key">Hausnummer</span>

              <pfm-input class="val" *ngIf="awardingBody.id === 'NEW'" [(value)]="awardingBody.address.street"       placeholder="Geben Sie den Straßennamen ein" [invalid]="validate('AWARDING_BODY')"></pfm-input>
              <pfm-input class="val" *ngIf="awardingBody.id === 'NEW'" [(value)]="awardingBody.address.streetNumber" placeholder="Geben Sie die Hausnummer ein"   [invalid]="validate('AWARDING_BODY')"></pfm-input>

              <pfm-input class="val" *ngIf="awardingBody.id !== 'NEW'" [value]="getMetaContact(awardingBody.id)?.address?.street       ?? ''" [disabled]="true" placeholder="Geben Sie den Straßennamen ein"></pfm-input>
              <pfm-input class="val" *ngIf="awardingBody.id !== 'NEW'" [value]="getMetaContact(awardingBody.id)?.address?.streetNumber ?? ''" [disabled]="true" placeholder="Geben Sie die Hausnummer ein"  ></pfm-input>

            </div>

            <div class="space-3"></div>

            <div class="row-3">
              <span class="key">Postleitzahl</span>
              <span class="key">Stadt</span>
              <span class="key">Land</span>

              <pfm-input            class="val" *ngIf="awardingBody.id === 'NEW'" [(value)]="awardingBody.address.zip"          placeholder="Geben Sie die PLZ ein"             [invalid]="validate('AWARDING_BODY')"></pfm-input>
              <pfm-input            class="val" *ngIf="awardingBody.id === 'NEW'" [(value)]="awardingBody.address.city"         placeholder="Geben Sie den Namen der Stadt ein" [invalid]="validate('AWARDING_BODY')"></pfm-input>
              <pfm-suggestion-input class="val" *ngIf="awardingBody.id === 'NEW'" [(value)]="awardingBody.address.country"      placeholder="Geben Sie das Land ein"            [invalid]="validate('AWARDING_BODY')"  [values]="metavalues.defaultCountries"></pfm-suggestion-input>

              <pfm-input class="val" *ngIf="awardingBody.id !== 'NEW'" [value]="getMetaContact(awardingBody.id)?.address?.zip      ?? ''"  placeholder="Geben Sie die PLZ ein"             [disabled]="true"></pfm-input>
              <pfm-input class="val" *ngIf="awardingBody.id !== 'NEW'" [value]="getMetaContact(awardingBody.id)?.address?.city     ?? ''"  placeholder="Geben Sie den Namen der Stadt ein" [disabled]="true"></pfm-input>
              <pfm-input class="val" *ngIf="awardingBody.id !== 'NEW'" [value]="getMetaContact(awardingBody.id)?.address?.country  ?? ''"  placeholder="Geben Sie das Land ein"            [disabled]="true"></pfm-input>

            </div>

          </ng-container>

          <div class="separator-dashed"></div>

          <h2>Bauherr</h2>

          <ng-container>

            <div class="row-flex">
              <ng-container *ngFor="let v of metavalues.privacy">
                <pfm-radiobutton name="builder_privacy" [radiovalue]="v.value" [text]="v.description" [(value)]="builder.privacy" [invalid]="validate('BUILDER')"></pfm-radiobutton>
              </ng-container>
            </div>

            <div class="space-2"></div>

            <div class="row-3">
              <span class="key"></span>
              <span class="key">Name</span>
              <span class="key">Firma</span>
              <pfm-select class="val" [values]="contactsToKeyValue(metavalues.contacts)" [(value)]="builder.id" [invalid]="validate('BUILDER')"></pfm-select>

              <pfm-input  class="val" *ngIf="builder.id === 'NEW'" [(value)]="builder.name"        placeholder="Geben Sie den Name ein"    [invalid]="validate('BUILDER')"></pfm-input>
              <pfm-input  class="val" *ngIf="builder.id === 'NEW'" [(value)]="builder.companyName" placeholder="Geben Sie die Firma ein"   [invalid]="validate('BUILDER')"></pfm-input>

              <pfm-input  class="val" *ngIf="builder.id !== 'NEW'" [value]="getMetaContact(builder.id)?.name        ?? ''" [disabled]="true" placeholder="Geben Sie den Name ein" ></pfm-input>
              <pfm-input  class="val" *ngIf="builder.id !== 'NEW'" [value]="getMetaContact(builder.id)?.companyName ?? ''" [disabled]="true" placeholder="Geben Sie die Firma ein"></pfm-input>

            </div>

            <div class="space-3"></div>

            <div class="row-2">
              <span class="key">Straße</span>
              <span class="key">Hausnummer</span>

              <pfm-input class="val" *ngIf="builder.id === 'NEW'" [(value)]="builder.address.street"       placeholder="Geben Sie den Straßennamen ein" [invalid]="validate('BUILDER')"></pfm-input>
              <pfm-input class="val" *ngIf="builder.id === 'NEW'" [(value)]="builder.address.streetNumber" placeholder="Geben Sie die Hausnummer ein"   [invalid]="validate('BUILDER')"></pfm-input>

              <pfm-input class="val" *ngIf="builder.id !== 'NEW'" [value]="getMetaContact(builder.id)?.address?.street       ?? ''" [disabled]="true" placeholder="Geben Sie den Straßennamen ein"></pfm-input>
              <pfm-input class="val" *ngIf="builder.id !== 'NEW'" [value]="getMetaContact(builder.id)?.address?.streetNumber ?? ''" [disabled]="true" placeholder="Geben Sie die Hausnummer ein"  ></pfm-input>

            </div>

            <div class="space-3"></div>

            <div class="row-3">
              <span class="key">Postleitzahl</span>
              <span class="key">Stadt</span>
              <span class="key">Land</span>

              <pfm-input            class="val" *ngIf="builder.id === 'NEW'" [(value)]="builder.address.zip"          placeholder="Geben Sie die PLZ ein"             [invalid]="validate('BUILDER')"></pfm-input>
              <pfm-input            class="val" *ngIf="builder.id === 'NEW'" [(value)]="builder.address.city"         placeholder="Geben Sie den Namen der Stadt ein" [invalid]="validate('BUILDER')"></pfm-input>
              <pfm-suggestion-input class="val" *ngIf="builder.id === 'NEW'" [(value)]="builder.address.country"      placeholder="Geben Sie das Land ein"            [invalid]="validate('BUILDER')"  [values]="metavalues.defaultCountries"></pfm-suggestion-input>

              <pfm-input class="val" *ngIf="builder.id !== 'NEW'" [value]="getMetaContact(builder.id)?.address?.zip      ?? ''"  placeholder="Geben Sie die PLZ ein"             [disabled]="true"></pfm-input>
              <pfm-input class="val" *ngIf="builder.id !== 'NEW'" [value]="getMetaContact(builder.id)?.address?.city     ?? ''"  placeholder="Geben Sie den Namen der Stadt ein" [disabled]="true"></pfm-input>
              <pfm-input class="val" *ngIf="builder.id !== 'NEW'" [value]="getMetaContact(builder.id)?.address?.country  ?? ''"  placeholder="Geben Sie das Land ein"            [disabled]="true"></pfm-input>

            </div>

          </ng-container>

          <div class="separator-dashed"></div>

          <h2>Auftraggeber</h2>

          <ng-container>

            <div class="row-flex">
              <ng-container *ngFor="let v of metavalues.privacy">
                <pfm-radiobutton name="client_privacy" [radiovalue]="v.value" [text]="v.description" [(value)]="client.privacy" [invalid]="validate('CLIENT')"></pfm-radiobutton>
              </ng-container>
            </div>

            <div class="space-2"></div>

            <pfm-switch [(value)]="clientisbuilder" text="Auftraggeber ist Bauherr (Übernimmt die Daten aus dem Abschnitt Bauherr)"></pfm-switch>

            <div class="space-2"></div>

            <div class="row-3">
              <span class="key"></span>
              <span class="key">Name</span>
              <span class="key">Firma</span>

              <pfm-select class="val" *ngIf="!clientisbuilder"                        [values]="contactsToKeyValue(metavalues.contacts)" [(value)]="client.id" [invalid]="validate('CLIENT')"></pfm-select>

              <pfm-input  class="val" *ngIf="!clientisbuilder && client.id === 'NEW'" [(value)]="client.name"        placeholder="Geben Sie den Name ein"    [invalid]="validate('CLIENT')"></pfm-input>
              <pfm-input  class="val" *ngIf="!clientisbuilder && client.id === 'NEW'" [(value)]="client.companyName" placeholder="Geben Sie die Firma ein"   [invalid]="validate('CLIENT')"></pfm-input>

              <pfm-input  class="val" *ngIf="!clientisbuilder && client.id !== 'NEW'" [value]="getMetaContact(client.id)?.name        ?? ''" [disabled]="true" placeholder="Geben Sie den Name ein" ></pfm-input>
              <pfm-input  class="val" *ngIf="!clientisbuilder && client.id !== 'NEW'" [value]="getMetaContact(client.id)?.companyName ?? ''" [disabled]="true" placeholder="Geben Sie die Firma ein"></pfm-input>

              <pfm-select class="val" *ngIf="clientisbuilder"                         [values]="contactsToKeyValue(metavalues.contacts)" [(value)]="builder.id" [disabled]="true"></pfm-select>

              <pfm-input  class="val" *ngIf="clientisbuilder"                         [value]="getMetaContact(builder.id)?.name        ?? builder.name"        [disabled]="true" placeholder="Geben Sie den Name ein" ></pfm-input>
              <pfm-input  class="val" *ngIf="clientisbuilder"                         [value]="getMetaContact(builder.id)?.companyName ?? builder.companyName" [disabled]="true" placeholder="Geben Sie die Firma ein"></pfm-input>

            </div>

            <div class="space-3"></div>

            <div class="row-2">
              <span class="key">Straße</span>
              <span class="key">Hausnummer</span>

              <pfm-input class="val" *ngIf="!clientisbuilder && client.id === 'NEW'" [(value)]="client.address.street"       placeholder="Geben Sie den Straßennamen ein" [invalid]="validate('CLIENT')"></pfm-input>
              <pfm-input class="val" *ngIf="!clientisbuilder && client.id === 'NEW'" [(value)]="client.address.streetNumber" placeholder="Geben Sie die Hausnummer ein"   [invalid]="validate('CLIENT')"></pfm-input>

              <pfm-input class="val" *ngIf="!clientisbuilder && client.id !== 'NEW'" [value]="getMetaContact(client.id)?.address?.street       ?? ''" [disabled]="true" placeholder="Geben Sie den Straßennamen ein"></pfm-input>
              <pfm-input class="val" *ngIf="!clientisbuilder && client.id !== 'NEW'" [value]="getMetaContact(client.id)?.address?.streetNumber ?? ''" [disabled]="true" placeholder="Geben Sie die Hausnummer ein"  ></pfm-input>

              <pfm-input class="val" *ngIf="clientisbuilder"                         [value]="getMetaContact(builder.id)?.address?.street       ?? builder.address.street"       [disabled]="true" placeholder="Geben Sie den Straßennamen ein"></pfm-input>
              <pfm-input class="val" *ngIf="clientisbuilder"                         [value]="getMetaContact(builder.id)?.address?.streetNumber ?? builder.address.streetNumber" [disabled]="true" placeholder="Geben Sie die Hausnummer ein"  ></pfm-input>

            </div>

            <div class="space-3"></div>

            <div class="row-3">
              <span class="key">Postleitzahl</span>
              <span class="key">Stadt</span>
              <span class="key">Land</span>

              <pfm-input            class="val" *ngIf="!clientisbuilder && client.id === 'NEW'" [(value)]="client.address.zip"          placeholder="Geben Sie die PLZ ein"             [invalid]="validate('CLIENT')"></pfm-input>
              <pfm-input            class="val" *ngIf="!clientisbuilder && client.id === 'NEW'" [(value)]="client.address.city"         placeholder="Geben Sie den Namen der Stadt ein" [invalid]="validate('CLIENT')"></pfm-input>
              <pfm-suggestion-input class="val" *ngIf="!clientisbuilder && client.id === 'NEW'" [(value)]="client.address.country"      placeholder="Geben Sie das Land ein"            [invalid]="validate('CLIENT')" [values]="metavalues.defaultCountries"></pfm-suggestion-input>

              <pfm-input class="val" *ngIf="!clientisbuilder && client.id !== 'NEW'" [value]="getMetaContact(client.id)?.address?.zip      ?? ''"  placeholder="Geben Sie die PLZ ein"             [disabled]="true"></pfm-input>
              <pfm-input class="val" *ngIf="!clientisbuilder && client.id !== 'NEW'" [value]="getMetaContact(client.id)?.address?.city     ?? ''"  placeholder="Geben Sie den Namen der Stadt ein" [disabled]="true"></pfm-input>
              <pfm-input class="val" *ngIf="!clientisbuilder && client.id !== 'NEW'" [value]="getMetaContact(client.id)?.address?.country  ?? ''"  placeholder="Geben Sie das Land ein"            [disabled]="true"></pfm-input>

              <pfm-input class="val" *ngIf="clientisbuilder"                         [value]="getMetaContact(builder.id)?.address?.zip      ?? builder.address.zip"     placeholder="Geben Sie die PLZ ein"             [disabled]="true"></pfm-input>
              <pfm-input class="val" *ngIf="clientisbuilder"                         [value]="getMetaContact(builder.id)?.address?.city     ?? builder.address.city"    placeholder="Geben Sie den Namen der Stadt ein" [disabled]="true"></pfm-input>
              <pfm-input class="val" *ngIf="clientisbuilder"                         [value]="getMetaContact(builder.id)?.address?.country  ?? builder.address.country" placeholder="Geben Sie das Land ein"            [disabled]="true"></pfm-input>

            </div>

          </ng-container>

          <div class="separator-dashed"></div>

          <h2>Projektbeteiligte (extern)</h2>

          <pfm-button type="sub"
                      [icon]="['fat', 'user-plus']"
                      (btnClick)="showServiceProviderModal()"
                      style="align-self: start">Weitere Beteiligte hinzufügen</pfm-button>

          <div class="space-2"></div>

          <pfm-table *ngIf="serviceProvider.length > 0" style="width: 100%" type="bordered" [datasource]="serviceProvider">

            <pfm-table-col header="Leistung" widthmode="default">
              <ng-template [ctxType]="{item:serviceProvider[0]!}" let-item="item">{{item.service}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="Firmenname" widthmode="default">
              <ng-template [ctxType]="{item:serviceProvider[0]!}" let-item="item">{{resolveFunctionContact(item).companyName}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="PLZ" widthmode="default">
              <ng-template [ctxType]="{item:serviceProvider[0]!}" let-item="item">{{resolveFunctionContact(item).address.zip}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="Stadt" widthmode="default">
              <ng-template [ctxType]="{item:serviceProvider[0]!}" let-item="item">{{resolveFunctionContact(item).address.city}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="Name" widthmode="default">
              <ng-template [ctxType]="{item:serviceProvider[0]!}" let-item="item">{{resolveFunctionContact(item).name}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="Telefon" widthmode="default">
              <ng-template [ctxType]="{item:serviceProvider[0]!}" let-item="item">{{resolveFunctionContact(item).phone}}</ng-template>
            </pfm-table-col>

            <pfm-table-col header="" widthmode="fit">
              <ng-template [ctxType]="{item:serviceProvider[0]!}" let-item="item">
                <div style="display: flex; flex-direction: row; gap: 3rem">
                  <pfm-button [icon]="['fat', 'pen']"   (btnClick)="editServiceProvider(item)" type="sub-trans">Bearbeiten</pfm-button>
                  <pfm-button [icon]="['fat', 'trash']" (btnClick)="remServiceProvider(item)"  type="sub-trans">Löschen</pfm-button>
                </div>
              </ng-template>
            </pfm-table-col>

          </pfm-table>

        </div>
      </pfm-tab>

      <pfm-tab tabIndex="3" tabTitle="Spezifische Projektangaben">
        <div *ngIf="metavalues !== null" class="tab-content">

          <h2>Beauftragte Leistungsphase nach HOAI</h2>

          <div class="row-flex">
            <pfm-checkbox text="LP 1" [(value)]="servicePhases.lp1"></pfm-checkbox>
            <pfm-checkbox text="LP 2" [(value)]="servicePhases.lp2"></pfm-checkbox>
            <pfm-checkbox text="LP 3" [(value)]="servicePhases.lp3"></pfm-checkbox>
            <pfm-checkbox text="LP 4" [(value)]="servicePhases.lp4"></pfm-checkbox>
            <pfm-checkbox text="LP 5" [(value)]="servicePhases.lp5"></pfm-checkbox>
            <pfm-checkbox text="LP 6" [(value)]="servicePhases.lp6"></pfm-checkbox>
            <pfm-checkbox text="LP 7" [(value)]="servicePhases.lp7"></pfm-checkbox>
            <pfm-checkbox text="LP 8" [(value)]="servicePhases.lp8"></pfm-checkbox>
            <pfm-checkbox text="LP 9" [(value)]="servicePhases.lp9"></pfm-checkbox>
          </div>

          <div class="space-3"></div>

          <div class="row-3">
            <span class="key">Projektstatus</span>
            <span class="key">Projektart</span>
            <span class="key">Nutzung</span>

            <pfm-suggestion-input class="val" placeholder="Projektstatus" [values]="metavalues.projectStatus"                   [(value)]="projectStatus" [invalid]="validate('PROJECT_STATUS')"></pfm-suggestion-input>
            <pfm-multiselect      class="val" placeholder="Projektart"    [values]="stringToKeyValue(metavalues.projectKinds)"  [(value)]="projectKind"   [invalid]="validate('PROJECT_KIND')"   [autoSubmit]="true"></pfm-multiselect>

            <pfm-multiselect class="val" placeholder="Nutzung" [values]="stringToKeyValue(metavalues.utilisation)" [(value)]="utilisation" [invalid]="validate('UTILISATION')" [autoSubmit]="true"></pfm-multiselect>
          </div>

          <div class="space-3"></div>

          <div class="row-3">
            <span class="key">Leistungsbild</span>
            <span class="key">Konstruktionsart</span>
            <span class="key">Nachhaltigkeitsaspekte</span>

            <pfm-multiselect class="val" placeholder="Leistungsbild"          [values]="stringToKeyValue(metavalues.workScopes)"            [(value)]="workScopes"            [invalid]="validate('WORK_SCOPES')"            [autoSubmit]="true"></pfm-multiselect>
            <pfm-multiselect class="val" placeholder="Konstruktionsart"       [values]="stringToKeyValue(metavalues.constructionTypes)"     [(value)]="constructionTypes"     [invalid]="validate('CONSTRUCTION_TYPES')"     [autoSubmit]="true"></pfm-multiselect>
            <pfm-multiselect class="val" placeholder="Nachhaltigkeitsaspekte" [values]="stringToKeyValue(metavalues.sustainabilityAspects)" [(value)]="sustainabilityAspects" [invalid]="validate('SUSTAINABILITY_ASPECTS')" [autoSubmit]="true"></pfm-multiselect>
          </div>

          <div class="separator-dashed"></div>

          <h2>Meilensteine</h2>

          <div class="row-4-small">
            <span class="key">Projektstart</span>
            <span class="key">Ende LPH3</span>
            <span class="key">Baubeginn</span>
            <span class="key">Projektende</span>

            <pfm-dateinput class="val" [(value)]="milestones_projectStart"   [invalid]="validate('PROJECT_START')" ></pfm-dateinput>
            <pfm-dateinput class="val" [(value)]="milestones_endLPH3"        [invalid]="validate('END_LPH3')"      ></pfm-dateinput>
            <pfm-dateinput class="val" [(value)]="milestones_buildingStart"  [invalid]="validate('BUILDING_START')"></pfm-dateinput>
            <pfm-dateinput class="val" [(value)]="milestones_projectEnd"     [invalid]="validate('PROJECT_END')"   ></pfm-dateinput>
          </div>

          <div class="space-3"></div>

          <div class="row-1-full">
            <span class="key">Besonderheiten</span>
            <pfm-textarea [(value)]="specialFeatures" [rows]="8" resize="vertical" [invalid]="validate('SPECIAL_FEATURES')"></pfm-textarea>
          </div>

          <div class="separator-dashed"></div>

          <h2>Kennwerte Lasten</h2>

          <span>Windzone <fa-icon class="clearicon" [class.nullvalue]="windLoadZone == null" (click)="windLoadZone = null" [icon]="['fas', 'circle-xmark']"></fa-icon></span>
          <div class="space-1"></div>

          <div class="row-flex">
            <ng-container *ngFor="let v of metavalues.windLoadZone">
              <pfm-radiobutton class="span" name="windLoadZone" [radiovalue]="v.value" [text]="v.description" [(value)]="windLoadZone" [invalid]="validate('WIND_LOAD_ZONE')"></pfm-radiobutton>
            </ng-container>
          </div>

          <div class="space-2"></div>

          <span>Erdbebenzone <fa-icon class="clearicon" [class.nullvalue]="earthquakeZone == null" (click)="earthquakeZone = null" [icon]="['fas', 'circle-xmark']"></fa-icon></span>
          <div class="space-1"></div>

          <div class="row-flex">
            <ng-container *ngFor="let v of metavalues.earthquakeZone">
              <pfm-radiobutton class="span" name="earthquakeZone" [radiovalue]="v.value" [text]="v.description" [(value)]="earthquakeZone" [invalid]="validate('EARTHQUAKE_ZONE')"></pfm-radiobutton>
            </ng-container>
          </div>

          <div class="space-2"></div>

          <span>Schneelast <fa-icon class="clearicon" [class.nullvalue]="snowLoad == null" (click)="snowLoad = null" [icon]="['fas', 'circle-xmark']"></fa-icon></span>
          <div class="space-1"></div>

          <div class="row-flex">
            <ng-container *ngFor="let v of metavalues.snowLoad">
              <pfm-radiobutton class="span" name="snowLoad" [radiovalue]="v.value" [text]="v.description" [(value)]="snowLoad" [invalid]="validate('SNOW_LOAD')"></pfm-radiobutton>
            </ng-container>
          </div>

          <div class="space-2"></div>

          <span>Nutzlasten <fa-icon class="clearicon" [class.nullvalue]="payload == null" (click)="payload = null" [icon]="['fas', 'circle-xmark']"></fa-icon></span>
          <div class="space-1"></div>

          <div class="row-flex">
            <ng-container *ngFor="let v of metavalues.payload">
              <pfm-radiobutton class="span" name="payload" [radiovalue]="v.value" [text]="v.description" [(value)]="payload" [invalid]="validate('PAYLOAD')"></pfm-radiobutton>
            </ng-container>
          </div>

          <div class="separator-dashed"></div>

          <h2>Kennwerte Bauwerk</h2>

          <div class="row-4-small">
            <span class="key">Spannweite</span>
            <span class="key">Bauwerkshöhe</span>
            <span class="key">Grundfläche</span>
            <span class="key">Bauvolumen</span>

            <pfm-numberinput placeholder="Spannweite"   class="val" [(value)]="buildingSpan"   unit="m"  [invalid]="validate('BUILDING_SPAN')"   ></pfm-numberinput>
            <pfm-numberinput placeholder="Bauwerkshöhe" class="val" [(value)]="buildingHeight" unit="m"  [invalid]="validate('BUILDING_HEIGHT')" ></pfm-numberinput>
            <pfm-numberinput placeholder="Grundfläche"  class="val" [(value)]="floorSpace"     unit="m²" [invalid]="validate('FLOOR_SPACE')"     ></pfm-numberinput>
            <pfm-numberinput placeholder="Bauvolumen"   class="val" [(value)]="buildVolume"    unit="m³" [invalid]="validate('BUILD_VOLUME')"    ></pfm-numberinput>
          </div>

          <div class="space-3"></div>

          <span>Gebäudeklasse</span>

          <div class="row-1">
            <pfm-select placeholder="Wähle eine Gebäudeklasse aus" [values]="metaToKeyValue(metavalues.buildingClass)" class="span-val-small-2" [(value)]="buildingClass" [invalid]="validate('BUILDING_CLASS')"></pfm-select>
          </div>

          <div class="space-3"></div>

          <div class="row-6-small">
            <span class="key hover-expand"><span class="short">BGF</span><span class="full">Brutto-Grundfläche</span></span>
            <span class="key hover-expand"><span class="short">NUF</span><span class="full">Nutzungsfläche</span></span>
            <span class="key hover-expand"><span class="short">BRI</span><span class="full">Brutto-Rauminhalt</span></span>
            <span class="key hover-expand"><span class="short">NRF</span><span class="full">Netto-Raumfläche</span></span>
            <span class="key hover-expand"><span class="short">NRI</span><span class="full">Netto-Rauminhalt</span></span>
            <span class="key hover-expand"><span class="short">BF</span ><span class="full">Bebaute Fläche</span></span>

            <pfm-numberinput class="val" [(value)]="BGF" unit="m²" [invalid]="validate('BGF')"></pfm-numberinput>
            <pfm-numberinput class="val" [(value)]="NUF" unit="m²" [invalid]="validate('NUF')"></pfm-numberinput>
            <pfm-numberinput class="val" [(value)]="BRI" unit="m³" [invalid]="validate('BRI')"></pfm-numberinput>
            <pfm-numberinput class="val" [(value)]="NRF" unit="m²" [invalid]="validate('NRF')"></pfm-numberinput>
            <pfm-numberinput class="val" [(value)]="NRI" unit="m³" [invalid]="validate('NRI')"></pfm-numberinput>
            <pfm-numberinput class="val" [(value)]="BF"  unit="m²" [invalid]="validate('BF')" ></pfm-numberinput>
          </div>

          <div class="separator-dashed"></div>

          <h2>Honorarangaben (Brutto)</h2>

          <div class="row-1">
            <span class="key">Regelwerk</span>
            <pfm-select placeholder="Bitte auswählen" [values]="metaToKeyValue(metavalues.rulebook)" class="val" [(value)]="rulebook" [invalid]="validate('RULEBOOK')" ></pfm-select>
          </div>

          <div class="space-3"></div>

          <div class="row-2">
            <span class="key">Honorarzone</span>
            <span class="key">Honorarstufe</span>
            <pfm-select placeholder="Bitte auswählen" [values]="metaToKeyValue(metavalues.feeBand)"  class="val" [(value)]="feeBand"  [invalid]="validate('FEEBAND')"  ></pfm-select>
            <pfm-select placeholder="Bitte auswählen" [values]="metaToKeyValue(metavalues.feeLevel)" class="val" [(value)]="feeLevel" [invalid]="validate('FEELEVEL')" ></pfm-select>
          </div>

          <div class="space-3"></div>

          <div class="row-1">
            <span class="key">Honorar / HOAI</span>
            <pfm-numberinput placeholder="Geben Sie das Honorar ein" class="val"  [precision]="2" [(value)]="feeHOAI" unit="Euro" [invalid]="validate('FEEHOAI')"></pfm-numberinput>
          </div>

          <div class="separator-dashed"></div>

          <h2>Projektkosten</h2>

          <div class="row-flex">
            <ng-container *ngFor="let v of metavalues.costTypes">
              <pfm-radiobutton class="span" name="costType" [radiovalue]="v.value" [text]="v.description" [(value)]="costType" [invalid]="validate('COST_TYPE')"></pfm-radiobutton>
            </ng-container>
          </div>

          <div class="space-3"></div>

          <div class="row-1">
            <span class="key">KGR 100</span>
            <pfm-numberinput placeholder="Geben Sie KGR 100 ein"    class="val" [precision]="2" [(value)]="KG100"  unit="Euro" [invalid]="validate('KG100')"   ></pfm-numberinput>
          </div>

          <div class="space-3"></div>

          <div class="row-2">
            <span class="key">KGR 300</span>
            <span class="key">KGR 300+400</span>
            <pfm-numberinput placeholder="Geben Sie KGR 300 ein"        class="val" [precision]="2" [(value)]="KG300"     unit="Euro" [invalid]="validate('KG300')"   ></pfm-numberinput>
            <pfm-numberinput placeholder="Geben Sie KGR 300+400 ein"    class="val" [precision]="2" [(value)]="KG300400"  unit="Euro" [invalid]="validate('KG300400')"   ></pfm-numberinput>
          </div>

          <div class="space-3"></div>

          <div class="row-1">
            <span class="key">Gesamtkosten</span>
            <pfm-numberinput placeholder="Geben Sie die Gesamtkosten ein" class="val" [precision]="2" [(value)]="totalCost" unit="Euro" [invalid]="validate('TOTAL_COST')" ></pfm-numberinput>
          </div>

          <div class="separator-dashed"></div>

          <div class="row-1-full">
            <span class="key">Beschreibung (kurz)</span>
            <pfm-textarea [(value)]="descriptionShort" placeholder="Schreiben Sie eine kurze Beschreibung ..." [rows]="8" resize="vertical" [invalid]="validate('DESCRIPTION_SHORT')"></pfm-textarea>
          </div>

          <div class="space-3"></div>

          <div class="row-1-full">
            <span class="key">Beschreibung (lang)</span>
            <pfm-textarea [(value)]="description" placeholder="Schreiben Sie eine lange Beschreibung ..." [rows]="8" resize="vertical" [invalid]="validate('DESCRIPTION')"></pfm-textarea>
          </div>




        </div>
      </pfm-tab>

      <pfm-tab tabIndex="4" tabTitle="Medien">
        <div *ngIf="metavalues !== null" class="tab-content">

          <h2>Bilder</h2>

          <input #fileUpload
                 type="file"
                 style="display: none"
                 accept=".jpeg,.jpg,.png,.gif"
                 [multiple]="true"
                 (change)="onUploadStarted($event)">

          <div class="row-single">
            <pfm-button [icon]="['fat', 'upload']" (click)="fileUpload.click()" type="sub">Bilder hochladen</pfm-button>
          </div>

          <div class="space-3"></div>

          <div class="image-list">

            <ng-container *ngFor="let img of images; let i = index">

              <pfm-imageblob-display *ngIf="!img.uploaded"
                                     [clickable]="false"
                                     [image]="images[i]"
                                     (imageChange)="imageUpdated($event, i)"
                                     mode="uploading"
                                     [actions]="['abort']"
                                     (abortUpload)="deleteImage(img.uuid)"></pfm-imageblob-display>

              <pfm-imageblob-display *ngIf="img.uploaded"
                                     [clickable]="false"
                                     [image]="images[i]"
                                     (imageChange)="imageUpdated($event, i)"
                                     mode="edit"
                                     [actions]="['delete', 'download']"
                                     (deleteImage)="deleteImage(img.uuid)"></pfm-imageblob-display>

            </ng-container>

          </div>

        </div>
      </pfm-tab>

    </pfm-tabs>

    <div style="flex-grow: 1"><!-- ensure that pfm-menubar is at bottom --></div>

    <pfm-menubar>
      <pfm-menubar-button [disabled]="!(selectedTab > 0)"    type="secondary"                                  (btnClick)="selectedTab=selectedTab-1"   >Zurück</pfm-menubar-button>
      <pfm-menubar-button [disabled]="true"                                   [icon]="['fat', 'trash']"                                                 >Projekt löschen</pfm-menubar-button>
      <pfm-menubar-button [disabled]="hasNoPendingChanges()"                  [icon]="['fat', 'floppy-disk']"  (btnClick)="createOrUpdateProject()"     >Speichern</pfm-menubar-button>
      <pfm-menubar-button [disabled]="mode != 'EDIT'"                         [icon]="['fat', 'table-layout']" (btnClick)="showNewestExpose()"          >Zum Layout</pfm-menubar-button>
      <pfm-menubar-button [disabled]="!(selectedTab < 3)"     type="primary"                                   (btnClick)="selectedTab=selectedTab+1"   >Weiter</pfm-menubar-button>
      <pfm-menubar-button *ngIf="selectedTab === 3"                           [icon]="['fat', 'floppy-disk']"  (btnClick)="createOrUpdateProject(true)" >Speichern & Beenden</pfm-menubar-button>
    </pfm-menubar>

  </main>

</frame-default>
