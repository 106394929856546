import {Injectable, Injector} from '@angular/core';
import {Router} from "@angular/router";
import {environment} from "../../environments/environment";
import {JwtHelperService} from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie-service';
import {JWTToken, User} from "../interfaces";
import {ObjectID} from "../interfaces";
import {lastValueFrom, Subject} from 'rxjs';
import {APIService} from "./api.service";

interface JWTClaims {
  userid: ObjectID;
  companyid: ObjectID;
  username: string;
  mail: string;
  v: number;

  exp: number;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  claimCache: [string, JWTClaims]|undefined = undefined;

  user: [ObjectID, User] | null = null;
  userSubject: Subject<User> | null = null;

  private apiService: APIService | null = null;

  constructor(private injector: Injector,
              private router: Router,
              private cookie: CookieService) {

    //

  }

  isAuthenticated(): boolean {
    return ((this.getClaimsFromToken()?.exp ?? 0) * 1000) > new Date().getTime();
  }

  setAuth(accessToken: JWTToken): void {
    this.cookie.set(environment.cookieKey, accessToken, new JwtHelperService().decodeToken(accessToken).exp * 1000, '/', environment.cookieBaseUrl);
    this.user = null;
  }

  clearAuth(): void {
    this.cookie.delete(environment.cookieKey, '/', environment.cookieBaseUrl);
    this.claimCache = undefined;
  }

  getToken(): JWTToken {
    return this.cookie.get(environment.cookieKey) as JWTToken;
  }

  getClaimsFromToken(): JWTClaims | null {
    const tok = this.getToken();
    if (this.claimCache !== undefined && tok === this.claimCache[0]) return this.claimCache[1];

    const claims = new JwtHelperService().decodeToken<JWTClaims>(tok);

    if (claims !== null) this.claimCache = [tok, claims];

    return claims;
  }

  getSelfID(): ObjectID|null {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.userid;
  }

  getSelfCompanyID(): ObjectID|null {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.companyid;
  }

  getSelf(force?: boolean): Promise<User|null> {
    const uid = this.getSelfID();
    if (uid === null) return Promise.resolve(null);

    if (force) this.user = null;

    if (this.user !== null && this.user[0] === uid) {
      return Promise.resolve(this.user[1]);
    }

    if (this.userSubject) {
      return lastValueFrom(this.userSubject);
    }

    const subj = this.userSubject = new Subject<User>();

    const apiService = this.apiService ?? (this.apiService = this.injector.get<APIService>(APIService));

    return apiService.getUser(uid).then((v) => {
      this.user = [uid, v];
      subj.next(v);
      subj.complete();
      this.userSubject = null;
      return v;
    }, (v) => {
      subj.error(v);
      subj.complete();
      this.userSubject = null;
      return v;
    });
  }
}
