import {HttpClient, HttpEventType, HttpHeaders} from '@angular/common/http';
import {lastValueFrom, map} from 'rxjs';
import {SerializedParamCollection, SerializedParamUtil} from "./serializedParameter";
import {AuthenticationService} from "../services/authentication.service";
import {environment} from "../../environments/environment";
import {APIService} from "../services/api.service";

export enum APIRequestMethod {'GET', 'POST', 'PUT', 'DELETE', 'PATCH'}

interface Parameter {
  [_:string]: string|number|boolean|undefined;
}

export interface APIServiceRef {
  http: HttpClient;
  auth: AuthenticationService
  api: APIService
}

export interface APIEventListener {
  uploadProgress?(loaded: number, total: number|undefined): void;
  downloadProgress?(loaded: number, total: number|undefined): void;
  response?(ok: boolean, url: string|null, body: any|null, headers: HttpHeaders, status: number, statusText: string): void;
}

export class APIMethod<T> {

  method: APIRequestMethod;
  uri: string;

  constructor(m: APIRequestMethod, u: string) {
    this.method = m;
    this.uri = u;
  }

  private rstr(): string {
    switch (this.method) {
      case APIRequestMethod.DELETE: return 'DELETE';
      case APIRequestMethod.GET:    return 'GET';
      case APIRequestMethod.POST:   return 'POST';
      case APIRequestMethod.PUT:    return 'PUT';
      case APIRequestMethod.PATCH:  return 'PATCH';
    }
  }

  run(ref: APIServiceRef, params: Parameter, body: any|null, filter?: SerializedParamCollection, lstr?: APIEventListener): Promise<T> {
    let url = this.url(ref, params, filter);


    const prom = ref.http.request<T>(this.rstr(), url,
      {
        body: body,
        observe: 'events',
        headers: {"Authorization": "Bearer " +  ref.auth.getToken()}
      }).
    pipe(map(event => {
      if (event.type === HttpEventType.UploadProgress) {

        if (lstr?.uploadProgress) lstr.uploadProgress(event.loaded, event.total);
        return undefined;

      } else if (event.type === HttpEventType.DownloadProgress) {

        if (lstr?.downloadProgress) lstr.downloadProgress(event.loaded, event.total);
        return undefined;

      } else if (event.type === HttpEventType.Response) {

        if (lstr?.response) lstr.response(event.ok, event.url, event.body, event.headers, event.status, event.statusText);
        return event.body!;

      } else {

        return undefined;

      }
    }));

    return lastValueFrom(prom).then(p => p!);

  }

  url(ref: APIServiceRef, params: Parameter, filter?: SerializedParamCollection): string {
    let urlbase = this.uri;
    let urlext = '';

    let hasParams = false;
    if (params !== null) {
      for (const key of Object.keys(params)) {
        const val = params[key];
        if (val === undefined) continue;
        if (key.startsWith(':') && urlbase.includes('{'+key.substring(1)+'}')) {
          urlbase = urlbase.replace('{'+key.substring(1)+'}', `${val}`);
          continue;
        }

        urlext += hasParams ? '&' : '?';
        urlext += key + '=' + encodeURIComponent(val);
        hasParams = true;
      }
    }
    if (filter !== undefined) {
      const fp = SerializedParamUtil.ToAPIParams(filter);
      for (const key of Object.keys(fp)) {
        urlext += hasParams ? '&' : '?';
        urlext += key + '=' + encodeURIComponent(fp[key]);
        hasParams = true;
      }
    }

    return environment.apiBaseUrl+urlbase+urlext;
  }

}
