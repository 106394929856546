import {Component, HostListener, OnInit} from '@angular/core';
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {showAPIError} from "../../utils/api";
import {
  CreateNewProject,
  EnumMetaValue, isValidationError, ObjectID,
  Project,
  ProjectEnums
} from "../../interfaces";
import {ValidationRef} from "../../interfaces/validation";
import {Expose} from "../../interfaces/expose";
import {environment} from "../../../environments/environment";
import {deepclone} from "../../utils/angular";
import {TemplateImageConfig} from "../../interfaces/templateConfig";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'pfm-expose-editcreate',
  templateUrl: './expose-editcreate.component.html',
  styleUrls: ['./expose-editcreate.component.scss']
})
export class ExposeEditCreateComponent implements OnInit {

  mode: 'CREATE' | 'EDIT' = 'CREATE';
  editExposeID: ObjectID | null = null;
  serverData: Expose|null = null;

  status: 'loading' | 'error' | 'content' = 'loading';

  metavalues: ProjectEnums | null = null;
  allprojects: Project[] | null = null;

  selectedTemplate: string   | null = null;
  selectedProject:  ObjectID | null = null;

  name: string = '';

  imagesConfig: { [ _p: string ]: TemplateImageConfig|null } = {};
  textsConfig:  { [ _p: string ]: string|null } = {};

  selectedTab = 0;

  _serverData: any|null = null;

  validationErrors: Set<ValidationRef> = new Set<ValidationRef>();

  downloadExpose: Expose|null = null;

  constructor(private api: APIService,
              private meta: MetaService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data['mode'] === 'CREATE') {
      this.editExposeID = null;
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchData(this.editExposeID).then(() => {});
    } else if (this.activatedRoute.snapshot.data['mode'] === 'EDIT') {
      this.editExposeID = this.activatedRoute.snapshot.params['expid'];
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchData(this.editExposeID).then(() => {});
    } else {
      this.status = 'error';
      this.notification.error('Fehler', 'Komponente ist in einem invaliden Zustand');
    }
  }

  @HostListener('window:beforeunload')
  hasNoPendingChanges = (): boolean => {
    return JSON.stringify(this.getExposeData()) === JSON.stringify(this._serverData);
  }

  async fetchData(exposeid: ObjectID|null) {
    try {
      this.status = 'loading';

      this.metavalues = await this.meta.getProjectEnums();
      this.allprojects = (await this.api.listProjects("@start", null)).projects.filter(p => p.classification === null || p.classification === 'INTERN'||  p.classification === 'EXTERN');

      const qpProj = this.activatedRoute.snapshot.queryParams['project'];
      if (qpProj !== undefined) {
        if (this.allprojects.find(p => p.id === qpProj) != undefined) {
          this.selectedProject = qpProj;
        }
      }

      const qpTemplate = this.activatedRoute.snapshot.queryParams['template'];
      if (qpTemplate !== undefined) {
        if (this.metavalues.exposeTemplate.find(p => p.value === qpTemplate) != undefined) {
          this.selectedTemplate = qpTemplate;
        }
      }

      if (exposeid !== null) {
        const expose = await this.api.getExpose(exposeid);
        await this.setAllValues(expose);
      }

      this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      this.status = 'error';
    }
  }

  async setAllValues(expose: Expose) {

    this.serverData = deepclone(expose);

    this.selectedTemplate = expose.template;
    this.selectedProject  = expose.projectID;
    this.name             = expose.name;
    this.imagesConfig     = expose.templateConfig.images;
    this.textsConfig      = expose.templateConfig.texts;

    this._serverData = this.getExposeData();
  }

  async createOrUpdateExpose(navigate = false) {
    if (this.mode === 'CREATE') await this.createExpose(navigate);
    if (this.mode === 'EDIT')   await this.updateExpose(navigate);
  }

  async createExpose(navigate: boolean) {
    this.validationErrors.clear();

    if (this.name             === '')   { this.validationErrors.add('EXPOSE_NAME');  }
    if (this.selectedProject  === null) { this.validationErrors.add('PROJECT_ID');   }
    if (this.selectedTemplate === null) { this.validationErrors.add('TEMPLATE_KEY'); }

    if (this.validationErrors.size > 0) return;

    try {
      let data = await this.api.createExpose(this.name, this.selectedProject!, this.selectedTemplate!, {images:this.imagesConfig, texts: this.textsConfig});

      if (navigate) {
        await this.router.navigate(['/exposes/list']);
      } else {
        await this.setAllValues(data);
        this._serverData = this.getExposeData();
        this.notification.success("Exposè gespeichert!",'')
      }

    } catch (err) {
      if (isValidationError(err, 'EXPOSE_VALIDATION_FAILED')) {
        this.notification.error('Exposè konnten nicht erstellt werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference as ValidationRef);
      } else {
        showAPIError(this.notification, 'Exposè konnten nicht erstellt werden', err);
      }
    }
  }

  async updateExpose(navigate: boolean) {
    if (this.editExposeID === null) return;

    this.validationErrors.clear();

    if (this.name             === '')   { this.validationErrors.add('EXPOSE_NAME');  }
    if (this.selectedProject  === null) { this.validationErrors.add('PROJECT_ID');   }
    if (this.selectedTemplate === null) { this.validationErrors.add('TEMPLATE_KEY'); }

    if (this.validationErrors.size > 0) return;

    try {
      let data = await this.api.updateExpose(this.editExposeID, this.name, this.selectedProject!, this.selectedTemplate!, {images:this.imagesConfig, texts: this.textsConfig});

      if (navigate) {
        await this.router.navigate(['/exposes/list']);
      } else {
        await this.setAllValues(data);
        this._serverData = this.getExposeData();
        this.notification.success("Exposè gespeichert!",'')
      }

    } catch (err) {
      if (isValidationError(err, 'EXPOSE_VALIDATION_FAILED')) {
        this.notification.error('Exposè konnten nicht bearbeitet werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference);
      } else {
        showAPIError(this.notification, 'Exposè konnten nicht bearbeitet werden', err);
      }
    }
  }

  getExposeData() {
    return {
      'id':       this.editExposeID,
      'name':     this.name,
      'project':  this.selectedProject,
      'template': this.selectedTemplate,
      'config':   {images:this.imagesConfig, texts: this.textsConfig}
    }
  }

  projectsToKV(v: Project[]) {
    return v.map(p => ({key: p.id, value: p.projectName}));
  }

  metaToKeyValue(v: EnumMetaValue<string, string>[]) {
    return v.map(p => ({key: p.value, value: p.description}));
  }

  metaToKeyValueExt(vSelected: string|null, vAll: EnumMetaValue<string, string>[], vLim: EnumMetaValue<string, string>[]) {
    if (vSelected !== null && !vLim.find(p => p.value === vSelected) && vAll.find(p => p.value === vSelected)) {
      // a enumValue is selected that only exists in [vAll], add it to the select values for now
      return vLim.map(p => ({key: p.value, value: p.description})).concat(({key: vSelected, value: vAll.find(p => p.value === vSelected)!.description}));
    } else {
      return vLim.map(p => ({key: p.value, value: p.description}));
    }
  }

  validate(...args: ValidationRef[]): boolean|undefined {
    return args.find(p => this.validationErrors.has(p))  ? true : undefined;
  }

  selectProject(item: Project) {
    this.selectedProject = item.id;
  }
}
