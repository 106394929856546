<frame-default>
  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;"
                     [crumbs]="
                     [
                      {text:'Seiten'},
                      {text:'Firma', link: '/company/settings'},
                      {text:'Nutzerverwaltung', link: '/users/list', active: true},
                     ]" ></pfm-breadcrumbs>

    <div class="statistics">
      <pfm-statistics-text icon="file-pen" title="Benutzer insgesamt" [loading]="totalUserCount === null" [value]="totalUserCount ?? '...'"></pfm-statistics-text>
      <div class="spacer"></div>
      <pfm-button routerLink="/users/create" icon="plus">Nutzer anlegen</pfm-button>
    </div>

    <pfm-card header="Benutzer">

      <pfm-table [datasource]="users">

        <pfm-table-col header="ID">
          <ng-template [ctxType]="{item:users[0]!}" let-item="item">{{item.id | shortid}}</ng-template>
        </pfm-table-col>

        <pfm-table-col header="Benutzername">
          <ng-template [ctxType]="{item:users[0]!}" let-item="item">{{item.username}}</ng-template>
        </pfm-table-col>

        <pfm-table-col header="Email">
          <ng-template [ctxType]="{item:users[0]!}" let-item="item">{{item.email}}</ng-template>
        </pfm-table-col>

        <pfm-table-col header="">
          <ng-template [ctxType]="{item:users[0]!}" let-item="item">
            <pfm-context-menu>
              <pfm-context-menu-item *ngIf="self !== null && (self.admin || self.companyOwner)" [icon]="['fat', 'pen']" (onClick)="editUser(item)"   >Bearbeiten</pfm-context-menu-item>
              <pfm-context-menu-item *ngIf="self !== null && (self.admin || self.companyOwner)" [icon]="['fat', 'trash-can']"   (onClick)="deleteUser(item)" >Löschen</pfm-context-menu-item>
            </pfm-context-menu>
          </ng-template>
        </pfm-table-col>



      </pfm-table>

    </pfm-card>
  </main>
</frame-default>
