import {DateUtils} from "../utils/date";

// { readonly __tag: unique symbol } is newType(T) equivalent in typescript
// prevents implicit type conversion
// see https://kubyshkin.name/posts/newtype-in-typescript/

export type RFC3339 = string;

export type Seconds = number;

export type ObjectID = string & { readonly __tag: unique symbol };

export type TemplateKey = string;

export type TemplateIdent = string;

export type CursorToken = '@start'|'@end'|(string & { readonly __tag: unique symbol });

export type JWTToken = string & { readonly __tag: unique symbol };

export function rfc3339ToNullDate(v: RFC3339|null): Date|null {
  if (v === null) return null;
  return DateUtils.parseRFC3339(v);
}

