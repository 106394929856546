
<div *ngIf="showDots && contentChildren.length > 0" class="ellipsis" [class.open]="modalid !== null" (click)="onShow($event)" #iconComp>
  <fa-icon icon="ellipsis-vertical"></fa-icon>
</div>


<ng-template #template>

  <div class="menu">
    <ng-content></ng-content>
  </div>

</ng-template>
