import { Injectable } from '@angular/core';
import {APIService} from "./api.service";
import {JWTToken, ProjectEnums} from "../interfaces";
import {lastValueFrom, Subject} from "rxjs";
import {AuthenticationService} from "./authentication.service";

@Injectable({providedIn: 'root'})
export class MetaService {

  private projectEnumsCache:     Map<JWTToken, ProjectEnums>          = new Map<JWTToken, ProjectEnums>();
  private projectEnumsSubjects$: Map<JWTToken, Subject<ProjectEnums>> = new Map<JWTToken, Subject<ProjectEnums>>();

  constructor(private api: APIService, private auth: AuthenticationService) { }

  getProjectEnumsImmediately(): ProjectEnums|null {
    const tok = this.auth.getToken();
    if (this.projectEnumsCache.has(tok)) return this.projectEnumsCache.get(tok)!;
    return null;
  }

  getProjectEnums(): Promise<ProjectEnums> {

    const tok = this.auth.getToken();

    if (this.projectEnumsCache.has(tok)) return Promise.resolve(this.projectEnumsCache.get(tok)!);

    if (this.projectEnumsSubjects$.has(tok)) return lastValueFrom(this.projectEnumsSubjects$.get(tok)!);

    const subj = new Subject<ProjectEnums>();

    this.projectEnumsSubjects$.set(tok, subj);

    return this.api.getProjectEnums().then((v) =>
    {
      this.projectEnumsCache.set(tok, v);
      subj.next(v);
      subj.complete();
      this.projectEnumsSubjects$.delete(tok);
      return v;
    }, (v) =>
    {
      subj.error(v);
      subj.complete();
      throw v;
    });
  }

}
