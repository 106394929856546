
<ng-template #actionbtns>

  <ng-container *ngIf="actions.length > 0">

    <span class="seperator"></span>

    <div class="actionbuttons">

      <pfm-button *ngIf="actions.includes('abort')"
                  type="sub-trans"
                  [icon]="['fat', 'xmark']"
                  (btnClick)="abortUpload.emit()">Abbrechen</pfm-button>

      <pfm-button *ngIf="image !== null && image.id !== null && actions.includes('delete')"
                  type="sub-trans"
                  [icon]="['fat', 'trash']"
                  (btnClick)="deleteImage.emit()">Löschen</pfm-button>

      <pfm-button *ngIf="image !== null && image.id !== null && actions.includes('download')"
                  type="sub-trans"
                  [icon]="['fat', 'download']"
                  [anchorLink]="imageSource(image.id)"
                  anchorTarget="_blank">Herunterladen</pfm-button>

      <pfm-button *ngIf="image !== null && image.id !== null && actions.includes('save') && changed && !isSaving"
                  type="sub-trans"
                  [icon]="['fat', 'save']"
                  (btnClick)="saveImage.emit(); changed = false;">Speichern</pfm-button>

      <pfm-button *ngIf="image !== null && image.id !== null && actions.includes('save') && isSaving"
                  type="sub-trans"
                  [icon]="['fat', 'save']"
                  [loading]="true">Speichern</pfm-button>

    </div>

  </ng-container>

</ng-template>

<div *ngIf="mode === 'upload'" class="imgbox mode-{{mode}}" [class.clickable]="clickable" (click)="onClicked()">

  <input #fileUpload
         type="file"
         style="display: none"
         accept=".jpeg,.jpg,.png,.gif"
         [multiple]="false"
         (change)="onUploadStarted($event)">

  <div class="image-container upload-container" (click)="fileUpload.click()">
    <fa-icon  class="icon"  [icon]="['fas', 'upload']"></fa-icon>
  </div>

  <span class="seperator"></span>

  <span class="filename">&nbsp;</span>
  <span class="filesize">&nbsp;</span>

  <ng-container>

    <pfm-readonly-input class="copyright" [value]="''" placeholder="Bildrechte"        ></pfm-readonly-input>
    <pfm-readonly-input class="notes"     [value]="''" placeholder="Interne Vermerke"  ></pfm-readonly-input>
    <pfm-readonly-input class="caption"   [value]="''" placeholder="Bildunterschrift"  ></pfm-readonly-input>

  </ng-container>

  <ng-container *ngTemplateOutlet="actionbtns"></ng-container>

</div>

<div *ngIf="image !== null && mode !== 'upload'" class="imgbox mode-{{mode}}" [class.clickable]="clickable" (click)="onClicked()">

  <div class="image-container">
    <img     *ngIf="image.id !== null" class="image" [ngSrc]="thumbSource(image.id)" alt="image" [fill]="true">
    <fa-icon *ngIf="image.id === null" class="icon"  [icon]="['fas', 'loader']" animation="spin"></fa-icon>
  </div>

  <span class="seperator"></span>

  <span *ngIf="image.data.filename !== ''" class="filename">{{image.data.filename}}</span>
  <span *ngIf="image.data.filename === ''" class="filename">???</span>

  <span class="filesize">{{image.filesize | filesize}}</span>

  <ng-container *ngIf="mode === 'readonly'">

    <pfm-readonly-input class="copyright" [value]="image.data.copyright" placeholder="Bildrechte"        ></pfm-readonly-input>
    <pfm-readonly-input class="notes"     [value]="image.data.notes"     placeholder="Interne Vermerke"  ></pfm-readonly-input>
    <pfm-readonly-input class="caption"   [value]="image.data.caption"   placeholder="Bildunterschrift"  ></pfm-readonly-input>

  </ng-container>

  <ng-container *ngIf="mode === 'uploading'">

    <pfm-input class="copyright" [value]="image.data.copyright" placeholder="Bildrechte"        ></pfm-input>
    <pfm-input class="notes"     [value]="image.data.notes"     placeholder="Interne Vermerke"  ></pfm-input>
    <pfm-input class="caption"   [value]="image.data.caption"   placeholder="Bildunterschrift"  ></pfm-input>

  </ng-container>

  <ng-container *ngIf="mode === 'edit'">

    <pfm-input class="copyright" [value]="image.data.copyright" (valueChange)="image.data.copyright = $event; imageChange.emit(image); changed = true" placeholder="Bildrechte"       ></pfm-input>
    <pfm-input class="notes"     [value]="image.data.notes"     (valueChange)="image.data.notes     = $event; imageChange.emit(image); changed = true" placeholder="Interne Vermerke" ></pfm-input>
    <pfm-input class="caption"   [value]="image.data.caption"   (valueChange)="image.data.caption   = $event; imageChange.emit(image); changed = true" placeholder="Bildunterschrift" ></pfm-input>

  </ng-container>

  <ng-container *ngTemplateOutlet="actionbtns"></ng-container>

</div>
