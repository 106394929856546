import {Component, OnInit} from '@angular/core';
import {Address, Contact, CursorToken, NewAddress} from "../../interfaces";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {manipulateURLComponents} from "../../utils/url";

@Component({
  selector: 'pfm-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit{

  contacts: Contact[] =[];

  status: 'loading'|'error'|'content' = 'content';

  nextToken: CursorToken = "@start";
  loading: boolean = true;
  filters: SerializedParamCollection = {
    search: { active: null, default: null, type: 'string' },
  };

  contactTotalCount: number|null = null;

  constructor(private api: APIService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  rowClicked(event: Contact) {
    void this.router.navigate(['contacts/' + event.id + '/edit']);
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await Promise.all([
      this.fetchData(true),
      this.fetchCount(),
    ])
  }

  async fetchData(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;
      if (reset) this.status = 'loading';

      const data = await this.api.listContacts(this.nextToken, 48, this.filters);

      if (reset) {
        this.contacts = data.contacts;
      } else {
        this.contacts = [...this.contacts, ...data.contacts];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

      if (reset) this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      if (reset) this.status = 'error';
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['contacts', 'list'], this.filters));
  }

  async fetchCount() {
    try {

      this.contactTotalCount = null;
      const data = await this.api.getContactsCount(this.filters);
      this.contactTotalCount = data.count;

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  async editContact(item: Contact) {
    await this.router.navigate(['/', 'contacts', item.id, 'edit']);
  }

  deleteContact(item: Contact) {

    this.modal.openDialog({
      title: 'Kontakt löschen',
      message: 'Das Löschen kann nicht rückgängig gemacht werden.',
      type: 'danger',

      onSubmit: async () => {
        try {
          await this.api.deleteContact(item.id);
          this.contacts = this.contacts.filter(c => c.id != item.id);
          this.notification.success('Kontakt gelöscht', item.name);
        } catch (err) {
          showAPIError(this.notification, 'Fehler beim Löschen', err);
        }
      }
    })
  }

  formatTel(item: Contact) {
    if (item.phone != "" && item.companyPhone != "") return `${item.phone}`;
    if (item.phone != "") return item.phone;
    if (item.companyPhone != "") return item.companyPhone;
    return null;
  }

  formatMail(item: Contact) {
    if (item.mail != "" && item.companyMail != "") return `${item.mail}`;
    if (item.mail != "") return item.mail;
    if (item.companyMail != "") return item.companyMail;
    return null;
  }

    protected readonly navigator = navigator;
}
