
<label for="switch-{{uniq}}">
  <input type="checkbox"
         id="switch-{{uniq}}"
         role="switch"
         [ngModel]="value"
         (ngModelChange)="value = $event; valueChange.emit($event)"
         [disabled]="disabled">
  <span>{{text}}</span>
</label>
