import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'strToNStr', pure:true })
export class StrToNStrPipe implements PipeTransform {

  transform(value: string): string|null {
    return (value==='') ? null : value;
  }

}
