import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';
import {ObjectID, TemplateIdent} from "../../interfaces";
import {environment} from "../../../environments/environment";
import {APIService} from "../../services/api.service";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {TemplateImageConfig} from "../../interfaces/templateConfig";

@Component({
  selector: 'pfm-template-block',
  templateUrl: './template-block.component.html',
  styleUrls: ['./template-block.component.scss'],
})
export class TemplateBlockComponent {

  @Input() type: 'generic-text'|'image-insert'|'text-insert' =  'generic-text';
  @Input() position: [number, number, number, number] = [0, 0, 0, 0];
  @Input() ident: TemplateIdent|null = null;

  @Input()  images: {[_p:TemplateIdent]:TemplateImageConfig|null}|undefined = undefined;
  @Output() imagesChange: EventEmitter<{[_p:TemplateIdent]:TemplateImageConfig|null}> = new EventEmitter<{[_p:TemplateIdent]:TemplateImageConfig|null}>();

  @Input()  texts:  {[_p:TemplateIdent]:string|null}|undefined = undefined;
  @Output() textsChange: EventEmitter<{[_p:TemplateIdent]:string|null}> = new EventEmitter<{[_p:TemplateIdent]:string|null}>();

  @Output() editText:  EventEmitter<{ident: TemplateIdent, startValue: string}> = new EventEmitter<{ident: TemplateIdent, startValue: string}>();
  @Output() editImage: EventEmitter<{ident: TemplateIdent, startValue: TemplateImageConfig|null}> = new EventEmitter<{ident: TemplateIdent, startValue: TemplateImageConfig|null}>();
  @Output() cropImage: EventEmitter<{ident: TemplateIdent, currValue: TemplateImageConfig, currURL: string, imgwidth: number, imgheight: number}> = new EventEmitter<{ident: TemplateIdent, currValue: TemplateImageConfig, currURL: string, imgwidth: number, imgheight: number}>();

  constructor(private api: APIService,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  pix_x(mm: number): string {
    if (mm < 0) return this.pix_x(210.0 + mm);
    return `${((mm * 100) / 210.0)}%`;
  }

  pix_y(mm: number): string {
    if (mm < 0) return this.pix_y(297.0 + mm);
    return `${((mm * 100) / 297.0)}%`;
  }

  bgimg(ident: string, thumb: boolean, clipped: boolean): string {

    const baseURL = `${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID()}/images`;

    if (this.images !== undefined && this.images[ident] !== undefined) {

      const v = this.images[ident];
      if (v !== null) {
        let extraParam = "";
        if (clipped && v.cropX !== null && v.cropY !== null && v.cropWidth !== null && v.cropHeight !== null) {
          extraParam = `&cropX=${v.cropX}&cropY=${v.cropY}&cropW=${v.cropWidth}&cropH=${v.cropHeight}`;
        }

        let thumbSection = "";
        if (thumb) thumbSection = `/thumb/${1024}`;

        return `${baseURL}/${v.image}${thumbSection}?xx-bearer-token=@${this.auth.getToken()}${extraParam}`;
      }
    }

    return '';
  }

  imgtxt(ident: string, short: boolean = false): string {
    if (this.images === undefined || this.images[ident] === undefined || this.images[ident] === null)
      return short ? '...' : "Hier klicken um Bild einzufügen";
    return '';
  }

  txttxt(ident: string, short: boolean = false, raw: boolean = false): string {
    if (this.texts === undefined || this.texts[ident] === undefined || this.texts[ident] === null || this.texts[ident] === '')
      return raw ? '' : short ? '...' : "Hier klicken um Freitext einzufügen";
    return this.texts[ident]!;
  }

  gentexttxt(): string {
    return '(text)'
  }

  isImageSet(ident: TemplateIdent) {
    return (this.images !== undefined && this.images[ident] !== undefined && this.images[ident] !== null);
  }

  isTextSet(ident: TemplateIdent) {
    return (this.texts !== undefined && this.texts[ident] !== undefined && this.texts[ident] !== null);
  }

  textBlockClick(ident: TemplateIdent) {
    this.editText.emit({ident: ident, startValue: this.txttxt(ident, false, true)})
  }

  imageBlockClick(ident: TemplateIdent) {
    if (this.images !== undefined && this.images[ident] !== undefined && this.images[ident] !== null) {
      this.editImage.emit({ident: ident, startValue: this.images[ident]});
    } else {
      this.editImage.emit({ident: ident, startValue: null});
    }
  }

  editImageClick(ident: TemplateIdent) {
    if (this.images !== undefined && this.images[ident] !== undefined && this.images[ident] !== null) {
      this.editImage.emit({ident: ident, startValue: this.images[ident]});
    } else {
      this.editImage.emit({ident: ident, startValue: null});
    }
  }

  cropImageClick(ident: TemplateIdent) {
    this.cropImage.emit({
      ident: ident,
      currValue: this.images![ident]!,
      currURL: this.bgimg(ident, false, false),
      imgwidth: this.position[2],
      imgheight: this.position[3],
    });
  }

  deleteImageClick(ident: TemplateIdent) {
    if (this.images === undefined) this.images = {};
    this.images[ident] = null
    this.imagesChange.emit(this.images);
  }
}
