
<input #comp
       [ngModel]="value"
       (ngModelChange)="value = $event; valueChange.emit($event)"
       [ngStyle]="{'width.px': compWidth}"
       [disabled]="disabled || autoFillDisabledOverride"
       [readonly]="readonly"
       [type]="type"
       [placeholder]="placeholder"
       [attr.aria-invalid]="invalid"
       [attr.name]="preventAutofill ? uuidName : ''"
       [autocomplete]="preventAutofill ? (type === 'password' ? 'new-password' : uuidAutocomplete) : ''"
       [required]="required"
       (keyup.enter)="onEnterUp.emit($event)"
       (input)="resize()">

<span #hiddenText1 class="hiddenText">{{comp.value}}{{extraSpaceStr}}</span>
<span #hiddenText2 class="hiddenText">{{placeholder}}</span>
