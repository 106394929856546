import {Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'pfm-toggle-value',
  template: '<ng-template #contentTemplate><ng-content></ng-content></ng-template>',
  styles: []
})
export class ToggleValueComponent {
  @ViewChild('contentTemplate', { static: true }) contentTemplate!: TemplateRef<any>;
}
