
<div class="tab-control">

  <div class="tab-header-list">
    <div *ngFor="let tab of tabs; index as idx" (click)="selectTab(tab, idx)" class="tab-header" [class.active]="selectedIndex === idx">
      <span class="index">{{tab.index}}</span>
      <span class="title">{{tab.title}}</span>
    </div>
  </div>

  <div class="separator"></div>

  <ng-container *ngFor="let tab of tabs; index as idx">
      <div class="tab-body" [class.visible]="idx === selectedIndex">
        <ng-container *ngTemplateOutlet="(tab.contentTemplate)"></ng-container>
      </div>
  </ng-container>
</div>

