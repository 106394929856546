import {Component, Input} from '@angular/core';

@Component({
  selector: 'pfm-readonly-input',
  templateUrl: './readonly-input.component.html',
  styleUrls: ['./readonly-input.component.scss']
})
export class ReadonlyInputComponent {
  @Input() value: string = '';

  @Input() invalid: boolean|undefined = undefined;
  @Input() placeholder: string = '';

}
