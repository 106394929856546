<frame-login>
  <main>

    <div class="header">
      <img ngSrc="assets/logo.png" class="logo" height="1024" width="1024" alt="logo"/>
      <h2 class="title">isiproject</h2>
    </div>

    <h1>Erstellen sie einen Account.</h1>
    <span *ngIf="errorTexts.length === 0" class="login-text">
        Wir würden uns freuen, Sie an Bord zu haben.
        Schließen Sie sich über 20 Kunden in ganz
        Deutschland an und steigern Sie die Produktivität
        mit unserem Baumanagement-Tool.
    </span>

    <div *ngIf="errorTexts.length > 0" class="alert-box">
      <pfm-alert *ngFor="let v of errorTexts">{{v}}</pfm-alert>
    </div>

    <label>Username<span class="required">*</span></label>
    <pfm-input [(value)]="username" [autoTrim]="true" placeholder="Username" [invalid]="isInvalid('username')" (keyup.enter)="emailInput.focus()"></pfm-input>

    <label>E-Mail<span class="required">*</span></label>
    <pfm-input #emailInput [(value)]="email" [autoTrim]="true" placeholder="E-Mail" [invalid]="isInvalid('email')" (keyup.enter)="companyInput.focus()"></pfm-input>


    <label>Firma<span class="required">*</span></label>
    <pfm-input #companyInput [(value)]="company" placeholder="Ihr Firmenname" [invalid]="isInvalid('company')" (keyup.enter)="inputPassword1.focus()"></pfm-input>

    <label>Passwort<span class="required">*</span></label>
    <pfm-input #inputPassword1 [(value)]="password1" [autoTrim]="false" type="password" [showPasswordEye]="true" placeholder="Erstellen Sie ihr Passwort" [invalid]="isInvalid('password1')" (keyup.enter)="inputPassword2.focus()"></pfm-input>

    <label>Passwort wiederholen<span class="required">*</span></label>
    <pfm-input #inputPassword2 [(value)]="password2" [autoTrim]="false" type="password" [showPasswordEye]="true" placeholder="Wiederholen Sie ihr Passwort" [invalid]="isInvalid('password2')" (keyup.enter)="signUp()"></pfm-input>

    <pfm-button [loading]="loading" (btnClick)="signUp()" type="primary">Registrieren</pfm-button>

    <span class="infotext">Sie haben bereits einen Account? <a [routerLink]="'/login'">Hier anmelden.</a></span>

  </main>
</frame-login>
