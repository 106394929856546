<div class="container">
  <div class="info-container">
    <div>
      <h2>{{title}}</h2>
      <span class="subtitle">{{text}}</span>
    </div>
    <pfm-button *ngIf="buttonLabel !== null" [routerLink]="buttonRouterLink" icon="plus" type="sub" style="align-self: start; margin-top: 1.5rem;">{{buttonLabel}}</pfm-button>
  </div>
  <div class="icon-container">
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <div class="circle"></div>
    <fa-icon *ngIf="icon" [icon]="icon" class="icon" size="5x" style="z-index: 100"></fa-icon>
  </div>
</div>
