import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'pfm-dateinput',
  templateUrl: './dateinput.component.html',
  styleUrls: ['./dateinput.component.scss']
})
export class DateinputComponent {
  @Input('value') inValue: Date|null = null;
  @Output('valueChange') inValueChange: EventEmitter<Date|null> = new EventEmitter<Date|null>();

  @Output() onEnterUp: EventEmitter<any> = new EventEmitter<any>();

  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() invalid: boolean|undefined = undefined;
  @Input() placeholder: string = '';

  @ViewChild('comp') component!: ElementRef<HTMLInputElement>;

  focus() {
    this.component.nativeElement.focus();
  }

  eventToDate(evt: any) {
    return evt.target.valueAsDate;
  }

  protected readonly console = console;
}
