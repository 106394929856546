import {Component, OnInit} from '@angular/core';
import {APIService} from "../../services/api.service";
import {Company, ObjectID} from "../../interfaces";
import {showAPIError} from "../../utils/api";
import {NotificationService} from "../../services/notification.service";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "../../services/authentication.service";
import {blobToBase64} from "../../utils/blob";

@Component({
  selector: 'pfm-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit{
  companyName: string = '';
  address_name: string = '';
  address_extra: string = '';
  address_street: string = '';
  address_streetNumber: string = '';
  address_zip: string = '';
  address_city: string = '';
  address_country: string = '';
  phoneNumber: string = '';
  faxNumber: string = '';
  mailAddress: string = '';
  website: string = '';

  status: "loading" | "error" | "content"  = 'loading';
  saveLoading: boolean = false;

  newLogo: File|null = null;
  company: Company | null = null;

  constructor(private api: APIService,
              private notification: NotificationService,
              private auth: AuthenticationService) {
  }

  ngOnInit() {
    void this.getCompany()
  }

  async getCompany() {
    try {
      const company = await this.api.getCompany()
      this.company = company
      this.applyData(company);

      this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Firma konnte nicht geladen werden', err);
      this.status = 'error'
    }
  }

  private applyData(company: Company) {
    this.companyName = company.name
    this.address_name = company.address.name;
    this.address_extra = company.address.extra;
    this.address_street = company.address.street;
    this.address_streetNumber = company.address.streetNumber;
    this.address_zip = company.address.zip;
    this.address_city = company.address.city;
    this.address_country = company.address.country;
    this.phoneNumber = company.phoneNumber;
    this.faxNumber = company.faxNumber;
    this.mailAddress = company.mailAddress;
    this.website = company.website;
  }

  async saveCompany() {
    try {
      this.saveLoading = true;

      this.company = await this.api.updateCompany(
        this.companyName,
        this.address_name,
        this.address_extra,
        this.address_street,
        this.address_streetNumber,
        this.address_zip,
        this.address_city,
        this.address_country,
        this.phoneNumber,
        this.faxNumber,
        this.mailAddress,
        this.website,
      );

      this.applyData(this.company);

      if (this.newLogo !== null) {
        const b64 = await blobToBase64(this.newLogo);
        const data = await this.api.uploadCompanyImage(this.newLogo.name, b64, this.newLogo.type, {});
        this.company = await this.api.updateCompanyLogo(data.id);
      }

      this.notification.success('Firma aktualisiert', 'Die Firmendaten wurden erfolgreich geändert');

    } catch (err) {
      showAPIError(this.notification, 'Firma konnte nicht gespeichert werden', err);
    } finally {
      this.saveLoading = false;
    }
  }

  imageSource(blobid: ObjectID|null): string|null {
    if (blobid === null) return null;
    return `${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID()}/images/${blobid}?xx-bearer-token=@${this.auth.getToken()}`;
  }
}
