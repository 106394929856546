import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as uuid from "uuid";

@Component({
  selector: 'pfm-switch',
  templateUrl: './switch.component.html',
  styleUrls: ['./switch.component.scss']
})
export class SwitchComponent {

  uniq = uuid.v4()

  @Input() value: boolean = false;
  @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() text: string = '';
  @Input() disabled: boolean = false;

}
