
<div *ngIf="opened" style="position: absolute; bottom: 0; left: 0; right: 0; top: 0;" (click)="close()"></div>

<div class="container" (blur)="close()">

  <div class="custom-select"
       (click)="onClicked()"
       [class.placeholder]="value === null"
       [class.disabled]="disabled"
       [class.open]="opened"
       [attr.aria-invalid]="invalid">

    <span class="value">{{ (value !== null) ? keyToText(value) : placeholder }}</span>

    <fa-icon *ngIf="!opened &&  (canClear && value !== null)" class="clear" [icon]="['fas', 'xmark']"     (click)="clearSelection()" ></fa-icon>
    <fa-icon *ngIf="!opened && !(canClear && value !== null)" class="caret" [icon]="['fas', 'caret-down']"                           ></fa-icon>
    <fa-icon *ngIf=" opened"                                  class="caret" [icon]="['fas', 'caret-up']"                             ></fa-icon>

  </div>

  <div class="option-box">
    <div [class.hidden]="!opened" class="option-list">

      <pfm-input #searchBox placeholder="Suchen ..." [(value)]="searchFilter" [autoTrim]="false" [preIcon]="['fas', 'magnifying-glass']" style="margin-bottom: 1rem;"></pfm-input>

      <div class="seperator"></div>

      <div class="option"
           *ngFor="let kv of filteredValues"
           [class.selected]="kv.key === value"
           (click)="select(kv.key)">{{kv.value}}</div>

    </div>
  </div>

</div>
