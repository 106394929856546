import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NotificationService, PFMNotification} from "../../services/notification.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss']
})
export class NotificationContainerComponent implements OnInit, OnDestroy {

  visibleNotifications: PFMNotification[] = [];
  _notifSubscription: Subscription|null = null;

  @Input() marginTop = '0px';

  constructor(private _notificationSvc: NotificationService) { }

  private _addNotification(notification: PFMNotification) {
    this.visibleNotifications.push(notification);

    if (notification.timeout !== null) setTimeout(() => this.close(notification), notification.timeout);
  }

  ngOnInit() {
    this._notifSubscription = this._notificationSvc.getObservable().subscribe(notification => this._addNotification(notification));
  }

  ngOnDestroy() {
    this._notifSubscription?.unsubscribe();
    this._notifSubscription = null;
  }

  close(v: PFMNotification) {
    this.visibleNotifications = this.visibleNotifications.filter(p => p.id !== v.id);
  }
}
