
<div class="bread">

  <div class="crumblist">
    <ng-container *ngFor="let crumb of crumbs; index as i">
      <span class="sep" *ngIf="i != 0">/</span>
      <a    *ngIf="crumb.link !== undefined" class="link" [class.active]="crumb.active ?? false" [routerLink]="crumb.link">{{crumb.text}}</a>
      <span *ngIf="crumb.link === undefined" class="link" [class.active]="crumb.active ?? false"                          >{{crumb.text}}</span>
    </ng-container>
  </div>

  <a *ngIf="back !== null" class="backlink" [routerLink]="back"><fa-icon icon="arrow-left"></fa-icon> Zurück</a>

</div>
