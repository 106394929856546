
<textarea [ngModel]="value"
          (ngModelChange)="value = $event; valueChange.emit($event)"
          [disabled]="disabled"
          [readonly]="readonly"
          [placeholder]="placeholder"
          [attr.aria-invalid]="invalid"
          [rows]="rows"
          class="resize-{{resize}}"
          [class.fullInnerHeight]="fullInnerHeight"
          (keyup.enter)="onEnterUp.emit($event)">
</textarea>
