import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {LoginFrameComponent} from "./frames/login-frame/login-frame.component";
import {DefaultFrameComponent} from "./frames/default-frame/default-frame.component";
import { CastDatePipe } from './pipes/cast-date.pipe';
import { CastNumberPipe } from './pipes/cast-number.pipe';
import { BooleanNotPipe } from './pipes/boolean-not';
import {CastStringPipe } from './pipes/cast-string.pipe';
import { CastNumberArrayPipe } from './pipes/cast-numberarray.pipe';
import { TrustHTMLPipe } from './pipes/trust-html.pipe';
import { TrustResourceURLPipe } from './pipes/trust-resourceurl.pipe';
import {TrustScriptPipe } from './pipes/trust-script.pipe';
import { ShortIDPipe } from './pipes/shortid';
import { SuffixPipe } from './pipes/suffix';
import { DisplayDatePipe } from './pipes/displaydate.pipe';
import { TrustURLPipe } from './pipes/trust-url.pipe';
import {CastStringArrayPipe} from "./pipes/cast-stringarray.pipe";
import {NumberArrayPipe} from "./pipes/number-array.pipe";
import {TrustStylePipe} from "./pipes/trust-style.pipe";
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import {LoginComponent} from "./pages/login/login.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { HomeComponent } from './pages/home/home.component';
import {HttpClientModule} from "@angular/common/http";
import { CardComponent } from './components/card/card.component';
import { ThemeChangerBtnComponent } from './components/theme-changer-btn/theme-changer-btn.component';
import { ButtonComponent } from './components/button/button.component';
import { SwitchComponent } from './components/switch/switch.component';
import { InputComponent } from './components/input/input.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { SelectComponent } from './components/select/select.component';
import { RadiobuttonComponent } from './components/radiobutton/radiobutton.component';
import { ControlsDemoComponent } from './pages/controls-demo/controls-demo.component';
import { AlertComponent } from './components/alert/alert.component';
import {NgOptimizedImage} from "@angular/common";
import { ProjectEditCreateComponent } from './pages/project-editcreate/project-editcreate.component';
import { ProjectListComponent } from './pages/project-list/project-list.component';
import { ExposeEditCreateComponent } from './pages/expose-editcreate/expose-editcreate.component';
import { ExposeListComponent } from './pages/expose-list/expose-list.component';
import { PortfolioEditCreateComponent } from './pages/portfolio-editcreate/portfolio-editcreate.component';
import { PortfolioListComponent } from './pages/portfolio-list/portfolio-list.component';
import { ProjectlistEditCreateComponent } from './pages/projectlist-editcreate/projectlist-editcreate.component';
import { ProjectlistListComponent } from './pages/projectlist-list/projectlist-list.component';
import { NotificationContainerComponent } from './components/notification-container/notification-container.component';
import {AuthenticationService} from "./services/authentication.service";
import {NotificationService} from "./services/notification.service";
import { MultiselectComponent } from './components/multiselect/multiselect.component';
import { SuggestionInputComponent } from './components/suggestion-input/suggestion-input.component';
import { NumberinputComponent } from './components/numberinput/numberinput.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { DateinputComponent } from './components/dateinput/dateinput.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { InfoCardComponent } from './pages/home/components/info-card/info-card.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { StatisticsTextComponent } from './components/statistics/statistics-text.component';
import { TableComponent } from './components/table/table.component';
import { TableColComponent } from './components/table/table-col.component';
import { TemplateBuilderComponent } from './components/template-builder/template-builder.component';
import { UserManagementComponent } from './pages/user-management/user-management.component';
import { UserEditCreateComponent } from './pages/user-editcreate/user-editcreate.component';
import { ModalComponent } from './components/modal/modal.component';
import { ContextMenuComponent } from './components/context-menu/context-menu.component';
import { ContextMenuItemComponent } from './components/context-menu/context-menu-item.component';
import {TypedTemplateDirective} from "./utils/typedTemplate";
import { LayoutImageChooserComponent } from './modals/layout-image-chooser/layout-image-chooser.component';
import {FilesizePipe} from "./pipes/filesize.pipe";
import { ReadonlyInputComponent } from './components/readonly-input/readonly-input.component';
import { TabsComponent } from './components/tabs/tabs.component';
import {EmptyFrameComponent} from "./frames/empty-frame/empty-frame.component";
import { TabComponent } from './components/tabs/tab.component';
import { MenubarComponent } from './components/menubar/menubar.component';
import {MenubarButtonComponent} from "./components/menubar/menubar-button.component";
import { ProfileComponent } from './pages/profile/profile.component';
import { AsyncDisplayComponent } from './components/async-display/async-display.component';
import { ContactEditcreateComponent } from './pages/contact-editcreate/contact-editcreate.component';
import { ContactListComponent } from './pages/contact-list/contact-list.component';
import { DownloadExposeModalComponent } from './modals/download-expose-modal/download-expose-modal.component';
import { DownloadPortfolioModalComponent } from './modals/download-portfolio-modal/download-portfolio-modal.component';
import { DownloadProjectlistModalComponent } from './modals/download-projectlist-modal/download-projectlist-modal.component';
import { ImageListComponent } from './pages/image-list/image-list.component';
import { ImageblobDisplayComponent } from './components/imageblob-display/imageblob-display.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import {ToggleValueComponent} from "./components/toggle-button/toggle-value.component";
import { CompanyComponent } from './pages/company/company.component';
import { LayoutTextChooserComponent } from './modals/layout-text-chooser/layout-text-chooser.component';
import { AvatarComponent } from './components/avatar/avatar.component';
import { InputAutogrowComponent } from './components/input-autogrow/input-autogrow.component';
import { TemplateBlockComponent } from './components/template-builder/template-block.component';
import {ImageCropperModule} from "ngx-image-cropper";
import { FieldTypesPipe } from './pages/project-list/pipes/field-types.pipe';
import { BorderComponent } from './components/border/border.component';
import { CompToStrPipe } from './pages/project-list/pipes/comp-to-str.pipe';
import { StrToNStrPipe } from './pages/project-list/pipes/str-to-nstr.pipe';
import { StrToNDatePipePipe } from './pages/project-list/pipes/str-to-ndate.pipe';
import { StrToNFloatPipe } from './pages/project-list/pipes/str-to-nfloat.pipe';
import { FilterUnitPipe } from './pages/project-list/pipes/filter-unit.pipe';
import { ProjectTableComponent } from './components/project-table/project-table.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginFrameComponent,
        DefaultFrameComponent,
        BooleanNotPipe,
        CastDatePipe,
        CastNumberPipe,
        CastNumberArrayPipe,
        CastStringPipe,
        CastStringArrayPipe,
        NumberArrayPipe,
        ShortIDPipe,
        TrustHTMLPipe,
        TrustResourceURLPipe,
        TrustScriptPipe,
        TrustStylePipe,
        TrustURLPipe,
        SuffixPipe,
        DisplayDatePipe,
        CheckboxComponent,
        LoginComponent,
        HomeComponent,
        CardComponent,
        ThemeChangerBtnComponent,
        ButtonComponent,
        SwitchComponent,
        InputComponent,
        TextareaComponent,
        SelectComponent,
        RadiobuttonComponent,
        ControlsDemoComponent,
        AlertComponent,
        ProjectEditCreateComponent,
        ProjectListComponent,
        ExposeEditCreateComponent,
        ExposeListComponent,
        PortfolioEditCreateComponent,
        PortfolioListComponent,
        ProjectlistEditCreateComponent,
        ProjectlistListComponent,
        NotificationContainerComponent,
        MultiselectComponent,
        SuggestionInputComponent,
        NumberinputComponent,
        ModalContainerComponent,
        DateinputComponent,
        RegistrationComponent,
        BreadcrumbsComponent,
        InfoCardComponent,
        StatisticsTextComponent,
        TableComponent,
        TableColComponent,
        TemplateBuilderComponent,
        UserManagementComponent,
        UserEditCreateComponent,
        ModalComponent,
        ContextMenuComponent,
        ContextMenuItemComponent,
        TypedTemplateDirective,
        LayoutImageChooserComponent,
        FilesizePipe,
        ReadonlyInputComponent,
        EmptyFrameComponent,
        TabsComponent,
        TabComponent,
        MenubarComponent,
        MenubarButtonComponent,
        ProfileComponent,
        AsyncDisplayComponent,
        ContactEditcreateComponent,
        ContactListComponent,
        DownloadExposeModalComponent,
        DownloadPortfolioModalComponent,
        DownloadProjectlistModalComponent,
        ImageListComponent,
        ImageblobDisplayComponent,
        ToggleButtonComponent,
        ToggleValueComponent,
        CompanyComponent,
        LayoutTextChooserComponent,
        AvatarComponent,
        InputAutogrowComponent,
        TemplateBlockComponent,
        FieldTypesPipe,
        BorderComponent,
        CompToStrPipe,
        StrToNStrPipe,
        StrToNDatePipePipe,
        StrToNFloatPipe,
        FilterUnitPipe,
        ProjectTableComponent,
    ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    HttpClientModule,
    NgOptimizedImage,
    ReactiveFormsModule,
    ImageCropperModule,


  ],
  providers: [AuthenticationService, NotificationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
