import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {isNgDiff} from "../../utils/angular";

@Component({
  selector: 'pfm-numberinput',
  templateUrl: './numberinput.component.html',
  styleUrls: ['./numberinput.component.scss']
})
export class NumberinputComponent implements OnChanges {
  innervalue: string = ''

  @Input() value: number|null = null;
  @Output() valueChange: EventEmitter<number|null> = new EventEmitter<number|null>();

  @Output() onEnterUp: EventEmitter<any> = new EventEmitter<any>();

  @Input() min: number|null = null;
  @Input() max: number|null = null;
  @Input() step: number = 1;

  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() invalid: boolean|undefined = undefined;
  @Input() placeholder: string = '';
  @Input() unit: string = '';

  @Input() precision: number|null = null;

  @ViewChild('comp') component!: ElementRef<HTMLInputElement>;

  focus() {
    this.component.nativeElement.focus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'value')) {
      this.innervalue = `${this.value ?? ''}`;
    }
  }

  onKeyDown(evt: KeyboardEvent) {
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(evt.keyCode) !== -1)  return;
    // Allow: Ctrl+A
    if  (evt.keyCode === 65 && (evt.ctrlKey || evt.metaKey))  return;
    // Allow: Ctrl+C
    if  (evt.keyCode === 67 && (evt.ctrlKey || evt.metaKey))  return;
    // Allow: Ctrl+V
    if (evt.keyCode === 86 && (evt.ctrlKey || evt.metaKey))  return;
    // Allow: Ctrl+X
    if  (evt.keyCode === 88 && (evt.ctrlKey || evt.metaKey))  return;
    // Allow: home, end, left, right
    if ( (evt.keyCode >= 35 && evt.keyCode <= 39))  return;
    // let it happen, don't do anything

    if ((evt.shiftKey || (evt.keyCode < 48 || evt.keyCode > 57)) && (evt.keyCode < 96 || evt.keyCode > 105)) {
      evt.preventDefault();
    }
  }

  validate(v: string): [string, number]|null {

    if (v.trim() === '') return null;

    if (!/^-?[0-9]*([.,][0-9]*)?$/.test(v)) return null;

    let f = parseFloat(v);
    if (isNaN(f)) return null;

    if (this.min != null && f < this.min) f = this.min;
    if (this.max != null && f > this.max) f = this.max;

    let str = (this.precision === null) ? f.toString() : f.toFixed(this.precision);

    return [str, f];
  }

  onValueInput(v: string) {
    let r = this.validate(v);
    if (r != null) {
      this.valueChange.emit(this.value = r[1]);
    } else {
      this.valueChange.emit(this.value = null);
    }
  }

  blurTriggered() {

    let r = this.validate(this.innervalue);

    if (r === null) {
      this.innervalue = '';
    } else {
      this.innervalue = r[0];
    }

  }
}
