import { Pipe, PipeTransform } from '@angular/core';
import {ComparisonType} from "../../../interfaces/projectFilter";

@Pipe({name: 'compToStr', pure: true})
export class CompToStrPipe implements PipeTransform {

  transform(value: ComparisonType|''): string {
    switch (value) {
      case 'EQ':  return '='; // \u003D   &#61;      ( https://www.fileformat.info/info/unicode/char/003d/index.htm )
      case 'FZ':  return '≈'; // \u2248   &#8776;    ( https://www.fileformat.info/info/unicode/char/2248/index.htm )
      case 'NEQ': return '≠'; // \u2260   &#8800;    ( https://www.fileformat.info/info/unicode/char/2260/index.htm )
      case 'GT':  return '>'; // \u003E   &#62;      ( https://www.fileformat.info/info/unicode/char/003e/index.htm )
      case 'LT':  return '<'; // \u003C   &#60;      ( https://www.fileformat.info/info/unicode/char/003c/index.htm )
      case 'GTE': return '≥'; // \u2265   &#8805;    ( https://www.fileformat.info/info/unicode/char/2265/index.htm )
      case 'LTE': return '≤'; // \u2264   &#8804;    ( https://www.fileformat.info/info/unicode/char/2264/index.htm )
      case 'IN':  return '∋'; // \u220B   &#8715;    ( https://www.fileformat.info/info/unicode/char/220b/index.htm )
      case 'NIN': return '∌'; // \u220C   &#8716;    ( https://www.fileformat.info/info/unicode/char/220c/index.htm )

      case 'FTS': return '?';
      case '':    return ' ';
    }
  }

}
