

<div class="container" (focusout)="onFocusLost()" [class.anysuggestions]="suggest(intermedValue).length > 0" [attr.aria-invalid]="invalid">

  <div class="input-div"
       #inputDiv
       [class.anysuggestions]="suggest(intermedValue).length > 0"
       [class.readonly]="readonly"
       [class.disabled]="readonly"
       [attr.aria-invalid]="invalid"
       (click)="onDivClicked($event, inputDiv)">

    <ng-container *ngFor="let v of value">
      <div class="tag">
        <span>{{displ(v)}}</span>
        <fa-icon *ngIf="!disabled && !readonly" icon="x" (click)="removeValue(v)"></fa-icon>
      </div>
    </ng-container>

    <pfm-input-autogrow #comp
                        type="text"
                        [(value)]="intermedValue"
                        [disabled]="disabled"
                        [readonly]="readonly"
                        [placeholder]="value.length === 0 ? (placeholder??'') : ''"
                        [attr.aria-invalid]="invalid"
                        [minWidth]="0"
                        [extraSpaceStr]="'M'"
                        (keyup.enter)="onEnter($event)"></pfm-input-autogrow>

  </div>


  <div class="option-box">
    <div class="option-list">

      <div class="seperator"></div>

      <div class="option" *ngFor="let suggestion of suggest(intermedValue)" (mousedown)="addValue(suggestion.key, suggestion.value)">
        <fa-icon *ngIf=" value.includes(suggestion.key)" class="icn" icon="square-check"></fa-icon>
        <span    *ngIf="!value.includes(suggestion.key)" class="icn"></span>
        <span>{{ suggestion.value }}</span>
      </div>

    </div>
  </div>

</div>
