import {Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'pfm-tab',
  template: '<ng-template #contentTemplate><ng-content></ng-content></ng-template>',
  styles: []
})
export class TabComponent {
  @Input('tabIndex') index: string = '';
  @Input('tabTitle') title: string = '';

  @ViewChild('contentTemplate', { static: true }) contentTemplate!: TemplateRef<any>;
}
