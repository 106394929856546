import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {CursorToken, ObjectID, Project, ProjectEnums} from "../../interfaces";
import {debounce, interval, Subject, Subscription} from "rxjs";
import {APIService} from "../../services/api.service";
import {MetaService} from "../../services/meta.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {showAPIError} from "../../utils/api";
import {ComparisonType, ProjectFilter} from "../../interfaces/projectFilter";
import {displayContact} from "../../utils/businesslogic";
import {DateUtils} from "../../utils/date";

@Component({
  selector: 'pfm-project-table',
  templateUrl: './project-table.component.html',
  styleUrls: ['./project-table.component.scss']
})
export class ProjectTableComponent implements OnInit, OnDestroy {

  @Input() singleSelect = false;
  @Input() multiSelect = false;
  @Input() edit = false;

  @Input()  selectedProject: ObjectID|null = null;
  @Output() selectedProjectChange = new EventEmitter<ObjectID|null>();

  @Input()  selectedProjects: ObjectID[] = []
  @Output() selectedProjectsChange = new EventEmitter<ObjectID[]>();

  @Output() filterSuccessfullyChanged = new EventEmitter<{searchText: string, filter: ProjectFilter[]}>();

  status: 'loading'|'error'|'content' = 'loading';
  loading: boolean = true;

  projects: Project[] = [];

  nextToken: CursorToken = "@start";

  searchText: string = '';

  showFilter: boolean = false;
  filters: ProjectFilter[] = [this.newEmptyFilter()];


  metavalues: ProjectEnums|null = null;

  filterObservable:   Subject<void>|null = null;
  filterSubscription: Subscription|null = null;

  constructor(private api: APIService,
              private meta: MetaService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  async ngOnInit() {
    this.filterObservable   = new Subject<void>();
    this.filterSubscription = this.filterObservable.pipe(debounce(() => interval(500))).subscribe(() => {this.searchExec().then(() => {});});

    await this.initialize();
  }

  ngOnDestroy() {
    this.filterSubscription?.unsubscribe()
  }

  async initialize() {

    try {
      this.metavalues = await this.meta.getProjectEnums();
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      this.status = 'error';
    }

    this.searchText = '';

    await this.fetchData(true, true);
  }

  newEmptyFilter(): ProjectFilter {
    return({
      fieldIdentifier:          '',
      fieldType:                '',
      comparisonType:           '',
      selectValues:             [],
      value:                    '',
      availableComparisonTypes: [],
    })
  }

  async searchExec() {
    await this.fetchData(false, true);
  }

  getFilters() {
    return this.filters
      .filter(p => p.fieldIdentifier !== '')
      .filter(p =>
      {
        if (p.fieldType === 'PROJECTID'     && p.value === '') return false;
        if (p.fieldType === 'BOOL'          && p.value === '') return false;
        if (p.fieldType === 'STR_ENUM'      && p.value === '') return false;
        if (p.fieldType === 'OPT_STR_ENUM'  && p.value === '') return false;
        if (p.fieldType === 'CONTACT_ID'    && p.value === '') return false;
        if (p.fieldType === 'OPT_TIME'      && p.value === '') return false;
        if (p.fieldType === 'TIME'          && p.value === '') return false;
        if (p.fieldType === 'OPT_FLOAT64'   && p.value === '') return false;
        if (p.fieldType === 'OPT_MONEYCENT' && p.value === '') return false;
        if (p.fieldType === 'USERID'        && p.value === '') return false;
        if (p.fieldType === 'IMAGEID_ARRAY' && p.value === '') return false;
        return true;
      })
  }

  async fetchData(initial: boolean, reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    let searchfilter = this.getFilters().filter(p => p.fieldIdentifier !== '').map(p => ({ident: p.fieldIdentifier, comp: p.comparisonType as string, val: p.value}));
    if (this.searchText !== '') searchfilter.push(({ident: 'SEARCH', comp: 'FTS', val: this.searchText}))

    try {
      this.loading = true;
      if (initial) this.status = 'loading';

      if (initial) {
        this.metavalues = await this.meta.getProjectEnums();
      }

      const data = await this.api.listProjects(this.nextToken, 48, searchfilter);

      if (reset) {
        this.projects = data.projects;
      } else {
        this.projects = [...this.projects, ...data.projects];
      }
      this.nextToken = data.nextPageToken;
      this.filterSuccessfullyChanged.emit({searchText: this.searchText, filter: this.getFilters()});

      if (initial) this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      if (initial) this.status = 'error';
    } finally {
      this.loading = false;
    }
  }

  async editProject(item: Project) {
    await this.router.navigate(['/', 'projects', item.id, 'edit']);
  }

  async openExpose(item: Project) {
    try {
      let data = await this.api.listProjectExposes(item.id, '@start', null);
      if (data.exposes.length === 0) {
        await this.router.navigate(['/', 'exposes', 'create'], {queryParams: {'project':item.id}});
      } else {
        await this.router.navigate(['/', 'exposes', data.exposes[0].id, 'edit']);
      }
    } catch (err) {
      showAPIError(this.notification, 'Expose konnten nicht geladen werden', err);
    }
  }

  async deleteProject( item: Project) {

    await this.api.deleteContact(item.id);
    this.modal.openDialog({
      title: 'Projekt löschen',
      message: 'Das Löschen kann nicht rückgängig gemacht werden.',
      type: 'danger',
      onSubmit: () => {
        try {
          this.projects = this.projects.filter(p => p.id != item.id);
          this.notification.success('Projekt gelöscht', item.projectName);
        } catch (err) {
          showAPIError(this.notification, 'Fehler beim Löschenn', err);
        }
      }
    })
  }

  translateClassification(cls: string | null) {
    if (cls === null) return "-";

    if (this.metavalues === null) return "?";

    for (const ce of this.metavalues.classification) {
      if (ce.value === cls) return ce.description;
    }

    return `[${cls}]`;
  }

  filterIdentChanged(newIdent: string|null, pfilter: ProjectFilter, idx: number) {

    const beforeIdent = pfilter.fieldIdentifier;

    if (newIdent === null) {

      this.filters[idx].value = '';
      this.filters[idx].fieldType = '';
      this.filters[idx].fieldIdentifier = '';
      this.filters[idx].selectValues = [];
      this.filters[idx].availableComparisonTypes = [];

    } else {

      this.filters[idx].fieldIdentifier = newIdent;
      this.filters[idx] = this.updateFilter(this.filters[idx], beforeIdent !== newIdent);

    }

    this.filters = [...this.filters.filter(p => p.fieldIdentifier !== ''), this.newEmptyFilter()];

  }

  updateFilter(filter: ProjectFilter, reset: boolean): ProjectFilter {

    let spec = this.metavalues?.projectFields?.find(p => p.identifier === filter.fieldIdentifier) ?? null;

    if (spec === null) {
      return this.newEmptyFilter();
    }

    filter = {...filter};

    let ctypes: ComparisonType[] = [];
    switch (spec.fieldType) {
      case 'PROJECTID':               ctypes = ['EQ']; break;
      case 'KEY_STRING':              ctypes = ['EQ','NEQ']; break;
      case 'SHORT_STRING':            ctypes = ['EQ','NEQ','FZ']; break;
      case 'LONG_STRING':             ctypes = ['FZ']; break;
      case 'FUNCTIONCONTACT_SERVICE': ctypes = spec.hasArrayInPath ? ['IN','NIN'] : ['EQ','NEQ']; break;
      case 'BOOL':                    ctypes = ['EQ']; break;
      case 'STR_ENUM':                ctypes = ['EQ','NEQ']; break;
      case 'OPT_STR_ENUM':            ctypes = ['EQ','NEQ']; break;
      case 'STR_ARRAY':               ctypes = ['IN','NIN']; break;
      case 'CONTACT_ID':              ctypes = spec.hasArrayInPath ? ['IN','NIN'] : ['EQ','NEQ']; break;
      case 'OPT_TIME':                ctypes = ['EQ', 'GTE', 'GT', 'LT', 'LTE']; break;
      case 'TIME':                    ctypes = ['EQ', 'GTE', 'GT', 'LT', 'LTE']; break;
      case 'OPT_FLOAT64':             ctypes = ['EQ', 'GTE', 'GT', 'LT', 'LTE']; break;
      case 'OPT_MONEYCENT':           ctypes = ['EQ', 'GTE', 'GT', 'LT', 'LTE']; break;
      case 'USERID':                  ctypes = spec.hasArrayInPath ? ['IN','NIN'] : ['EQ','NEQ']; break;
      case 'IMAGEID_ARRAY':           ctypes = []; break;
    }

    filter.fieldType = spec.fieldType;
    filter.selectValues = [];
    filter.availableComparisonTypes = ctypes;
    if (reset) filter.value = '';

    if (spec.fieldType == 'STR_ENUM') {

      filter.selectValues = spec.enumValues.map(p => ({'key': p.value, 'value': p.description}));

    } else if (spec.fieldType == 'OPT_STR_ENUM') {

      filter.selectValues = spec.enumValues.map(p => ({'key': p.value, 'value': p.description}));

    } else if (spec.fieldType == 'USERID') {

      filter.selectValues = this.metavalues?.users?.map(p => ({'key': p.id, 'value': p.username})) ?? [];

    } else if (spec.fieldType == 'CONTACT_ID') {

      filter.selectValues = this.metavalues?.contacts?.map(p => ({'key': p.id, 'value': (displayContact(p)??p.id)})) ?? [];

    } else if (spec.fieldType == 'FUNCTIONCONTACT_SERVICE') {

      filter.selectValues = this.metavalues?.functionContactService?.map(p => ({'key': p, 'value': p})) ?? [];

    } else {

      if (filter.fieldIdentifier === 'WORKSCOPES')             filter.selectValues = this.metavalues?.workScopes?.map(p => ({'key': p, 'value': p})) ?? [];
      if (filter.fieldIdentifier === 'UTILISATION')            filter.selectValues = this.metavalues?.utilisation?.map(p => ({'key': p, 'value': p})) ?? [];
      if (filter.fieldIdentifier === 'CONSTRUCTIONTYPES')      filter.selectValues = this.metavalues?.constructionTypes?.map(p => ({'key': p, 'value': p})) ?? [];
      if (filter.fieldIdentifier === 'SUSTAINABILITYASPECTS')  filter.selectValues = this.metavalues?.sustainabilityAspects?.map(p => ({'key': p, 'value': p})) ?? [];
      if (filter.fieldIdentifier === 'PROJECT_KIND')           filter.selectValues = this.metavalues?.projectKinds?.map(p => ({'key': p, 'value': p})) ?? [];
      if (filter.fieldIdentifier === 'PROJECT_STATUS')         filter.selectValues = this.metavalues?.projectStatus?.map(p => ({'key': p, 'value': p})) ?? [];
      if (filter.fieldIdentifier === 'PROJECTADDRESS_ZIP')     filter.selectValues = this.metavalues?.zips?.map(p => ({'key': p, 'value': p})) ?? [];
      if (filter.fieldIdentifier === 'PROJECTADDRESS_CITY')    filter.selectValues = this.metavalues?.cities?.map(p => ({'key': p, 'value': p})) ?? [];
      if (filter.fieldIdentifier === 'PROJECTADDRESS_COUNTRY') filter.selectValues = this.metavalues?.countries?.map(p => ({'key': p, 'value': p})) ?? [];

    }

    const currCompType =  filter.comparisonType
    if (reset || currCompType === '' || !ctypes.includes(currCompType)) {
      filter.comparisonType = ctypes[0] ?? '';
    }

    return filter;
  }

  rotateCompType(idx: number) {
    const vact: ComparisonType[] = this.filters[idx].availableComparisonTypes;

    if (vact.length === 0) { this.filters[idx].comparisonType = ''; return; }

    this.filters[idx].comparisonType = vact[ ((vact as string[]).indexOf(this.filters[idx].comparisonType) + 1) % vact.length ];

  }

  nullableDateToStr(v: Date | null) {
    return (v===null) ? '' : DateUtils.formatRFC3339(v);
  }

  nullableFloatToStr(v: number | null) {
    return (v===null) ? '' : (v.toFixed(6));
  }

  onChangeSingleSelect(item: Project, state: boolean) {
    if (state) this.selectedProject = item.id; else this.selectedProject = null;
    this.selectedProjectChange.emit(this.selectedProject);
  }

  onChangeMultiSelect(item: Project, state: boolean) {
    if (state) {
      if (!this.selectedProjects.includes(item.id)) this.selectedProjects = [...this.selectedProjects, item.id];
    } else {
      this.selectedProjects = this.selectedProjects.filter(p => p !== item.id);
    }
    this.selectedProjectsChange.emit(this.selectedProjects);
  }

  onRowClicked(p: Project) {
    if (this.singleSelect) {
      this.onChangeSingleSelect(p, this.selectedProject !== p.id);
    } else if (this.multiSelect) {
      this.onChangeMultiSelect(p, !this.selectedProjects.includes(p.id));
    }
  }

  async initFromQuery(searchText: string, filters: ProjectFilter[], showFilter: boolean) {
    this.searchText = searchText;
    this.filters = filters;
    this.showFilter = showFilter;

    await this.fetchData(true, true);
  }
}
