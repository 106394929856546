import { Pipe, PipeTransform } from '@angular/core';
import {ProjectEnums} from "../../../interfaces";

@Pipe({ name: 'fieldTypes', pure: true })
export class FieldTypesPipe implements PipeTransform {
  transform(value: ProjectEnums): {key:string, value:string}[] {
    return value.projectFields.
      filter(p => p.fieldType !== 'IMAGEID_ARRAY').
      map(p => ({key: p.identifier, value: p.name}));
  }
}
