import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import * as uuid from "uuid";
import {isNgDiff} from "../../utils/angular";

@Component({
  selector: 'pfm-input-autogrow',
  templateUrl: './input-autogrow.component.html',
  styleUrls: ['./input-autogrow.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputAutogrowComponent implements OnInit, OnChanges {
  @Input() value: string = '';
  @Input() values: {key: string, value: string}[] = [];
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() onEnterUp: EventEmitter<any> = new EventEmitter<any>();

  @Input() type: 'text'|'password'|'number'|'tel'|'url'| 'email' = 'text';
  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;
  @Input() required: boolean = false;
  @Input() invalid: boolean|undefined = undefined;
  @Input() placeholder: string = '';
  @Input() minWidth: number = 64;
  @Input() extraSpaceStr: string = '';

  @Input() preventAutofill: boolean = false;

  @ViewChild('comp') component!: ElementRef<HTMLInputElement>;
  compWidth: number = this.minWidth;

  @ViewChild('hiddenText1') textComp1!: ElementRef;
  @ViewChild('hiddenText2') textComp2!: ElementRef;

  uuidName: string = uuid.v4();
  uuidAutocomplete: string = `chrome-disable-${uuid.v4()}`;
  autoFillDisabledOverride: boolean = false;

  ngOnInit(): void {
    if (this.preventAutofill) {
      this.startAutofillPrevention();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isNgDiff(changes, 'minWidth')) this.resize();
    if (isNgDiff(changes, 'value')) this.resize();
    if (isNgDiff(changes, 'placeholder')) this.resize();
  }

  focus() {
    this.component.nativeElement.focus();
    this.resize();
  }

  startAutofillPrevention() {
    this.autoFillDisabledOverride = true;
    setTimeout(() => { this.autoFillDisabledOverride = false; }, 100)
  }

  cancelAutofillPrevention() {
    this.autoFillDisabledOverride = false;
  }

  resize() {
    setTimeout(() =>
    {
      if (this.value === '' && this.placeholder === '') { this.compWidth = this.minWidth; return; }
      this.compWidth = Math.max(this.minWidth, this.textComp1.nativeElement.offsetWidth, (this.value === '') ? this.textComp2.nativeElement.offsetWidth : 0);
    });
  }
}
