import {
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {ModalService} from "../../services/modal.service";
import {ContextMenuItemComponent} from "./context-menu-item.component";

@Component({
  selector: 'pfm-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent {

  @Input() showDots: boolean = true;

  @Input() stopClickPropagation: boolean = false;

  @ViewChild('template', { static: true }) templRef!: TemplateRef<any>;

  @ViewChild('iconComp') iconCompRef!: ElementRef;

  @ContentChildren(ContextMenuItemComponent) contentChildren!: QueryList<ContextMenuItemComponent>;

  modalid: string|null = null;

  constructor(private modal: ModalService) {}

  close() {
    if (this.modalid !== null) this.modal.close(this.modalid);
  }

  showManual(evt: MouseEvent) {

    const x = evt.x;
    const y = evt.y;

    this.modalid = this.modal.openGeneric({
      content:this.templRef,

      closeOnBackgroundClick: true,
      closeOnEscapePress: false,
      withBackground: false,
      blur: false,
      closeOnPageChange: true,

      onClose: () => {  this.modalid = null; this.modalid = null; },

      position: () => {
        return {x:x, y:y, corner:'tl', limitToBounds:true};
      },
    })

  }

  onShow(evt: MouseEvent) {

    this.modalid = this.modal.openGeneric({
      content:this.templRef,

      closeOnBackgroundClick: true,
      closeOnEscapePress: false,
      withBackground: false,
      blur: false,
      closeOnPageChange: true,

      onClose: () => {  this.modalid = null; },

      position: () => {
        const rect = this.iconCompRef.nativeElement.getBoundingClientRect();
        return {x:rect.x, y:rect.y, corner:'tr', limitToBounds:true};
      },
    })

    if (this.stopClickPropagation) {
      evt.stopPropagation();
    }
  }
}
