import { Component } from '@angular/core';

@Component({
  selector: 'frame-login',
  templateUrl: './login-frame.component.html',
  styleUrls: ['./login-frame.component.scss']
})
export class LoginFrameComponent {

}
