import { Pipe, PipeTransform } from '@angular/core';
import {MetaService} from "../../../services/meta.service";

@Pipe({ name: 'filterUnit', pure: true })
export class FilterUnitPipe implements PipeTransform {

  constructor(private meta: MetaService) {}

  transform(value: string): string {
    const meta = this.meta.getProjectEnumsImmediately();
    if (meta === null) return '';
    let u = meta.projectFields.find(p => p.identifier === value)?.unit;
    if (u === null) return '';

    switch (u)
    {
      case 'NONE':                       return '';
      case 'METER':                      return 'm';
      case 'SQUARE_METER':               return 'm²';
      case 'CUBIC_METER':                return 'm³';
      case 'TIME':                       return '';
      case 'KILOGRAMM':                  return 'kg';
      case 'KILOGRAMM_PER_SQUARE_METER': return 'kg/m²';
      case 'CENT':                       return 'cent';
    }

    return '';
  }
}
