import { Component } from '@angular/core';
import { FaConfig, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';
import * as faThin from '@fortawesome/pro-thin-svg-icons'

@Component({
  selector: 'pfm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {

    //library.addIconPacks(fas, far, fad, fal, fat, fab);

    // ======== FA SOLID ========

    library.addIcons(
      faSolid.faSave,
      faSolid.faSpinner,
      faSolid.faCircleHalfStroke,
      faSolid.faRightToBracket,
      faSolid.faFaceFlushed,
      faSolid.faFaceDizzy,
      faSolid.faFaceGrimace,
      faSolid.faRightFromBracket,
      faSolid.faFaceSmileHorns,
      faSolid.faLoader,
      faSolid.faUserRobotXmarks,
      faSolid.faX,
      faSolid.faPlus,
      faSolid.faTrash,
      faSolid.faUpload,
      faSolid.faImage,
      faSolid.faArrowLeft,
      faSolid.faFilePen,
      faSolid.faBriefcase,
      faSolid.faSquareList,
      faSolid.faHome,
      faSolid.faEllipsisV,
      faSolid.faGrid2,
      faSolid.faFolder,
      faSolid.faSquarePollVertical,
      faSolid.faCaretUp,
      faSolid.faCaretDown,
      faSolid.faMagnifyingGlass,
      faSolid.faCircleXmark,
      faSolid.faMobile,
      faSolid.faPhoneOffice,
      faSolid.faFilePdf,
      faSolid.faSquareCheck,
      faSolid.faPen,
      faSolid.faCrop,
      faSolid.faFilterList,
      faSolid.faXmark,
      faSolid.faEye,
      faSolid.faEyeSlash,
    )

    // ======== FA THIN ========

    library.addIcons(
      faThin.faPen,
      faThin.faTableLayout,
      faThin.faArrowDownToLine,
      faThin.faArchive,
      faThin.faTrashCan,
      faThin.faFiles,
      faThin.faClipboardList,
      faThin.faBriefcase,
      faThin.faCircleUser,
      faThin.faRightFromBracket,
      faThin.faArrowDownToSquare,
      faThin.faTableLayout,
      faThin.faTrash,
      faThin.faBuilding,
      faThin.faAddressBook,
      faThin.faImages,
      faThin.faUser,
      faThin.faUsers,
      faThin.faFloppyDisk,
      faThin.faUserPlus,
      faThin.faUpload,
      faThin.faXmark,
      faThin.faDownload,
      faThin.faArrowRight,
    )

    faConfig.defaultPrefix = 'fas';
    faConfig.fixedWidth = true;
  }
}
