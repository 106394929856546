import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {isNgDiff} from "../../utils/angular";
import {blobToBase64, blobToDataUrl} from "../../utils/blob";

@Component({
  selector: 'pfm-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnChanges {
  @Input() defaultImage: string|null = null;
  @Input() round: boolean = true;

  @Input() loading = false;

  selectedImageDataURL: string|null = null;
  @Input() selectedImage: File|null = null;
  @Output() selectedImageChange: EventEmitter<File|null> = new EventEmitter<File|null>();


  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (isNgDiff(changes, 'selectedImage')) {
      if (this.selectedImage === null) {
        this.selectedImageDataURL = null;
      } else {
        blobToDataUrl(this.selectedImage).then(v => console.log(this.selectedImageDataURL = v));
      }
    }
  }

  onImageSelected(evt: Event) {
    const files = (evt.target as any).files as File[];

    if (files !== undefined && files.length > 0) {
      this.selectedImageChange.emit(this.selectedImage = files[0]);
      blobToDataUrl(this.selectedImage).then(v => console.log(this.selectedImageDataURL = v));
    }
  }
}
