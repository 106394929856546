import {Component, Input} from '@angular/core';

@Component({
  selector: 'pfm-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent {

  @Input() crumbs: {text: string, link?: string, active?: boolean}[] = []
  @Input() back: string|null = null;

}
