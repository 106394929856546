import {ObjectID, RFC3339} from "./utils";

export interface Project {
  id: ObjectID;
  creationTime: RFC3339;
  lastUpdated: RFC3339;
  companyID: string;
  creatorID: string;
  classification: string|null,
  projectName: string;
  projectNumber: string;
  projectStatus: string;
  projectKind: string[];
  confidentialAgreement: boolean;
  projectAddress: Address;
  procedure: string|null;
  categories: string[];
  teamMembers: ProjectFunctionContact[];
  awardingBody: ProjectRootContact|null;
  builder: ProjectRootContact|null;
  client: ProjectRootContact|null;
  serviceProvider: ProjectFunctionContact[];
  servicePhases: HOAIServicePhases;
  workScopes: string[];
  utilisation: string[];
  constructionTypes: string[];
  sustainabilityAspects: string[];
  milestones: ProjectMilestones;
  specialFeatures: string;
  windLoadZone: string|null;
  snowLoad: string|null;
  earthquakeZone: string|null;
  payload: string|null;
  buildingSpan: number|null;
  buildVolume: number|null;
  floorSpace: number|null;
  buildingHeight: number|null;
  masses: number|null;
  buildingClass: string|null;
  BGF: number|null;
  NUF: number|null;
  BRI: number|null;
  NRF: number|null;
  NRI: number|null;
  BF: number|null;
  rulebook: string|null;
  feeBand: string|null;
  feeLevel: string|null;
  feeHOAI: MoneyCent|null;
  costType: string|null;
  KG100: MoneyCent|null;
  KG300: MoneyCent|null;
  KG300400: MoneyCent|null;
  totalCost: MoneyCent|null;
  description: string;
  descriptionShort: string;
  images: ObjectID[];
  deleted: RFC3339|null;

  completion: RFC3339|null;
  completedBy: ObjectID|null;

  selected?: boolean;
}

export type MoneyCent = number;

export interface Address {
  street: string;
  streetNumber: string;
  zip: string;
  city: string;
  country: string;
}

export function NewAddress(): Address {
  return {
    street: '',
    streetNumber: '',
    zip: '',
    city: '',
    country: '',
  }
}

export interface ProjectFunctionContact {
  service:   string;
  contactID: ObjectID;
}

export interface ProjectRootContact {
  privacy:   string;
  contactID: ObjectID;
}

export interface HOAIServicePhases {
  lp1: boolean;
  lp2: boolean;
  lp3: boolean;
  lp4: boolean;
  lp5: boolean;
  lp6: boolean;
  lp7: boolean;
  lp8: boolean;
  lp9: boolean;
}

export function NewHOAIServicePhases(): HOAIServicePhases {
  return {
    lp1: false,
    lp2: false,
    lp3: false,
    lp4: false,
    lp5: false,
    lp6: false,
    lp7: false,
    lp8: false,
    lp9: false,
  }
}

export interface ProjectMilestones {
  projectStart: RFC3339|null;
  endLPH3: RFC3339|null;
  buildingStart: RFC3339|null;
  projectEnd: RFC3339|null;
}

export function NewProjectMilestones(): ProjectMilestones {
  return {
    projectStart: null,
    endLPH3: null,
    buildingStart: null,
    projectEnd: null,
  }
}

export interface CreateNewProjectFunctionContact {
  service: string;

  contactID: ObjectID|null;

  name: string|null;
  companyName: string|null;
  address: Address|null;
  phone: string|null;
}

export interface CreateNewProjectRootContact {
  privacy: string;

  contactID: ObjectID|null;

  name: string|null;
  companyName: string|null;
  address: Address|null;
  phone: string|null;
}

export interface LinkProjectImage {
  id:        ObjectID|null;
  copyright: string;
  notes:     string;
  caption:   string;
}

export interface CreateNewProject{
  projectName: string,
  projectNumber: string,
  projectStatus: string,
  projectKind: string[],
  confidentialAgreement: boolean,
  classification: string|null,
  projectAddress: Address,
  procedure: string|null,
  categories: string[],
  teamMembers: CreateNewProjectFunctionContact[],
  awardingBody: CreateNewProjectRootContact|null,
  builder: CreateNewProjectRootContact|null,
  clientIsBuilder: boolean,
  client: CreateNewProjectRootContact|null,
  serviceProviders: CreateNewProjectFunctionContact[],
  servicePhases: HOAIServicePhases,
  workScopes: string[],
  utilisation: string[],
  constructionTypes: string[],
  sustainabilityAspects: string[],
  milestones: ProjectMilestones,
  specialFeatures: string,
  windLoadZone: string|null,
  snowLoad: string|null,
  earthquakeZone: string|null,
  payload: string|null,
  buildingSpan: number|null,
  buildVolume: number|null,
  floorSpace: number|null,
  buildingHeight: number|null,
  masses: number|null,
  buildingClass: string|null,
  BGF: number|null,
  NUF: number|null,
  BRI: number|null,
  NRF: number|null,
  NRI: number|null,
  BF: number|null,
  rulebook: string|null,
  feeBand: string|null,
  feeLevel: string|null,
  feeHOAI: number|null,
  costType: string|null,
  KG100: number|null,
  KG300: number|null,
  KG300400: number|null,
  totalCost: number|null,
  description: string,
  descriptionShort: string,
  images: LinkProjectImage[],
}
