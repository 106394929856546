

<pfm-modal [show]="show"
           (showChange)="show = $event; showChange.emit($event);"
           modalWidth="calc(100vw - 4rem)"
           modalHeight="calc(100vh - 4rem)">

  <div class="modal-content">
    <h1>Freitext eingeben</h1>

    <pfm-textarea value="textarea" [rows]="2" [(value)]="value" resize="none" [fullInnerHeight]="true"></pfm-textarea>

    <pfm-button type="primary" (btnClick)="onOkay()">Okay</pfm-button>

  </div>

</pfm-modal>
