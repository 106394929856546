import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthenticationService} from "../../services/authentication.service";
import {APIService} from "../../services/api.service";
import {isErrorCode} from "../../utils/api";
import {sleep} from "../../utils/angular";

@Component({
  selector: 'pfm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginLoading: boolean = false;
  errorText: string = "";

  identifier: string = '';
  password: string = '';
  ephemeral: boolean = false;

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    if (auth.isAuthenticated()) {
      void this.router.navigate(['/'], {queryParams: {}});
    }
  }

  async login() {
    try {
      this.loginLoading = true;
      await sleep(800)
      this.errorText = '';
      const data = await this.api.login(this.identifier, this.password); //TODO pass ephemeral

      this.auth.setAuth(data.token);

      await this.router.navigate(['/'], {queryParams: {}});

    } catch (err: any) {
      if (isErrorCode(err, 'ENTITY_NOT_FOUND')) {
        this.errorText = 'Die eingegebenen Login-Daten sind nicht richtig.';
      } else if (isErrorCode(err, 'AUTH_FAILED')) {
        this.errorText = 'Die eingegebenen Login-Daten sind nicht richtig.';
      } else if (isErrorCode(err, 'INTERNAL_ERROR')) {
        this.errorText = 'Interner Server Fehler';
      } else {
        this.errorText = 'Es ist ein unbekannter Fehler aufgetreten';
      }
    } finally {
      this.loginLoading = false;
    }
  }

}
