<frame-login>
  <main>

    <div class="header">
      <img ngSrc="assets/logo.png" class="logo" height="1024" width="1024" alt="logo"/>
      <h2 class="title">isiproject</h2>
    </div>

    <h1>Willkommen zurück!</h1>
    <span class="login-text">Geben Sie ihre Daten ein und machen Sie direkt weiter, wo sie zuletzt aufgehört haben.</span>

    <pfm-alert *ngIf="errorText != ''">{{errorText}}</pfm-alert>

    <label>E-Mail / Benutzername</label>
    <pfm-input [(value)]="identifier" [autoTrim]="true" placeholder="Geben Sie ihre E-Mail-Adresse ein" [autoTrim]="false" (keyup.enter)="this.inputPassword.focus()"></pfm-input>

    <label>Passwort</label>
    <pfm-input #inputPassword [(value)]="password" type="password" placeholder="Geben Sie ihr Passwort ein" [autoTrim]="false" [showPasswordEye]="true" (keyup.enter)="login()"></pfm-input>

    <pfm-switch class="eph-switch" [(value)]="ephemeral" text="Eingeloggt bleiben"></pfm-switch>

    <pfm-button [loading]="loginLoading" (btnClick)="login()" type="primary">Anmelden</pfm-button>

    <!--- to be removed as soon as something to pay is initialized--->
    <!--<span class="infotext">Sie haben noch keinen Account? <a [routerLink]="'/registration'">Hier registrieren.</a></span>-->

  </main>
</frame-login>
