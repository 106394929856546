import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import {authGuard} from "./guards/auth.guard";
import {HomeComponent} from "./pages/home/home.component";
import {ControlsDemoComponent} from "./pages/controls-demo/controls-demo.component";
import {ProjectEditCreateComponent} from "./pages/project-editcreate/project-editcreate.component";
import {ProjectListComponent} from "./pages/project-list/project-list.component";
import {ExposeEditCreateComponent} from "./pages/expose-editcreate/expose-editcreate.component";
import {ExposeListComponent} from "./pages/expose-list/expose-list.component";
import {PortfolioEditCreateComponent} from "./pages/portfolio-editcreate/portfolio-editcreate.component";
import {PortfolioListComponent} from "./pages/portfolio-list/portfolio-list.component";
import {ProjectlistEditCreateComponent} from "./pages/projectlist-editcreate/projectlist-editcreate.component";
import {ProjectlistListComponent} from "./pages/projectlist-list/projectlist-list.component";
import {RegistrationComponent} from "./pages/registration/registration.component";
import {UserManagementComponent} from "./pages/user-management/user-management.component";
import {UserEditCreateComponent} from "./pages/user-editcreate/user-editcreate.component";
import {ProfileComponent} from "./pages/profile/profile.component";
import {ContactListComponent} from "./pages/contact-list/contact-list.component";
import {ContactEditcreateComponent} from "./pages/contact-editcreate/contact-editcreate.component";
import {ImageListComponent} from "./pages/image-list/image-list.component";
import {CompanyComponent} from "./pages/company/company.component";
import {pendingChangesGuard} from "./guards/pending-changes.guard";


const routes: Routes =
[
  { path: 'login',                    component: LoginComponent,                                                                    },
/*
  { path: 'registration',             component: RegistrationComponent,                                                             },
*/
  { path: 'demo',                     component: ControlsDemoComponent,                                                             },

  { path: '',                         component: HomeComponent,                  canActivate: [authGuard],                          },

  { path: 'projects/create',          component: ProjectEditCreateComponent,     canActivate: [authGuard], data: { mode: 'CREATE' } },
  { path: 'projects/:projid/edit',    component: ProjectEditCreateComponent,     canActivate: [authGuard], data: { mode: 'EDIT'   }, canDeactivate: [pendingChangesGuard] },
  { path: 'projects/list',            component: ProjectListComponent,           canActivate: [authGuard],                          },

  { path: 'exposes/create',           component: ExposeEditCreateComponent,      canActivate: [authGuard], data: { mode: 'CREATE' } },
  { path: 'exposes/:expid/edit',      component: ExposeEditCreateComponent,      canActivate: [authGuard], data: { mode: 'EDIT'   }, canDeactivate: [pendingChangesGuard] },
  { path: 'exposes/list',             component: ExposeListComponent,            canActivate: [authGuard],                          },

  { path: 'portfolios/create',        component: PortfolioEditCreateComponent,   canActivate: [authGuard], data: { mode: 'CREATE' } },
  { path: 'portfolios/:pfid/edit',    component: PortfolioEditCreateComponent,   canActivate: [authGuard], data: { mode: 'EDIT'   }, canDeactivate: [pendingChangesGuard] },
  { path: 'portfolios/list',          component: PortfolioListComponent,         canActivate: [authGuard],                          },

  { path: 'projectlists/create',      component: ProjectlistEditCreateComponent, canActivate: [authGuard],  data: { mode: 'CREATE' } },
  { path: 'projectlists/:plid/edit',  component: ProjectlistEditCreateComponent, canActivate: [authGuard],  data: { mode: 'EDIT'   }, canDeactivate: [pendingChangesGuard] },
  { path: 'projectlists/list',        component: ProjectlistListComponent,       canActivate: [authGuard],                          },

  { path: 'users/create',             component: UserEditCreateComponent,        canActivate: [authGuard], data: { mode: 'CREATE' } },
  { path: 'users/:usrid/edit',        component: UserEditCreateComponent,        canActivate: [authGuard], data: { mode: 'EDIT'   } },
  { path: 'users/list',               component: UserManagementComponent,        canActivate: [authGuard],                          },

  { path: 'images',                   component: ImageListComponent,             canActivate: [authGuard],                          },
  { path: 'images/:tabmode',          component: ImageListComponent,             canActivate: [authGuard],                          },

  { path: 'profile',                  component: ProfileComponent,               canActivate: [authGuard],                          },

  { path: 'company/settings',         component: CompanyComponent,               canActivate: [authGuard],                          },

  { path: 'contacts/list',            component: ContactListComponent,           canActivate: [authGuard],                          },
  { path: 'contacts/create',          component: ContactEditcreateComponent,     canActivate: [authGuard], data: { mode: 'CREATE' } },
  { path: 'contacts/:contactid/edit', component: ContactEditcreateComponent,     canActivate: [authGuard], data: { mode: 'EDIT'   } },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
