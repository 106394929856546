import {Component, Input} from '@angular/core';
import {IconProp} from "@fortawesome/fontawesome-svg-core";

@Component({
  selector: 'pfm-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent {
   @Input() title: string = '';
   @Input() text: string = '';
   @Input() icon: IconProp | null = null;

   @Input() buttonLabel: string|null = null;

   @Input() buttonIcon: IconProp | null = null;
   @Input() buttonRouterLink: string = '';

  constructor() {}

}
