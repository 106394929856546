<div class="notifications" [style.margin-top]="marginTop">
  <ng-container  *ngFor="let notif of visibleNotifications">

    <div class="notification" [ngClass]="notif.type.toLowerCase()" (click)="close(notif)">
      <div class="title">{{notif.title}}</div>
      <div class="message">{{notif.message}}</div>
    </div>

  </ng-container>
</div>
