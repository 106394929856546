import {Component, EventEmitter, Input, Output} from '@angular/core';
import * as uuid from 'uuid';

@Component({
  selector: 'pfm-radiobutton',
  templateUrl: './radiobutton.component.html',
  styleUrls: ['./radiobutton.component.scss']
})
export class RadiobuttonComponent {

  uniq = uuid.v4()

  @Input() value: string|null = null;
  @Output() valueChange: EventEmitter<string|null> = new EventEmitter<string|null>();

  @Input() text: string = '';
  @Input() name: string = '';
  @Input() disabled: boolean = false;
  @Input() invalid: boolean|undefined = undefined;

  @Input() radiovalue: string = uuid.v4();

}
