import {Injectable, TemplateRef} from '@angular/core';
import {Observable, Subject} from "rxjs";
import * as uuid from "uuid";

export interface ModalWrapper {
  type: 'dialog'|'generic';
  modal: DialogModal|GenericModal;
  id: string;
}

export interface Modal {
  onClose?:  () => void;

  closeOnPageChange? : boolean;
  closeOnBackgroundClick?: boolean;
  closeOnEscapePress?: boolean;
}

export interface DialogModal extends Modal {
  title: string;
  message: string;
  type: 'alert'|'danger';

  onSubmit?: () => void;
  onCancel?: () => void;
}

export interface GenericModal extends Modal {
  blur?: boolean;
  withBackground: boolean;
  content: TemplateRef<any>;
  modalWidth?: string;
  modalHeight?: string;

  position?: () => {x: number, y: number, corner: 'tl'|'br'|'tr'|'bl', limitToBounds: boolean};
}

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private _subjectCreateModal = new Subject<ModalWrapper>();
  private _subjectCloseModal = new Subject<string>();

  constructor() { }

  getCreateModalObservable(): Observable<ModalWrapper> {
    return this._subjectCreateModal.asObservable();
  }

  getCloseModalObservable(): Observable<string> {
    return this._subjectCloseModal.asObservable();
  }

  openDialog(modal: DialogModal): string {
    const mid = uuid.v4();
    this._subjectCreateModal.next({type:'dialog', modal:modal, id: mid});
    return mid;
  }

  openGeneric(modal: GenericModal): string {
    const mid = uuid.v4();
    this._subjectCreateModal.next({type:'generic', modal:modal, id: mid});
    return mid;
  }

  close(v: string) {
    this._subjectCloseModal.next(v);
  }
}
