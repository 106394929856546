
<label for="cbox-{{uniq}}">
  <input type="checkbox"
         id="cbox-{{uniq}}"
         [ngModel]="value"
         (ngModelChange)="value = $event; valueChange.emit($event)"
         [indeterminate]="indeterminate"
         [disabled]="disabled"
         [readonly]="readonly"
         [attr.aria-invalid]="invalid">
  <span>{{text}}</span>
</label>
