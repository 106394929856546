<frame-default>
  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 2rem"
                     back="/"
                     [crumbs]="[{ text:'Seiten' }, { text:'Profil', link:'/profile', active: true }]"></pfm-breadcrumbs>

    <div class="form-content">

      <div *ngIf="errorTexts.length > 0" class="alert-box">
        <pfm-alert *ngFor="let v of errorTexts">{{v}}</pfm-alert>
      </div>

      <label>Username</label>
      <pfm-input #usernameInput
                 [(value)]="username"
                 [preventAutofill]="true"
                 placeholder="Username"
                 [invalid]="isInvalid('username')"
                 (keyup.enter)="emailInput.focus()">
      </pfm-input>

      <label>E-Mail</label>
      <pfm-input #emailInput
                 [(value)]="email"
                 [preventAutofill]="true"
                 [invalid]="isInvalid('email')"
                 formControlName="email"
                 placeholder="E-Mail"
      ></pfm-input>

      <pfm-button type="primary" [loading]="saveLoading1" (btnClick)="changeData()">Speichern</pfm-button>

      <div class="separator"></div>

      <label>Aktuelles Passwort</label>
      <pfm-input #inputPassword0
                 [(value)]="currentPassword"
                 [autoTrim]="false"
                 [preventAutofill]="true"
                 [invalid]="isInvalid('passwordCurrent')"
                 type="password"
                 [showPasswordEye]="true"
                 placeholder="Aktuelles Passwort"
                 (keyup.enter)="inputPassword1.focus()"
      ></pfm-input>

      <label>Neues Passwort</label>
      <pfm-input #inputPassword1
                 [(value)]="newPassword1"
                 [autoTrim]="false"
                 [preventAutofill]="true"
                 [invalid]="isInvalid('newPassword1')"
                 type="password"
                 [showPasswordEye]="true"
                 placeholder="Neues Passwort"
                 (keyup.enter)="inputPassword2.focus()"
      ></pfm-input>

      <label>Neues Passwort wiederholen<span class="required">*</span></label>
      <pfm-input #inputPassword2
                 [(value)]="newPassword2"
                 [autoTrim]="false"
                 [preventAutofill]="true"
                 [invalid]="isInvalid('newPassword2')"
                 type="password"
                 [showPasswordEye]="true"
                 placeholder="Neues Passwort wiederholen"
      ></pfm-input>

      <pfm-button type="primary" [loading]="saveLoading2" (btnClick)="changePassword()">Speichern</pfm-button>

    </div>

  </main>
</frame-default>
