import {Component, Input} from '@angular/core';
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {Router} from "@angular/router";

@Component({
  selector: 'frame-default',
  templateUrl: './default-frame.component.html',
  styleUrls: ['./default-frame.component.scss']
})
export class DefaultFrameComponent {

  @Input() status: 'loading'|'error'|'content' = 'content';
  @Input() errorMessage: string = '';

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private router: Router)
  {
  }

  async logout() {
    this.auth.clearAuth();
    await this.router.navigate(['login']);
  }
}
