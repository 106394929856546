
<div class="custom-input">

  <div *ngIf="preIcon !== null" class="icon-pre" [class.is-placeholder]="innerValue === ''">
    <fa-icon [icon]="preIcon"></fa-icon>
  </div>

  <input #comp
         [class.has-icon-pre]="preIcon !== null"
         [ngModel]="innerValue"
         (ngModelChange)="innerValue = $event"
         [disabled]="disabled || autoFillDisabledOverride"
         [readonly]="readonly"
         [type]="(showPasswordEye && type === 'password' && !realPasswordHidden) ? 'text' : type"
         [placeholder]="placeholder"
         [attr.aria-invalid]="invalid"
         [attr.name]="preventAutofill ? uuidName : ''"
         [autocomplete]="preventAutofill ? (type === 'password' ? 'new-password' : uuidAutocomplete) : ''"
         [required]="required"
         (keyup.enter)="onEnterUp.emit($event)">

  <ng-container *ngIf="showPasswordEye && type === 'password'">
    <fa-icon *ngIf=" realPasswordHidden" class="password-eye eye-show" (click)="realPasswordHidden = false" [icon]="['fas', 'eye']"      ></fa-icon>
    <fa-icon *ngIf="!realPasswordHidden" class="password-eye eye-hide" (click)="realPasswordHidden = true"  [icon]="['fas', 'eye-slash']"></fa-icon>
  </ng-container>

</div>

