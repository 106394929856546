import { Injectable } from '@angular/core';
import { Subject, Observable } from "rxjs";
import * as uuid from "uuid";

export class PFMNotification {

  constructor(
    public id: string,
    public type: PFMNotificationType,
    public title: string,
    public message: string,
    public timeout: number|null,
  ) { }

}

export type PFMNotificationType = 'SUCCESS'|'WARNING'|'ERROR'|'INFO';

@Injectable()
export class NotificationService {

  private _subject = new Subject<PFMNotification>();

  constructor() { }

  getObservable(): Observable<PFMNotification> {
    return this._subject.asObservable();
  }

  info(title: string, message: string, timeout: number|null = 3000) {
    this._subject.next(new PFMNotification(uuid.v4(), 'INFO', title, message, timeout));
  }

  success(title: string, message: string, timeout: number|null = 3000) {
    this._subject.next(new PFMNotification(uuid.v4(), 'SUCCESS', title, message, timeout));
  }

  warning(title: string, message: string, timeout: number|null = 3000) {
    this._subject.next(new PFMNotification(uuid.v4(), 'WARNING', title, message, timeout));
  }

  error(title: string, message: string, timeout: number|null = 3000) {
    this._subject.next(new PFMNotification(uuid.v4(), 'ERROR', title, message, timeout));
  }

  notify(type: PFMNotificationType, title: string, message: string, timeout: number|null = 3000) {
    this._subject.next(new PFMNotification(uuid.v4(), type, title, message, timeout));
  }
}
