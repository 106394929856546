import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {isErrorCode} from "../../utils/api";
import {validateEmailRegex} from "../../utils/email";

@Component({
  selector: 'pfm-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  loading: boolean = false;
  errorTexts: string[] = [];

  username: string = '';
  email: string = '';
  company: string = '';
  password1: string = '';
  password2: string = '';

  invalid = new Set<string>();

  constructor(private router: Router, private api: APIService, private auth: AuthenticationService) {
    if (auth.isAuthenticated()) {
      void this.router.navigate(['/'], {queryParams: {}})
    }
  }
  ngOnInit() {
  }

  async signUp() {

    if (!this.validate()) return

    try {
      this.loading = true;

      const data = await this.api.register(this.username, this.email, this.password1, this.company);
      this.auth.setAuth(data.token);

      await this.router.navigate(['/'], {queryParams: {}});

    } catch (e: any) {
      if (isErrorCode(e, 'DATABASE_ERROR')) {
        this.errorTexts.push('Leider ist beim erstellen des Accounts etwas schiefgelaufen, versuchen Sie es nochmal.');
      } else if (isErrorCode(e, 'USERNAME_COLLISION')) {
        this.errorTexts.push('Leider ist der Benutzername bereits vergeben, ändern Sie ihn und versuchen sie es erneut.');
      } else if(isErrorCode(e, 'EMAIL_COLLISION')) {
        this.errorTexts.push('Leider ist die E-Mail bereits vergeben, ändern Sie sie und versuchen sie es erneut.');
      } else if (isErrorCode(e, 'INTERNAL_ERROR')) {
        this.errorTexts.push('Leider ist beim erstellen des Accounts etwas schiefgelaufen, versuchen Sie es nochmal.');
      } else if (isErrorCode(e, 'UNAUTHORIZED')) {
        this.errorTexts.push('Sie sind nicht berechtigt diese Aktion auszuführen.');
      } else {
        this.errorTexts.push('Leider ist beim erstellen des Accounts etwas schiefgelaufen, versuchen Sie es nochmal.');
      }
    } finally {
      this.loading = false;
    }
  }

  isInvalid(key: string) {
    return this.invalid.has(key) ? true : undefined;
  }

  validate(): boolean {
    this.errorTexts = [];
    this.invalid.clear();

    let anyerr = false;

    if (this.username.length < 4)          { this.errorTexts.push('Der Benutzername muss mindestens 4 Zeichen lang sein'); this.invalid.add('username');  anyerr = true; }
    if (this.password1.length < 8)         { this.errorTexts.push('Das Passwort muss mindestens 8 Zeichen lang sein');     this.invalid.add('password1'); anyerr = true; }
    if (!validateEmailRegex(this.email))   { this.errorTexts.push('Bitte eine gültige Email eingeben');                    this.invalid.add('email');     anyerr = true; }
    if (this.company.length < 4)           { this.errorTexts.push('Der Firmenname muss mindestens 4 Zeichen lang sein');   this.invalid.add('company');   anyerr = true; }
    if (this.password1 !== this.password2) { this.errorTexts.push('Beide Passwörter müssen übereinstimmen');               this.invalid.add('password2'); anyerr = true; }

    return !anyerr;
  }
}
