<frame-default [status]="status" errorMessage="Beim Laden der Projekte ist ein Fehler aufgetreten">
  <main>
    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;"
                     [crumbs]="[{text:'Seiten'}, {text:'Projekte', link: '/projects/list', active: true},]" ></pfm-breadcrumbs>


    <div class="statistics">
      <pfm-statistics-text icon="file-pen" title="Projekte insgesamt" [loading]="projectTotalCount  === null" [value]="projectTotalCount  ?? '...'"></pfm-statistics-text>
      <pfm-statistics-text icon="file-pen" title="Projekte intern"    [loading]="projectInternCount === null" [value]="projectInternCount ?? '...'"></pfm-statistics-text>
      <pfm-statistics-text icon="file-pen" title="Projekte extern"    [loading]="projectExternCount === null" [value]="projectExternCount ?? '...'"></pfm-statistics-text>
      <div class="spacer"></div>
      <pfm-button routerLink="/projects/create" icon="plus">Projekt anlegen</pfm-button>
    </div>

    <pfm-project-table #table [edit]="true" (filterSuccessfullyChanged)="manipulatePath($event.searchText, $event.filter)" ></pfm-project-table>
  </main>
</frame-default>
