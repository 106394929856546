import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TemplateIdent} from "../../interfaces";
import {APIService} from "../../services/api.service";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {isNgDiff} from "../../utils/angular";

@Component({
  selector: 'pfm-layout-text-chooser',
  templateUrl: './layout-text-chooser.component.html',
  styleUrls: ['./layout-text-chooser.component.scss']
})
export class LayoutTextChooserComponent implements OnInit, OnChanges {

  @Input() reference: TemplateIdent|null = null;

  @Input()  show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()  initialValue: string = '';

  @Output() success: EventEmitter<{ident:TemplateIdent, value:string}> = new EventEmitter<{ident:TemplateIdent, value:string}>();

  loading: boolean = false;

  value: string = '';

  constructor(private api: APIService,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (isNgDiff(changes, 'show') && this.show) {
      this.value = this.initialValue;
    }

    if (isNgDiff(changes, 'initialValue')) {
      this.value = this.initialValue;
    }
  }

  onOkay() {
    this.show = false;
    this.showChange.emit(false);

    if (this.reference === null) return;

    this.success.emit({"ident": this.reference, "value": this.value});
  }
}
