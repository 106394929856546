
<table class="type-{{type}}" [class.loading]="loading">
  <thead *ngIf="!loading">
    <tr>
      <th class="captialize" *ngFor="let coldef of coldefs">{{coldef.header}}</th>
    </tr>
  </thead>

  <tbody *ngIf="!loading">
    <tr *ngFor="let data of datasource" [class.clickable]="rowClickable" [class.hoverHighlight]="hoverHighlight">

      <ng-container *ngFor="let coldef of coldefs">
        <td class="widthmode-{{coldef.widthmode}}" (click)="onClick(data)">
          <ng-container *ngTemplateOutlet="coldef.contentTemplate;context:{item:data}"></ng-container>
        </td>
      </ng-container>

    </tr>
  </tbody>

  <tbody *ngIf="loading">
    <fa-icon *ngIf="loading" class="loader" icon="loader" animation="spin"></fa-icon>
  </tbody>

</table>
