import {Component, OnInit} from '@angular/core';
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService, PFMNotificationType} from "../../services/notification.service";
import {ModalService} from "../../services/modal.service";
import {sleep} from "../../utils/angular";

@Component({
  selector: 'pfm-controls-demo',
  templateUrl: './controls-demo.component.html',
  styleUrls: ['./controls-demo.component.scss']
})
export class ControlsDemoComponent implements OnInit {
  loginLoading: boolean = false;
  errorText: string = "";

  identifier: string = '';
  password: string = '';
  ephemeral1: boolean = false;
  ephemeral2: boolean = false;
  selval: string|null = null;
  radio: string|null = '';
  multiSelectValues = [
    {key: 'APPLE', value: 'Apple'},
    {key: 'BANANA', value: 'Banana'},
    {key: 'CHERRY', value: 'Cherry'},
    {key: 'DATE', value: 'Date'},
    {key: 'ELDERBERRY', value: 'Elderberry'},
    {key: 'FIG', value: 'Fig'},
    {key: 'GRAPE', value: 'Grape'},
    {key: 'HONEYDEW', value: 'Honeydew'},
    {key: 'IRIS', value: 'Iris'},
    {key: 'JASMINE', value: 'Jasmine'},
    {key: 'KIWI', value: 'Kiwi'},
    {key: 'LEMON', value: 'Lemon'},
    {key: 'MANGO', value: 'Mango'},
    {key: 'NECTARINE', value: 'Nectarine'},
    {key: 'ORANGE', value: 'Orange'},
    {key: 'PEAR', value: 'Pear'},
    {key: 'QUINCE', value: 'Quince'},
    {key: 'RASPBERRY', value: 'Raspberry'},
    {key: 'STRAWBERRY', value: 'Strawberry'},
    {key: 'TANGERINE', value: 'Tangerine'}
  ];
  multiSelectValues2 = [
    {"key": "H", "value": "H"},
    {"key": "He", "value": "He"},
    {"key": "Li", "value": "Li"},
    {"key": "Be", "value": "Be"},
    {"key": "B", "value": "B"},
    {"key": "C", "value": "C"},
    {"key": "N", "value": "N"},
    {"key": "O", "value": "O"},
    {"key": "F", "value": "F"},
    {"key": "Ne", "value": "Ne"},
    {"key": "Na", "value": "Na"},
    {"key": "Mg", "value": "Mg"},
    {"key": "Al", "value": "Al"},
  ];
  showModal1: boolean  = false;
  showModalSugg1: boolean  = false;
  showModalSelec1: boolean  = false;

  now: Date|null = new Date();
  d0: Date|null = null;

  tabidx: number = 0;

  constructor(private api: APIService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private auth: AuthenticationService,
              private modal: ModalService,
              private route: ActivatedRoute,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
    this.route.fragment.subscribe((fragment: string|null) => {
      this.tabidx = parseInt(fragment ?? '0') ?? 0;
    })
  }

  notify(type: PFMNotificationType, title: string, message: string, timeout: number|null) {
    this.notification.notify(type, title, message, timeout);
  }

  showDialog() {
    this.modal.openDialog({
      title: 'title',
      message: 'i am a message',
      type: 'alert'
    })
  }

  showModal1Timed(dur: number) {
    this.showModal1=true;
    sleep(dur).then(p => this.showModal1 = false);
  }

  setFragment(tabidx: number) {
    this.router.navigate(['/demo'], {fragment: `${tabidx}`}).then(() => {});
  }

  protected readonly alert = alert;
}
