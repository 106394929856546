import {Component, OnDestroy, OnInit} from '@angular/core';
import {CursorToken, ObjectID, Project} from "../../interfaces";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {debounce, interval, Subject, Subscription} from "rxjs";
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {ModalService} from "../../services/modal.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {BlobDataCompanyImage, BlobDataProjectImage, DataBlob, NewImageData} from "../../interfaces/datablob";
import {manipulateURLComponents} from "../../utils/url";
import {showAPIError} from "../../utils/api";
import {sleep} from "../../utils/angular";
import {blobToBase64} from "../../utils/blob";
import * as uuid from "uuid";
import {preloadImage} from "../../utils/image";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'pfm-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit, OnDestroy {

  selectedTab: 'PROJECT_IMAGES'|'COMPANY_IMAGES' = 'COMPANY_IMAGES';

  status: 'loading'|'error'|'content' = 'loading';
  loading: boolean = true;
  tableLoading: boolean = false;

  uploadPlaceholder: string[] = [];
  uploadingImages: NewImageData[] = [];

  imagesCompany: DataBlob<BlobDataCompanyImage>[] = [];
  imageCompanyCount: number|null = null;

  imageCompanyNextToken: CursorToken = "@start";

  imagesProject: DataBlob<BlobDataProjectImage>[] = [];
  imageProjectCount: number|null = null;

  imageProjectNextToken: CursorToken = "@start";

  searchObservable:   Subject<string>|null = null;
  searchSubscription: Subscription|null = null;

  filters: SerializedParamCollection = {
    search: { active: null, default: null, type: 'string' },
  };

  imageSaveLoading: Set<ObjectID> = new Set<ObjectID>();

  constructor(private api: APIService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: ModalService,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
    this.searchObservable   = new Subject<string>();
    this.searchSubscription = this.searchObservable.pipe(debounce(() => interval(500))).subscribe(() => {

      if (this.selectedTab == "COMPANY_IMAGES") {
        Promise.all([
          this.fetchCompanyImagesData(false, true),
          this.fetchCompanyImagesCount()
        ]).then(() => {});
      } else if (this.selectedTab == "PROJECT_IMAGES") {
        Promise.all([
          this.fetchProjectImagesData(false, true),
          this.fetchProjectImagesCount()
        ]).then(() => {});
      }

    });

    this.activatedRoute.params.subscribe(async uparams => {
      await this.initializeFromQueryParams(uparams, this.activatedRoute.snapshot.queryParams);
    })
    this.activatedRoute.queryParams.subscribe(async qparams => {
      await this.initializeFromQueryParams(this.activatedRoute.snapshot.params, qparams);
    });
  }

  ngOnDestroy() {
    this.searchSubscription?.unsubscribe()
    this.searchSubscription?.unsubscribe()
  }

  async initializeFromQueryParams(uparams: any, qparams: any) {
    this.selectedTab = (uparams['tabmode'] ?? this.selectedTab).toUpperCase();
    this.filters = SerializedParamUtil.ParameterFromPath(qparams, this.filters);

    if (this.selectedTab == "COMPANY_IMAGES") {
      await Promise.all([
        this.fetchCompanyImagesData(true, true),
        this.fetchCompanyImagesCount(),
        this.fetchProjectImagesCount(),
      ]);
    } else if (this.selectedTab == "PROJECT_IMAGES") {
      await Promise.all([
        this.fetchProjectImagesData(true, true),
        this.fetchCompanyImagesCount(),
        this.fetchProjectImagesCount(),
      ]);
    } else {
      this.status = 'error';
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['images', this.selectedTab.toLowerCase()], this.filters));
  }

  async fetchCompanyImagesData(initial: boolean, reset: boolean) {
    if (reset) {
      this.imageCompanyNextToken = '@start';
    }

    try {
      this.loading = true;
      if (initial) this.status = 'loading';

      const data = await this.api.listImages(this.imageCompanyNextToken, 24, {...this.filters, 'type': { active: 'COMPANY_IMAGE', default: null, type: 'string' }});

      if (reset) {
        this.imagesCompany = data.images;
      } else {
        this.imagesCompany = [...this.imagesCompany, ...data.images];
      }
      this.imageCompanyNextToken = data.nextPageToken;
      this.manipulatePath();

      if (initial) this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      if (initial) this.status = 'error';
    } finally {
      this.loading = false;
    }
  }

  async fetchCompanyImagesCount() {
    try {
      this.imageCompanyCount = null;
      const data = (await Promise.all([this.api.countImages({...this.filters, 'type': { active: 'COMPANY_IMAGE', default: null, type: 'string' }}), sleep(300)]))[0];
      this.imageCompanyCount = data.count;
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  async fetchProjectImagesData(initial: boolean, reset: boolean) {
    if (reset) {
      this.imageProjectNextToken = '@start';
    }

    try {
      this.loading = true;
      if (initial) this.status = 'loading';

      const data = await this.api.listImages(this.imageProjectNextToken, 24, {...this.filters, 'type': { active: 'PROJECT_IMAGE', default: null, type: 'string' }});

      if (reset) {
        this.imagesProject = data.images;
      } else {
        this.imagesProject = [...this.imagesProject, ...data.images];
      }
      this.imageProjectNextToken = data.nextPageToken;
      this.manipulatePath();

      if (initial) this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      if (initial) this.status = 'error';
    } finally {
      this.loading = false;
    }
  }

  async fetchProjectImagesCount() {
    try {
      this.imageProjectCount = null;
      const data = (await Promise.all([this.api.countImages({...this.filters, 'type': { active: 'PROJECT_IMAGE', default: null, type: 'string' }}), sleep(300)]))[0];
      this.imageProjectCount = data.count;
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
    }
  }

  async changeTabByIndex(v: number) {

    if (v === 0) this.selectedTab = 'COMPANY_IMAGES';
    if (v === 1) this.selectedTab = 'PROJECT_IMAGES';

    this.tableLoading = true;

    if (this.selectedTab == "COMPANY_IMAGES") {
      await Promise.all([
        this.fetchCompanyImagesData(false, true),
        this.fetchCompanyImagesCount(),
        sleep(200),
      ]).then(() => {});
    } else if (this.selectedTab == "PROJECT_IMAGES") {
      await Promise.all([
        this.fetchProjectImagesData(false, true),
        this.fetchProjectImagesCount(),
        sleep(200),
      ]).then(() => {});
    }

    this.tableLoading = false;

  }

  tabToHeader(t: "PROJECT_IMAGES" | "COMPANY_IMAGES") {
    switch (t) {
      case "COMPANY_IMAGES": return "Firmenbilder";
      case "PROJECT_IMAGES": return "Projektbilder";
    }
  }

  addUploadTile() {
    this.uploadPlaceholder = [ uuid.v4(), ...this.uploadPlaceholder]
  }

  async startUpload(upkey: string, files: File[]) {

    this.uploadPlaceholder = this.uploadPlaceholder.filter(p => p != upkey)

    for (const f of files) {

      const uniqid = uuid.v4();

      this.uploadingImages.push({
        uploaded: false,
        uuid: uniqid,
        id: null,
        progress: 0,
        filesize: f.size,
        data: {
          filename: f.name,
          caption: '',
          copyright: '',
          notes: '',
        }
      })

      void (async () => {

        try {

          const b64 = await blobToBase64(f);

          const data = await this.api.uploadCompanyImage(f.name, b64, f.type, {
            uploadProgress: (loaded: number, total: number | undefined) => {
              const progress = (total===undefined) ? 0 : loaded/total;
              this.uploadingImages = this.uploadingImages.map(p => (p.uuid !== uniqid) ? p : {...p, progress: progress });
            }
          });

          await preloadImage(`${environment.apiBaseUrl}company/${this.auth.getSelfCompanyID()}/images/${data.id}/thumb/${512}?xx-bearer-token=@${this.auth.getToken()}`);

          if (this.uploadingImages.filter(p => p.uuid === uniqid).length > 0) {
            this.uploadingImages = this.uploadingImages.filter(p => p.uuid !== uniqid);
            this.imagesCompany = [ data, ...this.imagesCompany ];
          } else {
            //aborted
          }


        } catch (err) {
          showAPIError(this.notification, 'Bild konnten nicht hochgeladen werden', err);
        } finally {
          this.uploadingImages = this.uploadingImages.filter(p => p.uuid !== uniqid);
        }
      })();

    }

  }

  async deleteCompanyImage(img: DataBlob<BlobDataCompanyImage>) {
    try {

      await this.api.deleteCompanyImage(img.id);

      this.notification.success('Erfolg!', 'Bild gelöscht');

      this.imagesCompany = this.imagesCompany.filter(p => p.id !== img.id);

    } catch (err) {
      showAPIError(this.notification, 'Bild konnte nicht geladen werden', err);
    }
  }

  abortUpload(uniqid: string) {
    this.uploadingImages = this.uploadingImages.filter(p => p.uuid !== uniqid);
  }

  tabToIndex(t: "PROJECT_IMAGES" | "COMPANY_IMAGES") {
    switch (t) {
      case "COMPANY_IMAGES": return 0;
      case "PROJECT_IMAGES": return 1;
    }
  }

  async saveCompanyImage(img: DataBlob<BlobDataCompanyImage>) {
    try {
      this.imageSaveLoading.add(img.id);
      const data = (await Promise.all([this.api.updateImageBlobMeta(img.id, img.data.copyright, img.data.notes, img.data.caption), sleep(300)]))[0];
      this.imagesCompany = this.imagesCompany.map(p => p.id === data.id ? data : p);
    } catch (err) {
      showAPIError(this.notification, 'Firmenbild konnten nicht gespeichert werden', err);
    } finally {
      this.imageSaveLoading.delete(img.id);
    }
  }

  async saveProjectImage(img: DataBlob<BlobDataCompanyImage>) {
    try {
      this.imageSaveLoading.add(img.id);
      const data = (await Promise.all([this.api.updateImageBlobMeta(img.id, img.data.copyright, img.data.notes, img.data.caption), sleep(300)]))[0];
      this.imagesProject = this.imagesProject.map(p => p.id === data.id ? data : p);
    } catch (err) {
      showAPIError(this.notification, 'Projektbild konnten nicht gespeichert werden', err);
    } finally {
      this.imageSaveLoading.delete(img.id);
    }
  }
}
