<div class="topbar">

  <pfm-button *ngIf="!showFilter" (btnClick)="showFilter=true" class="filter-btn" icon="filter-list">Tabelle filtern</pfm-button>
  <pfm-card *ngIf="showFilter" theme="component" class="filter-card">
    <div class="filter-card-content">

      <div class="filter-card-header">
        <fa-icon icon="filter-list"></fa-icon>
        <span>Tabelle filtern</span>
      </div>

      <div class="filter-card-data">

        <ng-container *ngIf="metavalues !== null">
          <ng-container *ngFor="let pfilter of filters; let idx = index">

            <pfm-select class="c1" placeholder="Filter" [canClear]="true" [value]="pfilter.fieldIdentifier | strToNStr" (valueChange)="filterIdentChanged($event, pfilter, idx); filterObservable?.next()" [values]="metavalues | fieldTypes"></pfm-select>

            <pfm-border *ngIf="pfilter.fieldIdentifier !== ''" [clickable]="true" (click)="rotateCompType(idx); filterObservable?.next()" class="c2">{{pfilter.comparisonType | compToStr}}</pfm-border>

            <ng-container *ngIf="pfilter.fieldIdentifier !== ''">

              <pfm-input  *ngIf="pfilter.fieldType === 'PROJECTID'"               [value]="pfilter.value" (valueChange)="pfilter.value = $event; filterObservable?.next()" (onEnterUp)="searchExec()" class="c3"></pfm-input>
              <pfm-input  *ngIf="pfilter.fieldType === 'SHORT_STRING'"            [value]="pfilter.value" (valueChange)="pfilter.value = $event; filterObservable?.next()" (onEnterUp)="searchExec()" class="c3"></pfm-input>
              <pfm-input  *ngIf="pfilter.fieldType === 'LONG_STRING'"             [value]="pfilter.value" (valueChange)="pfilter.value = $event; filterObservable?.next()" (onEnterUp)="searchExec()" class="c3"></pfm-input>
              <pfm-input  *ngIf="pfilter.fieldType === 'FUNCTIONCONTACT_SERVICE'" [value]="pfilter.value" (valueChange)="pfilter.value = $event; filterObservable?.next()" (onEnterUp)="searchExec()" class="c3"></pfm-input>

              <pfm-select *ngIf="pfilter.fieldType === 'KEY_STRING'" [value]="pfilter.value | strToNStr" (valueChange)="pfilter.value = ($event??''); filterObservable?.next()" [values]="pfilter.selectValues" class="c3"></pfm-select>
              <pfm-select *ngIf="pfilter.fieldType === 'STR_ARRAY'"  [value]="pfilter.value | strToNStr" (valueChange)="pfilter.value = ($event??''); filterObservable?.next()" [values]="pfilter.selectValues" class="c3"></pfm-select>

              <pfm-switch *ngIf="pfilter.fieldType === 'BOOL'" [value]="pfilter.value === 'true'" (valueChange)="pfilter.value = ($event?'true':'false'); filterObservable?.next()" class="c3 switch"></pfm-switch>

              <pfm-select *ngIf="pfilter.fieldType === 'STR_ENUM'"     [value]="pfilter.value | strToNStr" (valueChange)="pfilter.value = ($event??''); filterObservable?.next()"  class="c3" [values]="pfilter.selectValues"></pfm-select>
              <pfm-select *ngIf="pfilter.fieldType === 'OPT_STR_ENUM'" [value]="pfilter.value | strToNStr" (valueChange)="pfilter.value = ($event??''); filterObservable?.next()"  class="c3" [values]="pfilter.selectValues"></pfm-select>
              <pfm-select *ngIf="pfilter.fieldType === 'CONTACT_ID'"   [value]="pfilter.value | strToNStr" (valueChange)="pfilter.value = ($event??''); filterObservable?.next()"  class="c3" [values]="pfilter.selectValues"></pfm-select>
              <pfm-select *ngIf="pfilter.fieldType === 'USERID'"       [value]="pfilter.value | strToNStr" (valueChange)="pfilter.value = ($event??''); filterObservable?.next()"  class="c3" [values]="pfilter.selectValues"></pfm-select>

              <pfm-dateinput *ngIf="pfilter.fieldType === 'OPT_TIME'" [value]="pfilter.value | strToNDate" (valueChange)="pfilter.value = nullableDateToStr($event); filterObservable?.next()" class="c3"></pfm-dateinput>
              <pfm-dateinput *ngIf="pfilter.fieldType === 'TIME'"     [value]="pfilter.value | strToNDate" (valueChange)="pfilter.value = nullableDateToStr($event); filterObservable?.next()" class="c3"></pfm-dateinput>

              <pfm-numberinput  *ngIf="pfilter.fieldType === 'OPT_FLOAT64'"   [value]="pfilter.value | strToNFloat" (valueChange)="pfilter.value = nullableFloatToStr($event); filterObservable?.next()" [unit]="pfilter.fieldIdentifier | filterUnit" class="c3"></pfm-numberinput>
              <pfm-numberinput  *ngIf="pfilter.fieldType === 'OPT_MONEYCENT'" [value]="pfilter.value | strToNFloat" (valueChange)="pfilter.value = nullableFloatToStr($event); filterObservable?.next()" [unit]="pfilter.fieldIdentifier | filterUnit" class="c3"></pfm-numberinput>

            </ng-container>

          </ng-container>
        </ng-container>

      </div>

    </div>
  </pfm-card>

  <pfm-input class="searchfield"
             [preIcon]="['fas', 'magnifying-glass']"
             placeholder="Tabelle durchsuchen"
             [value]="searchText"
             (valueChange)="searchText = $event; filterObservable?.next()"
             [autoTrim]="false"
             (onEnterUp)="searchExec()" ></pfm-input>

</div>

<pfm-card header="Projekte">

  <pfm-table [loading]="loading" [datasource]="projects" [hoverHighlight]="true" [rowClickable]="singleSelect || multiSelect" (rowClicked)="onRowClicked($event)">
    <pfm-table-col *ngIf="singleSelect" header="">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">
        <pfm-checkbox (valueChange)="onChangeSingleSelect(item, $event)" [value]="selectedProject === item.id"></pfm-checkbox>
      </ng-template>
    </pfm-table-col>

    <pfm-table-col *ngIf="multiSelect" header="">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">
        <pfm-checkbox (valueChange)="onChangeMultiSelect(item, $event)" [value]="selectedProjects.includes(item.id)"></pfm-checkbox>
      </ng-template>
    </pfm-table-col>

    <pfm-table-col header="Nummer">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">{{item.projectNumber}}</ng-template>
    </pfm-table-col>

    <pfm-table-col header="Name">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">{{item.projectName}}</ng-template>
    </pfm-table-col>

    <pfm-table-col header="Klassifikation">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">{{translateClassification(item.classification)}}</ng-template>
    </pfm-table-col>

    <pfm-table-col header="Erstelldatum">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">{{item.creationTime | date:'yyyy-MM-dd'}}</ng-template>
    </pfm-table-col>

    <pfm-table-col header="Änderungsdatum">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">{{item.lastUpdated | date:'yyyy-MM-dd'}}</ng-template>
    </pfm-table-col>

    <pfm-table-col header="Abgeschlossen am">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">{{item.completion | date: 'yyyy-MM-dd'}}</ng-template>
    </pfm-table-col>

    <pfm-table-col *ngIf="edit" header="">
      <ng-template [ctxType]="{item:projects[0]!}" let-item="item">
        <pfm-context-menu>
          <pfm-context-menu-item [icon]="['fat', 'pen']"                  (onClick)="editProject(item)"   >Bearbeiten</pfm-context-menu-item>
          <pfm-context-menu-item [icon]="['fat', 'table-layout']"         (onClick)="openExpose(item)"    >Layout</pfm-context-menu-item>
          <pfm-context-menu-item [icon]="['fat', 'trash-can']"            (onClick)="deleteProject(item)" >Löschen</pfm-context-menu-item>
        </pfm-context-menu>
      </ng-template>
    </pfm-table-col>

  </pfm-table>

</pfm-card>
