import {Component, HostListener, OnInit} from '@angular/core';
import {DialogModal, GenericModal, Modal, ModalService, ModalWrapper} from "../../services/modal.service";
import {filter, Subscription} from "rxjs";
import {NavigationEnd, Router} from "@angular/router";

@Component({
  selector: 'pfm-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent implements OnInit {

  allModals: {wrapper:ModalWrapper, modal:Modal}[] = []
  dialogModals: {wrapper:ModalWrapper, modal:DialogModal}[] = []
  genericModals: {wrapper:ModalWrapper, modal:GenericModal}[] = []

  sub1: Subscription|null = null;
  sub2: Subscription|null = null;

  constructor(private modal: ModalService,
              private router: Router) {
    router.events.pipe(filter(p => p instanceof NavigationEnd)).subscribe(() => this.onRouteChanged());
  }

  ngOnInit() {
    this.sub1 = this.modal.getCreateModalObservable().subscribe((w) => {

      if (w.type === "dialog")  this.dialogModals.push({wrapper:w, modal:<DialogModal>w.modal});
      if (w.type === "generic") this.genericModals.push({wrapper:w, modal:<GenericModal>w.modal});

      this.allModals.push({wrapper:w, modal:w.modal});

    })
    this.sub2 = this.modal.getCloseModalObservable().subscribe((w) => {

      let wrapper = this.allModals.find(p => p.wrapper.id === w)?.wrapper;

      if (wrapper) {
        if (wrapper.modal.onClose !== undefined) wrapper.modal.onClose();
        this.closeModal(wrapper);
      }

    })
  }

  closeModal(modal: ModalWrapper) {
    if (this.dialogModals.find(v => v.wrapper.id === modal.id))
      this.dialogModals = this.dialogModals.filter(el => el.wrapper.id !== modal.id);
    else if (this.genericModals.find(v => v.wrapper.id === modal.id))
      this.genericModals = this.genericModals.filter(el => el.wrapper.id !== modal.id);


    this.allModals = this.allModals.filter(el => el.wrapper.id !== modal.id);
  }

  dialogAbortClicked(v: {wrapper:ModalWrapper, modal:DialogModal}) {
    if (v.modal.onCancel !== undefined) v.modal.onCancel();
    if (v.modal.onClose !== undefined)  v.modal.onClose();
    this.closeModal(v.wrapper);
  }

  dialogConfirmClicked(v: {wrapper:ModalWrapper, modal:DialogModal}) {
    if (v.modal.onSubmit !== undefined) v.modal.onSubmit();
    if (v.modal.onClose !== undefined)  v.modal.onClose();
    this.closeModal(v.wrapper);
  }

  onBackgroundClick(evt: MouseEvent, bg: HTMLDivElement) {

    if (evt.target !== bg) return;

    for (const v of this.dialogModals.reverse()) {
      if (v.modal.closeOnBackgroundClick ?? true) {
        if (v.modal.onClose !== undefined) v.modal.onClose();
        this.closeModal(v.wrapper);
        return
      }
    }
    for (const v of this.genericModals.reverse()) {
      if (v.modal.closeOnBackgroundClick ?? true) {
        if (v.modal.onClose !== undefined) v.modal.onClose();
        this.closeModal(v.wrapper);
        return
      }
    }
  }

  position_left(modal: GenericModal, container: HTMLDivElement, bg: HTMLDivElement) {
    if (modal.position === undefined) return undefined;
    const v = modal.position();

    if (v.corner === 'tr' || v.corner === 'br') return undefined;

    let r = v.x;
    if (v.limitToBounds && r < 0) r = 0;
    if (v.limitToBounds && r > bg.offsetWidth-container.offsetWidth) r = bg.offsetWidth-container.offsetWidth;
    return r;
  }

  position_top(modal: GenericModal, container: HTMLDivElement, bg: HTMLDivElement) {
    if (modal.position === undefined) return undefined;
    const v = modal.position();

    if (v.corner === 'br' || v.corner === 'bl') return undefined;

    let r = v.y;
    if (v.limitToBounds && r < 0) r = 0;
    if (v.limitToBounds && r > bg.offsetHeight-container.offsetHeight) r = bg.offsetHeight-container.offsetHeight;
    return r;
  }

  position_right(modal: GenericModal, container: HTMLDivElement, bg: HTMLDivElement) {
    if (modal.position === undefined) return undefined;
    const v = modal.position();

    if (v.corner === 'tl' || v.corner === 'bl') return undefined;

    let r = bg.offsetWidth - v.x;
    if (v.limitToBounds && r < 0) r = 0;
    if (v.limitToBounds && r > bg.offsetWidth-container.offsetWidth) r = bg.offsetWidth-container.offsetWidth;
    return r;
  }

  position_bottom(modal: GenericModal, container: HTMLDivElement, bg: HTMLDivElement) {
    if (modal.position === undefined) return undefined;
    const v = modal.position();

    if (v.corner === 'tr' || v.corner === 'tl') return undefined;

    let r = bg.offsetHeight - v.y;
    if (v.limitToBounds && r < 0) r = 0;
    if (v.limitToBounds && r > bg.offsetHeight-container.offsetHeight) r = bg.offsetHeight-container.offsetHeight;
    return r;
  }

  needsBlur(dialogModals: { wrapper: ModalWrapper; modal: DialogModal }[], genericModals: { wrapper: ModalWrapper; modal: GenericModal }[]) {
    return dialogModals.length > 0 || genericModals.find(m => m.modal.blur??true)
  }

  onRouteChanged() {
    const shouldCloseModals = this.allModals.filter(p => p.modal.closeOnPageChange??false);
    for (const modal of shouldCloseModals) this.closeModal(modal.wrapper);
  }

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      for (const v of this.dialogModals.reverse()) {
        if (v.modal.closeOnEscapePress ?? true) {
          if (v.modal.onClose !== undefined) v.modal.onClose();
          this.closeModal(v.wrapper);
          return
        }
      }
      for (const v of this.genericModals.reverse()) {
        if (v.modal.closeOnEscapePress ?? true) {
          if (v.modal.onClose !== undefined) v.modal.onClose();
          this.closeModal(v.wrapper);
          return
        }
      }
    }
  }
}
