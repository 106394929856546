import {Component, ContentChildren, EventEmitter, Input, Output, QueryList} from '@angular/core';
import {TabComponent} from "../tabs/tab.component";
import {ToggleValueComponent} from "./toggle-value.component";

@Component({
  selector: 'pfm-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent {

  @ContentChildren(ToggleValueComponent) values!: QueryList<ToggleValueComponent>;

  @Input() selectedIndex: number = 0;
  @Output() selectedIndexChange: EventEmitter<number> = new EventEmitter<number>();

}
