import {Component, OnInit} from '@angular/core';
import {AuthenticationService} from "../../services/authentication.service";
import {Company, User} from "../../interfaces";
import {APIService} from "../../services/api.service";
import {showAPIError} from "../../utils/api";
import {NotificationService} from "../../services/notification.service";

@Component({
  selector: 'pfm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  user: User|null = null;
  company: Company|null = null

  projectTotalCount: number = 0;

  constructor(private auth: AuthenticationService,
              private notification: NotificationService,
              private api: APIService) {
  }

  async ngOnInit() {
    try {
      Promise.all([
        this.auth.getSelf().then(v => this.user = v),
        this.fetchProjectsCount(),
        this.fetchCompany(),
      ]).then(() => {});
    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err);
    }
  }

  async fetchUser() {
    try {
      const id = this.auth.getSelfID();
      if (id === null) {
        this.notification.error('Fehler', 'Nicht eingeloggt');
        return;
      }
      this.user = await this.api.getUser(id)
    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err);
    }
  }

  async fetchCompany() {
    try {
      this.company = await this.api.getCompany()
    } catch (err) {
      showAPIError(this.notification, 'Firma konnte nicht geladen werden', err);
    }
  }

  async fetchProjectsCount() {
    try {
      const data = await this.api.getProjectsCount()
      this.projectTotalCount = data.count;
    } catch (err) {
      showAPIError(this.notification, 'Projekte konnten nicht geladen werden', err);
    }
  }
}
