import {Component, HostListener} from '@angular/core';
import {
  EnumMetaValue,
  isValidationError,
  ObjectID,
  Portfolio,
  Project,
  ProjectEnums,
  TemplateIdent
} from "../../interfaces";
import {ValidationRef} from "../../interfaces/validation";
import {APIService} from "../../services/api.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {NotificationService} from "../../services/notification.service";
import {showAPIError} from "../../utils/api";
import {deepclone} from "../../utils/angular";
import {environment} from "../../../environments/environment";
import {PortfolioTemplateConfiguration} from "../../interfaces/templateConfig";
import {Expose} from "../../interfaces/expose";
import {MetaService} from "../../services/meta.service";

@Component({
  selector: 'pfm-portfolio-editcreate',
  templateUrl: './portfolio-editcreate.component.html',
  styleUrls: ['./portfolio-editcreate.component.scss']
})
export class PortfolioEditCreateComponent {

  mode: 'CREATE' | 'EDIT' = 'CREATE';
  editPortfolioID: ObjectID|null = null;
  serverData: Portfolio|null = null;

  status: 'loading' | 'error' | 'content' = 'loading';

  selectedTab = 0;

  metavalues: ProjectEnums|null = null;
  allprojects: Project[]|null = null;

  projectChooserValue: ObjectID|null = null;

  selectedTemplate: TemplateIdent|null = null;
  selectedProjects: ObjectID[] = [];

  name: string = '';

  config: PortfolioTemplateConfiguration = {
    mainImages:    {},
    mainTexts:     {},
    projectImages: {},
    projectTexts:  {},
  }

  _serverData: any|null = null;

  validationErrors: Set<ValidationRef> = new Set<ValidationRef>();

  downloadPortfolio: Portfolio|null = null;

  constructor(private api: APIService,
              private meta: MetaService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private auth: AuthenticationService,
              private notification: NotificationService) {
  }

  ngOnInit(): void {
    if (this.activatedRoute.snapshot.data['mode'] === 'CREATE') {
      this.editPortfolioID = null;
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchData(this.editPortfolioID).then(() => {});
    } else if (this.activatedRoute.snapshot.data['mode'] === 'EDIT') {
      this.editPortfolioID = this.activatedRoute.snapshot.params['pfid'];
      this.mode = this.activatedRoute.snapshot.data['mode'];
      this.fetchData(this.editPortfolioID).then(() => {});
    } else {
      this.status = 'error';
      this.notification.error('Fehler', 'Komponente ist in einem invaliden Zustand');
    }
  }

  @HostListener('window:beforeunload')
  hasNoPendingChanges = (): boolean => {
    return JSON.stringify(this.getPortfolioData()) === JSON.stringify(this._serverData);
  }

  async fetchData(portfolioid: ObjectID|null) {
    try {
      this.status = 'loading';

      this.metavalues = await this.meta.getProjectEnums();
      this.allprojects = (await this.api.listProjects("@start", null)).projects.filter(p => p.classification === null || p.classification === 'INTERN' || p.classification === 'EXTERN');

      const qpTemplate = this.activatedRoute.snapshot.queryParams['template'];
      if (qpTemplate !== undefined) {
        if (this.metavalues.portfolioTemplate.find(p => p.value === qpTemplate) != undefined) {
          this.selectedTemplate = qpTemplate;
        }
      }

      if (portfolioid !== null) {
        const portfolio = await this.api.getPortfolio(portfolioid);
        await this.setAllValues(portfolio);
      }

      this.status = 'content';
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err);
      this.status = 'error';
    }
  }

  async setAllValues(portfolio: Portfolio) {

    this.serverData = deepclone(portfolio);

    this.selectedTemplate = portfolio.template;
    this.selectedProjects = portfolio.projectIDs;
    this.name             = portfolio.name;
    this.config           = deepclone(portfolio.templateConfig);

    this._serverData = this.getPortfolioData();
  }

  async createOrUpdatePortfolio(navigate = false) {
    if (this.mode === 'CREATE') await this.createPortfolio(navigate);
    if (this.mode === 'EDIT')   await this.updatePortfolio(navigate);
  }

  async createPortfolio(navigate: boolean) {
    this.validationErrors.clear();

    if (this.name             === '')         { this.validationErrors.add('PORTFOLIO_NAME');  }
    if (this.selectedProjects === null)       { this.validationErrors.add('PROJECT_ID');      }
    if (this.selectedProjects.length === 0)   { this.validationErrors.add('PROJECT_ID');      }
    if (this.selectedTemplate === null)       { this.validationErrors.add('TEMPLATE_KEY');    }

    if (this.validationErrors.size > 0) return;

    try {
      let data = await this.api.createPortfolio(this.name, this.selectedProjects, this.selectedTemplate!, this.config);

      if (navigate) {
        await this.router.navigate(['/portfolios/list']);
      } else {
        await this.setAllValues(data);
        this._serverData = this.getPortfolioData();
        this.notification.success("Portfolio gespeichert!",'')
      }

    } catch (err) {
      if (isValidationError(err, 'PORTFOLIO_VALIDATION_FAILED')) {
        this.notification.error('Portfolio konnten nicht erstellt werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference as ValidationRef);
      } else {
        showAPIError(this.notification, 'Portfolio konnten nicht erstellt werden', err);
      }
    }
  }

  async updatePortfolio(navigate: boolean) {
    if (this.editPortfolioID === null) return;

    this.validationErrors.clear();

    if (this.name             === '')         { this.validationErrors.add('PORTFOLIO_NAME');  }
    if (this.selectedProjects === null)       { this.validationErrors.add('PROJECT_ID');   }
    if (this.selectedProjects.length === 0)   { this.validationErrors.add('PROJECT_ID');   }
    if (this.selectedTemplate === null)       { this.validationErrors.add('TEMPLATE_KEY'); }

    if (this.validationErrors.size > 0) return;

    try {
      let data = await this.api.updatePortfolio(this.editPortfolioID, this.name, this.selectedProjects, this.selectedTemplate!, this.config);

      if (navigate) {
        await this.router.navigate(['/portfolios/list']);
      } else {
        await this.setAllValues(data);
        this._serverData = this.getPortfolioData();
        this.notification.success("Portfolio gespeichert!",'')
      }

    } catch (err) {
      if (isValidationError(err, 'PORTFOLIO_VALIDATION_FAILED')) {
        this.notification.error('Portfolio konnten nicht bearbeitet werden', err.error.extra.message);
        this.validationErrors.add(err.error.extra.reference);
      } else {
        showAPIError(this.notification, 'Portfolio konnten nicht bearbeitet werden', err);
      }
    }
  }

  getPortfolioData() {
    return {
      'id':       this.editPortfolioID,
      'name':     this.name,
      'projects': this.selectedProjects,
      'template': this.selectedTemplate,
      'config':   this.config,
    }
  }

  projectsToKV(v: Project[]) {
    return v.map(p => ({key: p.id, value: p.projectName}));
  }

  async getExposes(projID: ObjectID) {
    const res = await this.api.listProjectExposes(projID, "@start", null);
     return res.exposes
  }

  metaToKeyValue(v: EnumMetaValue<string, string>[]) {
    return v.map(p => ({key: p.value, value: p.description}));
  }

  metaToKeyValueExt(vSelected: string|null, vAll: EnumMetaValue<string, string>[], vLim: EnumMetaValue<string, string>[]) {
    if (vSelected !== null && !vLim.find(p => p.value === vSelected) && vAll.find(p => p.value === vSelected)) {
      // a enumValue is selected that only exists in [vAll], add it to the select values for now
      return vLim.map(p => ({key: p.value, value: p.description})).concat(({key: vSelected, value: vAll.find(p => p.value === vSelected)!.description}));
    } else {
      return vLim.map(p => ({key: p.value, value: p.description}));
    }
  }

  validate(...args: ValidationRef[]): boolean|undefined {
    return args.find(p => this.validationErrors.has(p))  ? true : undefined;
  }

  resolveProject(pid: ObjectID): Project|null {
    return this.allprojects?.find(p => p.id === pid) ?? null;
  }

  removeProject(pid: ObjectID) {
    this.selectedProjects = this.selectedProjects.filter(p => p != pid);

    delete this.config.projectImages[pid];
    delete this.config.projectTexts[pid];
  }

  async addProject(pid: ObjectID|null) {
    if (pid === null) return;

    const res = await this.getExposes(pid)

    if (!this.selectedProjects.includes(pid)) this.selectedProjects = [...this.selectedProjects, pid];
    for (const p of this.selectedProjects) {
      if (this.config.projectImages[p] === undefined) {
        if (res.length === 0) this.config.projectImages[p] = {};
        else {
          const first = res[0];
          this.config.projectImages[p] = first.templateConfig.images
        }
      }
      if (this.config.projectTexts[p]  === undefined) this.config.projectTexts[p]  = {};
    }



    setTimeout(() => {this.projectChooserValue = null;}, 0); // weird hack???
  }

  castObjectID(v: string|null) {
    return v as (ObjectID|null);
  }
}
