
<pfm-layout-image-chooser [projectid]="projectid"
                          [projectids]="projectids"
                          [(show)]="imgChooserShow"
                          [reference]="imgChooserRef"
                          (success)="imageChosen($event.ident, $event.blobid)"></pfm-layout-image-chooser>

<pfm-layout-text-chooser [(show)]="txtChooserShow"
                         [reference]="txtChooserRef"
                         [initialValue]="txtChooserValue"
                         (success)="textChosen($event.ident, $event.value)"></pfm-layout-text-chooser>

<pfm-modal [show]="cropFile !== null" (showChange)="hideCropperModal($event)">

  <div class="cropper-content" *ngIf="cropFile !== null">
    <h2>Bild beschneiden</h2>
    <image-cropper #cropper
                   [imageURL]="cropFile.url"
                   [maintainAspectRatio]="true"
                   [aspectRatio]="cropFile.ratio"
                   [autoCrop]="false"
                   style="width: 475px; --cropper-outline-color: rgba(255, 255, 255, .75)"
                   format="png"
                   (cropperReady)="loadCropperReady(cropFile.ident, cropFile.blobid, cropFile.initial, cropper, $event)"
                   (loadImageFailed)="loadCropperImageFailed()"></image-cropper>
    <pfm-button style="justify-self: right" type="primary" (btnClick)="onCrop(cropFile.ident, cropFile.blobid, cropper)">Okay</pfm-button>
  </div>
</pfm-modal>

<div *ngIf="template === 'LAYOUT_EXPOSE_DEMO'" class="pagelist">

  <div class="page-a4">

    <pfm-template-block type="generic-text"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-145, 25, 135, 107.2]"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-20, 5, 15, 15]"
                        ident="logo"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[10, 151, 190, 126]"
                        ident="page_1_main"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

  </div>

  <div class="page-a4">

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[10, 15, 90, 126]"
                        ident="page_2_tl"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[110, 15, 90, 58]"
                        ident="page_2_tr_1"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[110, 83, 90, 58]"
                        ident="page_2_tr_2"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[10, 151, 109, 126]"
                        ident="page_2_bb"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

  </div>

</div>

<div *ngIf="template === 'LAYOUT_EXPOSE_ISENMANN'" class="pagelist">

  <div class="page-a4">

    <pfm-template-block type="generic-text"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[20, 10, 100, 20]"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-20, 5, 15, 15]"
                        ident="logo"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[30, 40, 180, 60]"
                        ident="page_1_main"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-60, 120, 60, 40]"
                        ident="page_1_sub_1"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-60, 180, 60, 40]"
                        ident="page_1_sub_2"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-60, 240, 60, 40]"
                        ident="page_1_sub_3"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="generic-text"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[20, 120, 100, 160]"></pfm-template-block>

  </div>

</div>


<div *ngIf="template === 'LAYOUT_PROJECTLIST_DEMO'" class="pagelist">

  <div class="page-a4">

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-20, 5, 15, 15]"
                        ident="logo"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

  </div>

</div>

<div *ngIf="template === 'LAYOUT_PROJECTLIST_ISENMANN'" class="pagelist">

  <div class="page-a4">

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-20, 5, 15, 15]"
                        ident="logo"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="generic-text"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[20, 50, 170, 200]"
                        ident="logo"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

  </div>

</div>


<div *ngIf="template === 'LAYOUT_PORTFOLIO_DEMO' && mainPage" class="pagelist">

  <div class="page-a4">

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-20, 5, 15, 15]"
                        ident="logo"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-145, 25, 135, 107.2]"
                        ident="main_1"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[10, 151, 190, 126]"
                        ident="main_2"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

  </div>

</div>

<div *ngIf="template === 'LAYOUT_PORTFOLIO_DEMO' && !mainPage" class="pagelist">

  <div class="page-a4">

    <pfm-template-block type="text-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[10, 10, 190, 20]"
                        ident="proj_text"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[10, 40, 190, 247]"
                        ident="proj_main"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

  </div>

</div>

<div *ngIf="template === 'LAYOUT_PORTFOLIO_ISENMANN' && mainPage" class="pagelist">

  <!-- empty -->

</div>

<div *ngIf="template === 'LAYOUT_PORTFOLIO_ISENMANN' && !mainPage" class="pagelist">

  <div class="page-a4">

    <pfm-template-block type="generic-text"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[20, 10, 100, 20]"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-20, 5, 15, 15]"
                        ident="logo"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[30, 40, 180, 60]"
                        ident="page_1_main"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-60, 120, 60, 40]"
                        ident="page_1_sub_1"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-60, 180, 60, 40]"
                        ident="page_1_sub_2"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="image-insert"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[-60, 240, 60, 40]"
                        ident="page_1_sub_3"
                        (editText)="onEditText($event.ident, $event.startValue)"
                        (editImage)="onEditImage($event.ident, $event.startValue)"
                        (cropImage)="onCropImage($event.ident, $event.currValue, $event.currURL, $event.imgwidth, $event.imgheight)"></pfm-template-block>

    <pfm-template-block type="generic-text"
                        [(images)]="images"
                        [(texts)]="texts"
                        [position]="[20, 120, 100, 160]"></pfm-template-block>

  </div>

</div>
