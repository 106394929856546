<div class="avatar">
  <input #fileUpload
         type="file"
         style="display: none"
         accept=".jpeg,.jpg,.png,.gif"
         [multiple]="false"
         (change)="onImageSelected($event)">

  <img     *ngIf="!loading && selectedImageDataURL === null && defaultImage !== null"  [class.round]="round" [class.square]="!round" [ngSrc]="defaultImage"         alt="image" [fill]="true" (click)="fileUpload.click()">
  <img     *ngIf="!loading && selectedImageDataURL !== null"                           [class.round]="round" [class.square]="!round" [ngSrc]="selectedImageDataURL" alt="image" (click)="fileUpload.click()">
  <fa-icon *ngIf="loading" class="icon" [icon]="['fas', 'loader']" animation="spin"></fa-icon>

  <div (click)="fileUpload.click()" class="uploadbutton">
    <div class="iconborder">
      <fa-icon  class="icon"  [icon]="['fas', 'upload']"></fa-icon>
    </div>
  </div>

</div>
