import {HttpErrorResponse} from '@angular/common/http';
import {ApiErrorCode, isAPIError} from "../interfaces";
import {NotificationService} from "../services/notification.service";

export function showAPIError(ns: NotificationService, txt: string, err: any) {

  console.error('[[API-ERROR]]', err);

  if (!isAPIError(err)) {
    ns.error('Fehler', txt + '\n\n' + 'Ein unbekannter Fehler ist aufgetreten');
    return
  }

  if (isErrorCode(err, ['NOT_IMPLEMENTED', 'INTERNAL_ERROR', 'PANIC', 'DATABASE_ERROR', 'FILESYSTEM_ERROR', 'MAILER_ERROR', 'DATA_CONSISTENCY_ERROR'])) {
    ns.error('Fehler', txt + '\n\n' + 'Es ist ein interner Fehler auf dem Server aufgetreten');
    return
  }

  if (isErrorCode(err, 'SELF_DELETE')) {
    ns.error('Fehler', txt + '\n\n' + 'Der aktuelle Benutzer kann nicht gelöscht werden');
    return
  }

  if (isErrorCode(err, 'USERNAME_COLLISION')) {
    ns.error('Fehler', txt + '\n\n' + 'Der Benutzername ist bereits vergeben');
    return
  }

  if (isErrorCode(err, 'EMAIL_COLLISION')) {
    ns.error('Fehler', txt + '\n\n' + 'Die Mail Adresse ist bereits vergeben');
    return
  }

  if (isErrorCode(err, 'MISSING_PERMISSIONS')) {
    ns.error('Fehler', txt + '\n\n' + 'Für diese Aktion hat der aktuelle Benutzer keine ausreichenden Berechtigungen');
    return
  }

  if (isErrorCode(err, 'WRONG_OLD_PASSWORD')) {
    ns.error('Fehler', txt + '\n\n' + 'Das aktuelle Passwort ist nicht korrekt');
    return
  }

  if (isErrorCode(err, ['UNAUTHORIZED', 'AUTH_FAILED'])) {
    ns.error('Fehler', txt + '\n\n' + 'AUthentifizierung mit dem Server fehlgeschlagen');
    return
  }

  if (isErrorCode(err, ['CONTACT_IN_USE'])) {
    ns.error('Fehler', txt + '\n\n' + 'Der Kontakt wird noch verwendet');
    return
  }

  if (isErrorCode(err, ['PROJECT_IN_USE'])) {
    ns.error('Fehler', txt + '\n\n' + 'Das Projekt wird noch verwendet');
    return
  }

  if (isErrorCode(err, ['IMAGE_IN_USE'])) {
    ns.error('Fehler', txt + '\n\n' + 'Das Bild wird noch verwendet');
    return
  }

  ns.error('Fehler', txt + '\n\n' + formatAPIError(err)); // fallback show server message
}

export function formatAPIError(err: any): string {
  console.error('[[API-ERROR]]', err);

  if (!(err instanceof HttpErrorResponse)) {
    return "API Request fehlgeschlagen";
  }

  if (!isAPIError(err)) {
    return err.message;
  }

  return err.error.message;
}

export function isErrorCode(err: any, errcode: ApiErrorCode | ApiErrorCode[]): boolean {
  if (!isAPIError(err)) return false;

  if (typeof errcode === 'string') return err.error.errorcode === errcode;

  return errcode.some(p => err.error.errorcode === p);
}
