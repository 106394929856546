
<input #comp
       [ngModel]="innervalue"
       (ngModelChange)="innervalue = $event; onValueInput($event)"
       (blur)="blurTriggered()"
       [disabled]="disabled"
       [readonly]="readonly"
       type="text"
       [min]="min"
       [max]="max"
       [step]="step"
       [placeholder]="placeholder"
       [attr.aria-invalid]="invalid"
       [class.withunit]="unit != ''"
       (keydown)="onKeyDown($event)"
       (keyup.enter)="onEnterUp.emit($event)">

<span class="unit" *ngIf="unit != ''">{{unit}}</span>
