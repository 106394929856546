<pfm-download-expose-modal [(expose)]="downloadExpose"></pfm-download-expose-modal>

<frame-default [status]="status" errorMessage="Beim Laden der Exposèdaten ist ein Fehler aufgetreten">

  <main *ngIf="allprojects !== null && metavalues !== null">

    <pfm-breadcrumbs style="align-self: start; margin-bottom: 2rem"
                     back="/exposes/list"
                     [crumbs]="[{text:'Seiten'}, {text:'Exposès', link:'/exposes/list'}, {text:'Exposè anlegen', link:'/exposes/create', active:true}]">
    </pfm-breadcrumbs>

    <pfm-tabs [(selectedIndex)]="selectedTab">
      <pfm-tab tabIndex="1" tabTitle="Projekt auswählen">
        <div class="main-content">
          <label>Projekt<span class="required">*</span></label>
          <pfm-project-table [singleSelect]="true" [(selectedProject)]="selectedProject"></pfm-project-table>
        </div>
      </pfm-tab>
      <pfm-tab tabIndex="2" tabTitle="Layout definieren">
        <div class="main-content">
          <label>Name<span class="required">*</span></label>
          <pfm-input class="val" placeholder="Name" [(value)]="name" [invalid]="validate('EXPOSE_NAME')"></pfm-input>
        <label>Template<span class="required">*</span></label>
        <pfm-select class="val" placeholder="Template" [(value)]="selectedTemplate" [values]="metaToKeyValueExt(serverData?.template??null, metavalues.exposeTemplate, metavalues.exposeTemplateAvailable)" [invalid]="validate('TEMPLATE_KEY')"></pfm-select>
        <pfm-template-builder *ngIf="selectedTemplate !== null && selectedProject !== null"
                              class="span"
                              [projectid]="selectedProject"
                              [template]="selectedTemplate"
                              [mainPage]="true"
                              [(images)]="imagesConfig"
                              [invalid]="validate('TEMPLATE_CONFIG_IMAGES', 'TEMPLATE_CONFIG_TEXTS')"
                              [(texts)]="textsConfig"></pfm-template-builder>
        </div>
      </pfm-tab>
    </pfm-tabs>

    <pfm-menubar style="align-self: center">
      <pfm-menubar-button                           [disabled]="!(selectedTab > 0)"                         type="secondary"                                              (btnClick)="selectedTab=selectedTab-1" >Zurück</pfm-menubar-button>
      <pfm-menubar-button                           [disabled]="true"                                                            [icon]="['fat', 'trash']"                                                       >Löschen</pfm-menubar-button>
      <pfm-menubar-button *ngIf="mode === 'CREATE'" [disabled]="hasNoPendingChanges()"                                           [icon]="['fat', 'floppy-disk']"          (btnClick)="createExpose(false)"       >Erstellen</pfm-menubar-button>
      <pfm-menubar-button *ngIf="mode === 'EDIT'"   [disabled]="hasNoPendingChanges()"                                           [icon]="['fat', 'floppy-disk']"          (btnClick)="updateExpose(false)"       >Speichern</pfm-menubar-button>
      <pfm-menubar-button *ngIf="selectedTab < 1"   [disabled]="hasNoPendingChanges()"                      type="primary"       [icon]="['fat', 'arrow-right']"          (btnClick)="selectedTab=selectedTab+1" >Weiter</pfm-menubar-button>
      <pfm-menubar-button                           [disabled]="mode == 'CREATE' || !hasNoPendingChanges()"                      [icon]="['fat', 'arrow-down-to-square']" (btnClick)="downloadExpose=serverData" >Download</pfm-menubar-button>
      <pfm-menubar-button *ngIf="selectedTab === 1"                                                                              [icon]="['fat', 'floppy-disk']"          (btnClick)="createOrUpdateExpose(true)">Speichern & Beenden</pfm-menubar-button>
    </pfm-menubar>
  </main>

</frame-default>
