<frame-default [status]="status" errorMessage="Beim Laden der Projekte ist ein Fehler aufgetreten">
  <main>

    <pfm-breadcrumbs style="align-self: start; margin-bottom: 1rem;"
                     [crumbs]="[{text:'Seiten'}, {text:'Kontakte', link:'/contacts/list'},{text:'Kontakt anlegen', link:'/contacts/editcreate' , active: true}]"></pfm-breadcrumbs>

    <div class="main-content">

      <h3 class="key">Personenbezogene Daten</h3>
      <div class="row-3">
        <span class="key">Name<span *ngIf="(name === '' && companyName === '') || (name !== '')" class="required">*</span></span>
        <span class="key">Telefonnummer</span>
        <span class="key">E-Mail</span>
        <pfm-input class="val" [(value)]="name" placeholder="Geben Sie den Namen ein" [invalid]="check"></pfm-input>
        <pfm-input class="val" [(value)]="phone" placeholder="Geben Sie die Telefonnummer ein"
                   [invalid]="check"></pfm-input>
        <pfm-input class="val" type="email" [(value)]="mail" placeholder="Geben Sie die E-Mail Adresse ein"
                   [invalid]="check"></pfm-input>
      </div>

      <div class="space-5"></div>

      <h3 class="key">Firmenbezogene Daten</h3>
      <div class="row-3">
        <span class="key">Firmenname<span *ngIf="(name === '' && companyName === '') || (companyName !== '')" class="required">*</span></span>
        <span class="key">Telefon Büro</span>
        <span class="key">E-Mail</span>
        <pfm-input class="val" [(value)]="companyName" placeholder="Geben Sie den Firmennamen ein"
                   [invalid]="check"></pfm-input>
        <pfm-input class="val" [(value)]="companyPhone" placeholder="Geben Sie die Telefonnummer ein"
                   [invalid]="check"></pfm-input>
        <pfm-input class="val" type="email" [(value)]="companyMail" placeholder="Geben Sie die E-Mail Addresse ein"
                   [invalid]="check"></pfm-input>
      </div>

      <div class="space-5"></div>

      <h3 class="key">Addressdaten</h3>
      <div class="row-3">

        <span class="key">Postleitzahl</span>
        <span class="key" style="grid-column: 2/4">Stadt</span>
        <pfm-input class="val" [(value)]="address_zip" placeholder="Geben Sie die Postleitzahl ein" [invalid]="check"></pfm-input>
        <pfm-input class="val" style="grid-column: 2/4" [(value)]="address_city" placeholder="Geben Sie die Stadt an ein" [invalid]="check"></pfm-input>
      </div>
      <div class="space-1"></div>
      <div class="row-3">
        <span class="key" style="grid-column: 1/3">Straße</span>
        <span class="key">Hausnummer</span>
        <pfm-input class="val" style="grid-column: 1/3" [(value)]="address_street" placeholder="Geben Sie die Straße ein" [invalid]="check"></pfm-input>
        <pfm-input class="val" [(value)]="address_streetNumber" placeholder="Geben Sie die Nummer ein" [invalid]="check"></pfm-input>
      </div>
      <div class="space-1"></div>
      <div class="row-1">
        <span class="key">Land</span>
        <pfm-input class="val" [(value)]="address_country" placeholder="Geben Sie das Land an" [invalid]="check"></pfm-input>
      </div>

    </div>

    <div style="flex-grow: 1"><!-- ensure that pfm-menubar is at bottom --></div>

    <pfm-menubar>
      <pfm-menubar-button [disabled]="true" [icon]="['fat', 'trash']"                                          >Kontakt löschen</pfm-menubar-button>
      <pfm-menubar-button                   [icon]="['fat','floppy-disk']" (btnClick)="createOrUpdateContact()">Speichern</pfm-menubar-button>
    </pfm-menubar>
  </main>
</frame-default>
